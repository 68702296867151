import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../StringConstant/MotiLalAllConstant";
import { Utils } from "../../Utils/Utils";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";

function CreateFeeStructure({ setCreateFeeStructure, bottomPopup }) {
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(4)?.[0]
  );
  const [fromClass, setFromClass] = useState("");
  const [toClass, setToClass] = useState("");
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-start fixed top-0 left-0 z-50 ">
      <div
        className={`bg-white  min-w-[30rem] h-[100vh] tablet:w-[60%] text-sm shadow-lg relative ${bottomPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <p className="text-xl font-semibold text-[#2B60EC]">
            Create Fee Structure
          </p>
          <IoCloseSharp
            className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
            onClick={() => {
              setCreateFeeStructure(false);
            }}
          />
        </div>
        <div className="p-5   h-[90vh] overflow-y-scroll no-scrollbar">
          <div className=" flex flex-col  gap-2">
            <p className="">Academic year</p>
            <select
              value={academicYear}
              onChange={(e) => setAcademicYear(e.target.value)}
              className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
            >
              <option value="" disabled>
                Choose academic year
              </option>
              {Utils?.generateAcademicYears(4)?.map(
                (studentClassData, index) => (
                  <option value={studentClassData} key={index}>
                    {studentClassData}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="flex items-end gap-5 w-full my-5">
            <div className=" flex flex-col  gap-2 w-full">
              <p className="">From class</p>
              <select
                value={fromClass}
                onChange={(e) => setFromClass(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
              >
                <option value="" disabled>
                  Choose from class
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className=" flex flex-col  gap-2 w-full">
              <p className="">To class</p>
              <select
                value={toClass}
                onChange={(e) => setToClass(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
              >
                <option value="" disabled>
                  Choose to class
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>{" "}
          <div className="flex items-end gap-5 w-full my-5">
            <div className=" flex flex-col  gap-2 w-full">
              <p className="">From month</p>
              <select
                value={fromMonth}
                onChange={(e) => setFromMonth(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
              >
                <option value="" disabled>
                  Choose from month
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.mo?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className=" flex flex-col  gap-2 w-full">
              <p className="">To month</p>
              <select
                value={toMonth}
                onChange={(e) => setToMonth(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
              >
                <option value="" disabled>
                  Choose to month
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.MONTHS_ARRAY?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-3 ">
            <div className="flex justify-between items-end gap-5">
              <InputFieldWithBorder label={`Name of fees`} />
              <InputFieldWithBorder label={`Amount`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFeeStructure;
