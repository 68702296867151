import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import TeacherRegistration from "./TeacherRegistration/TeacherRegistration";
import TeacherInfoTable from "./TeacherInfoTable/TeacherInfoTable";
import { useGetAllTeachersQuery } from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";
import { AuthContext } from "../../Provider/AuthProvider";

function TeacherManagement() {
  const { userData } = useContext(AuthContext);

  const [showTeacherSignUpForm, setShowTeacherSignUpForm] = useState(false);
  const [getTeacherAllData, setGetTeacherAllData] = useState([]);

  const {
    data: getAllTeachers,
    refetch: getAllTeachersRefetch,
    isLoading: getAllTeacherIsLoading,
  } = useGetAllTeachersQuery(userData?.medium);

  useEffect(() => {
    if (getAllTeachers) {
      setGetTeacherAllData(getAllTeachers?.data);
    }
  }, [getAllTeachers]);
  return (
    <div className="m-5  ">
      {getAllTeacherIsLoading && <CircleLoader />}
      <div className="w-full flex justify-between items-center bg-white px-5 py-2 rounded-lg h-auto">
        <p className="">Staff Management</p>
        <div className="flex items-center">
          <CustomButton
            label={`Add New Teacher`}
            className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
            onClick={() => setShowTeacherSignUpForm(true)}
          />
        </div>
      </div>
      <div className="w-full flex justify-between  h-[90vh] ">
        <div className=" mt-5 rounded-lg w-full  h-auto">
          <TeacherInfoTable getTeacherAllData={getTeacherAllData} />
        </div>
      </div>
      {showTeacherSignUpForm && (
        <TeacherRegistration
          bottomPopup={`animate-bottomPopup`}
          setShowTeacherSignUpForm={setShowTeacherSignUpForm}
          getTableDataRefetch={getAllTeachersRefetch}
        />
      )}
    </div>
  );
}

export default TeacherManagement;
