import React from "react";
import search_animation from "../../assets/search_animation.json";
import Lottie from "lottie-react";

function TimeTableSchedule({ timeTableAllData }) {
  return (
    <div className="my-5 p-5 bg-white rounded-lg  h-[78vh]  overflow-y-scroll no-scrollbar">
      {timeTableAllData?.timeTable?.length > 0 ? (
        <table className="w-full text-sm bg-white rounded   flex items-start">
          <tbody className="">
            <tr className=" p-3 text-start ">
              <td className="p-3 text-start border border-collapse bg-gray-500/30">
                Day
              </td>
              {timeTableAllData?.timeTable?.[0]?.periods?.map((_, index) => (
                <td
                  className=" p-3 text-start border border-collapse bg-gray-500/30"
                  key={index}
                >
                  {index + 1}
                </td>
              ))}
            </tr>
            {timeTableAllData?.timeTable?.map((tableData, index1) => (
              <tr
                className="shadow text-gray-700 border border-collapse "
                key={index1}
              >
                <td className=" p-3 text-start border border-collapse bg-gray-500/30">
                  {tableData?.day}
                </td>
                {tableData?.periods?.map((periodsData, index2) => (
                  <td
                    className=" p-3 text-start border border-collapse !text-xs"
                    key={index2}
                  >
                    <p className="">
                      {periodsData?.subject} ({periodsData?.teacher})
                    </p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <Lottie
            animationData={search_animation}
            loop={true}
            className="w-[15rem]"
          />
          <p className="-translate-y-14">
            The time table for this class is not scheduled
          </p>
        </div>
      )}
    </div>
  );
}

export default TimeTableSchedule;
