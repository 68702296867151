import { configureStore, compose } from "@reduxjs/toolkit";
import { SchoolApi } from "./ReduxToolkit";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: {
    [SchoolApi.reducerPath]: SchoolApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(SchoolApi.middleware),
  devTools: true,
});

export default store;
