import React from "react";
import { FaBullhorn, FaTrophy } from "react-icons/fa";
import { IoVideocam } from "react-icons/io5";

function Cards() {
  return (
    <div className="flex justify-center w-full  my-10 mb-20">
      <div className="flex flex-col lg:flex-row justify-between items-start w-[95%] tablet:w-[80%] lg:w-[70%]">
        <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
          <div className="flex items-center w-ful bg-red-600 ">
            <div className=" text-4xl">
              <IoVideocam className="text-white p-1 px-2 " />
            </div>
            <div className="text-center w-full font-bold bg-[#293B61]  py-2">
              AN INSIGHT
            </div>
          </div>
          <div className=" overflow-hidden inline-block ">
            <img
              src={require("../../assets/curriculum-kn-2.jpg")}
              alt=""
              className=" hover:scale-105 duration-300 cursor-pointer "
            />
          </div>
          <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
            <p className="text-[#FADF13]">18 Oct, 2022</p>
            <p className="">Happy Republic Day</p>
          </div>
        </div>
        <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
          <div className="flex items-center w-ful bg-red-600 ">
            <div className=" text-4xl">
              <FaTrophy className="text-white p-1 px-2 " />
            </div>
            <div className="text-center w-full font-bold bg-[#293B61]  py-2">
              OUR ACHIEVEMENTS
            </div>
          </div>
          <div className=" overflow-hidden inline-block ">
            <img
              src={require("../../assets/curriculum-kn-2.jpg")}
              alt=""
              className=" hover:scale-105 duration-300 cursor-pointer "
            />
          </div>
          <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
            <p className="text-[#FADF13]">18 Oct, 2022</p>
            <p className="">Happy Republic Day</p>
          </div>
        </div>
        <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
          <div className="flex items-center w-ful bg-red-600 ">
            <div className=" text-4xl">
              <FaBullhorn className="text-white p-1 px-2 " />
            </div>
            <div className="text-center w-full font-bold bg-[#293B61]  py-2">
              ANNOUNCEMENTS
            </div>
          </div>
          <div className=" overflow-hidden inline-block ">
            <img
              src={require("../../assets/curriculum-kn-2.jpg")}
              alt=""
              className=" hover:scale-105 duration-300 cursor-pointer "
            />
          </div>
          <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
            <p className="text-[#FADF13]">18 Oct, 2022</p>
            <p className="">Happy Republic Day</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
