import React, { useEffect, useState } from "react";
import { VscAccount } from "react-icons/vsc";
import TeacherFullInfoPopup from "../TeacherFullInfoPopup";
import { useGetAllTeachersQuery } from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";

function TeacherInfoTable({ getTeacherAllData, getTableDataRefetch }) {
  const [showDetailsBox, setShowDetailsBox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentStaffId, setCurrentStaffId] = useState("");

  // console.log(getAllTeachers?.data);

  const teacherInfo = [
    {
      img: "",
      teacherFirstName: "krishna",
      teacherLastName: "kumar",
      email: "kkgkrishna112@gmail.com",
      fatherName: "Ramdin Gautam",
      motherName: "Anita Gautam",
      mobile: "7651893713",
      aadhar: "314556835392",
      accountNo: "20838100013817",
      bankName: "Bank of baroda ",
      branchName: "mandi samiti ",
      ifsc: "BARB0MANKAN",
    },
    {
      img: "",
      teacherFirstName: "krishna",
      teacherLastName: "kumar",
      email: "kkgkrishna112@gmail.com",
      fatherName: "Ramdin Gautam",
      motherName: "Anita Guatam",
      mobile: "7651893713",
      aadhar: "314556835392",
      accountNo: "20838100013817",
      bankName: "Bank of baroda ",
      branchName: "mandi samiti ",
      ifsc: "BARB0MANKAN",
    },
    {
      img: "",
      teacherFirstName: "krishna",
      teacherLastName: "kumar",
      email: "kkgkrishna112@gmail.com",
      fatherName: "Ramdin Gautam",
      motherName: "Anita Guatam",
      mobile: "7651893713",
      aadhar: "314556835392",
      accountNo: "20838100013817",
      bankName: "Bank of baroda ",
      branchName: "mandi samiti ",
      ifsc: "BARB0MANKAN",
    },
  ];

  const handleShowTeacherFullDetail = (index, teacher) => {
    // setCurrentIndex(index);
    // setShowDetailsBox(true);

    setCurrentIndex(index);
    if (showDetailsBox === currentIndex) {
      setShowDetailsBox(-1);
      return;
    }
    setShowDetailsBox(index);
    setCurrentStaffId(teacher?.staffId);
  };

  return (
    <div className=" h-full overflow-y-scroll no-scrollbar">
      <table className="w-full text-sm bg-white">
        <thead>
          <tr className="shadow text-gray-700">
            <th className=" p-3 text-start">S.No.</th>
            <th className=" p-3 text-start">Teacher Name</th>
            <th className=" p-3 text-start">Email Id</th>
            <th className=" p-3 text-start">Mobile No</th>
            <th className=" p-3 text-start">Aadhar Number</th>
            <th className=" p-3 text-start">Password</th>
          </tr>
        </thead>
        <tbody>
          {getTeacherAllData?.map((teacher, index) => (
            <tr
              key={index}
              className="shadow text-gray-500 cursor-pointer hover:bg-gray-400/10"
              onClick={() => handleShowTeacherFullDetail(index, teacher)}
            >
              <td className=" p-3 text-start ">{index + 1}</td>{" "}
              <td className=" p-3 text-start capitalize">{`${teacher.personalDetails?.firstName} ${teacher.personalDetails?.lastName}`}</td>
              <td className=" p-3 text-start ">
                {teacher?.personalDetails?.email}
              </td>
              <td className=" p-3 text-start ">
                {` +91 ${teacher?.personalDetails?.phone}`}
              </td>
              <td className=" p-3 text-start ">
                {teacher?.personalDetails?.adharNumber}
              </td>
              <td className=" p-3 text-start ">
                {teacher?.personalDetails?.password}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDetailsBox === currentIndex && (
        <TeacherFullInfoPopup
          setShowDetailsBox={setShowDetailsBox}
          rightPopup={`animate-rightPopup`}
          currentStaffId={currentStaffId}
          getTableDataRefetch={getTableDataRefetch}
        />
      )}
    </div>
  );
}

export default TeacherInfoTable;
