import React, { useRef, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import HinglishTemplate from "./TCTemplates/HinglishTemplate";
import { useReactToPrint } from "react-to-print";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import { IoMdSearch } from "react-icons/io";
import { API_CONSTANT } from "../../ApiConstant/ApiConstant";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import TcEnglishTemplate from "./TCTemplates/TcEnglishTemplate/TcEnglishTemplate";
import CircleLoader from "../../AllLoaders/CircleLoader";
import TcHindiTemplate from "./TCTemplates/TcHindiTemplate/TcHindiTemplate";

function TCMigrationManagement() {
  const componentRef = useRef();
  const [language, setLanguage] = useState("HINGLISH");
  const [studentId, setStudentId] = useState("");
  const [currentStudentAllData, setCurrentStudentAllData] = useState();
  const [showStudentDetailBox, setShowStudentDetailPopup] = useState(false);
  const [showTc, setShowTc] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleStudentSearch = async () => {
    if (!studentId || studentId === "") {
      enqueueSnackbar("Please Enter Student ID !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    setLoading(true); // Set loading state to true

    try {
      const response = await axios.get(
        `${API_CONSTANT?.BASE_URL_PROD}${API_CONSTANT?.GET_STUDENT_BY_SCHOLAR_NUMBER}/${studentId}`
      );

      setCurrentStudentAllData(response.data?.student);
      setShowStudentDetailPopup(true);
    } catch (error) {
      console.error("Error fetching student data:", error);
      enqueueSnackbar("An error occurred while fetching student data.", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };

  return (
    <div className="m-5">
      {loading && <CircleLoader />}
      <div className="w-full flex justify-between items-center bg-white px-5 py-2 rounded-lg">
        <p className="">Transfer & Migration Management</p>
        <div className="flex items-center">
          <CustomButton
            label={`Print Transfer Certificate `}
            className={` text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
            onClick={handlePrint}
          />
          <div className=" w-[10rem]">
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
            >
              <option value={`ENGLISH`}>ENGLISH</option>
              <option value={`HINDI`}>HINDI</option>
              <option value={`HINGLISH`}>HINGLISH</option>
            </select>
          </div>
        </div>
      </div>
      {!showTc && (
        <div className="  overflow-y-scroll no-scrollbar p-5 bg-white my-5 rounded-lg">
          <div className="w-[25rem]">
            <div className="flex justify-between gap-3 items-end mb-5 ">
              <InputFieldWithBorder
                value={studentId || ""}
                label="Enter Student Id"
                onChange={(e) => {
                  setStudentId(e.target.value);
                  // setShowStudentDetailPopup(false);
                }}
              />
              <IoMdSearch
                className="border text-4xl p-1 rounded-lg mb-1 cursor-pointer text-[#2B60EC] border-[#2B60EC] hover:bg-[#2B60EC] hover:text-white"
                onClick={() => handleStudentSearch()}
              />
            </div>
            {showStudentDetailBox && (
              <div className="border rounded-xl p-5">
                <p className="font-semibold text-lg mb-5 border-b pb-3">
                  Basic Details{" "}
                </p>
                <div className="px-5 pb-5 mb-5 text-sm border-b">
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">
                      Scholar Id Number
                    </p>
                    <p className="text-gray-500">
                      {currentStudentAllData?.scholarNumber}
                    </p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">
                      Scholar Full Name
                    </p>
                    <p className="text-gray-500 capitalize">
                      {currentStudentAllData?.personalDetails?.firstName}{" "}
                      {currentStudentAllData?.personalDetails?.lastName}
                    </p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Father Name</p>
                    <p className="text-gray-500 capitalize">
                      {currentStudentAllData?.personalDetails?.fatherName}
                    </p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Mobile No</p>
                    <p className="text-gray-500">
                      +91
                      {currentStudentAllData?.personalDetails?.phone}
                    </p>
                  </div>{" "}
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Email Id</p>
                    <p className="text-gray-500">
                      {currentStudentAllData?.personalDetails?.email}
                    </p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Class</p>
                    <p className="text-gray-500">
                      {currentStudentAllData?.class}
                    </p>
                  </div>
                </div>
                <CustomButton
                  label={`Generate T.C. `}
                  className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
                  onClick={() => setShowTc(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {showTc && language === "HINDI" && (
        <div className=" h-[90vh] overflow-y-scroll no-scrollbar">
          <TcHindiTemplate
            componentRef={componentRef}
            currentStudentAllData={currentStudentAllData}
            templateLanguage={language}
          />
        </div>
      )}{" "}
      {showTc && language === "ENGLISH" && (
        <div className=" h-[90vh] overflow-y-scroll no-scrollbar">
          <TcEnglishTemplate
            componentRef={componentRef}
            currentStudentAllData={currentStudentAllData}
            templateLanguage={language}
          />
        </div>
      )}{" "}
      {showTc && language === "HINGLISH" && (
        <div className=" h-[90vh] overflow-y-scroll no-scrollbar">
          <HinglishTemplate
            componentRef={componentRef}
            currentStudentAllData={currentStudentAllData}
            templateLanguage={language}
          />
        </div>
      )}
    </div>
  );
}

export default TCMigrationManagement;
