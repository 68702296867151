import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import { FaRegEye } from "react-icons/fa";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import {
  useGetAllDocumentByScholarNumberQuery,
  useStudentUploadDocumentMutation,
} from "../../../Redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { API_CONSTANT } from "../../../ApiConstant/ApiConstant";
import CustomAlertPopup from "../../../Shared/CustomAlertPopup/CustomAlertPopup";
import axios from "axios";

function UploadStudentDocument({
  setShowUploadPopup,
  rightPopup,
  studentData,
}) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imagePreviewDocumentName, setImagePreviewDocumentName] = useState("");
  const [showDeleteAlertPopup, setShowDeleteAlertPopup] = useState(false);
  const [fileData, setFileData] = useState();
  const [studentUploadDocument, { isLoading: studentUploadDocumentIsLoading }] =
    useStudentUploadDocumentMutation();
  const {
    data: getAllDocument,
    refetch: getAllDocumentRefetch,
    isLoading: getAllDocumentLoading,
  } = useGetAllDocumentByScholarNumberQuery(studentData?.scholarNumber || "");

  console.log(getAllDocument?.documents);

  const handleFileSelect = async (e, documentName) => {
    console.log(e.target.name);

    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("filetype", documentName);
    formData.append("scholarNumber", studentData?.scholarNumber);
    setFileData(formData);
  };

  const handleFileUpload = async () => {
    try {
      const response = await studentUploadDocument({
        body: fileData,
      });

      if (response) {
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        getAllDocumentRefetch();
      } else {
        console.log("Oops! file is not upload. ");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const renderInputFields = () => {
    const handlePreview = (documentUrl) => {
      setImagePreviewUrl(documentUrl);
      //   window.open(documentUrl, "_blank");
    };

    const getDocumentUrlByDocumentName = (documentName) => {
      setImagePreviewDocumentName(documentName);
      const document = getAllDocument?.documents.find(
        (doc) => doc.documentName === documentName
      );
      return document ? document.url : "";
    };

    const handleDeleteDocumet = (documentName) => {
      setImagePreviewDocumentName(documentName);

      // console.log("documentName delete vale me ", documentName);
      // console.log("documentName delete vale me ", studentData?.scholarNumber);
      setShowDeleteAlertPopup(true);
    };

    const handleDeleteDocumentApi = async () => {
      try {
        const response = await axios.delete(
          `${API_CONSTANT?.BASE_URL_PROD}${API_CONSTANT?.DELETE_DOCUMENT}`,
          {
            data: {
              filetype: imagePreviewDocumentName,
              scholarNumber: studentData?.scholarNumber,
            },
          }
        );

        if (response) {
          enqueueSnackbar(response?.data?.message, {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
          getAllDocumentRefetch();
          setShowDeleteAlertPopup(false);
        } else {
          console.log("Oops! file is not Deleted. ");
        }
      } catch (error) {
        console.error("Delete failed:", error.message);
      }
    };

    const apiDocumentNames = getAllDocument?.documents.map(
      (doc) => doc.documentName
    );
    const allDocumentNames = [
      "photo",
      "signature",
      "birth_certificate",
      "aadhar",
      "high_school",
      "caste_certificate",
      "domicile_certificate",
      "income_certificate",
      "transfer_certificate",
    ];

    return allDocumentNames?.map((documentName) => {
        const displayName = documentName.replace(/_/g, " ");

      if (apiDocumentNames?.includes(documentName)) {
        // Hide the input field if it's present in the API response


        return (
          <div
            key={documentName}
            className="flex justify-between items-center w-full"
          >
            <div className="capitalize">{displayName}</div>
            <div className="flex items-end gap-4">
              <p className="text-[#2B60EC]">File Uploaded</p>
              <FaRegEye
                className="bg-gray-300 text-gray-600 hover:bg-[#2B60EC] hover:text-white p-1 px-2 text-4xl cursor-pointer rounded-md"
                onClick={() =>
                  handlePreview(getDocumentUrlByDocumentName(documentName))
                }
              />
              <RiDeleteBin6Fill
                className="bg-gray-300 text-gray-600 hover:bg-[#2B60EC] hover:text-white  p-1 px-2  text-4xl cursor-pointer rounded-md"
                onClick={() =>
                  //   handlePreview(getDocumentUrlByDocumentName(documentName))
                  handleDeleteDocumet(documentName)
                }
              />
            </div>
            {showDeleteAlertPopup && (
              <CustomAlertPopup
                title={`warning`}
                discreption={`Check the information given by you once again because once the form is submitted, there will be no changes in this form.`}
                cancelBtnLabel={`Cancel`}
                cancelBtnOnClick={setShowDeleteAlertPopup}
                submitBtnLabel={`Ok`}
                submitBtnOnClick={handleDeleteDocumentApi}
              />
            )}
            {imagePreviewUrl !== "" && (
              <div className="fixed top-0 left-0 w-full h-screen bg-gray-500/50 flex justify-start">
                <div className="bg-white h-screen w-[30rem] flex flex-col ">
                  <div className="flex justify-between items-center p-5 border-b pb-5">
                    <div className="flex items-center gap-2">
                      <p className=" text-xl font-semibold capitalize">
                        {imagePreviewDocumentName}
                      </p>
                    </div>
                    <IoCloseSharp
                      className={`text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300`}
                      onClick={() => setImagePreviewUrl("")}
                    />
                  </div>
                  <div className=" h-[90vh]  p-10">
                    <img
                      src={`${API_CONSTANT?.IMAGE_CLOUD_FRONT_URL}${imagePreviewUrl}`}
                      className="animate-bottomPopup max-h-[40rem] shadow-lg p-5"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      } else {
        // Show the input field if it's not present in the API response
        return (
          <div
            key={documentName}
            className="flex justify-between items-center w-full"
          >
            <div className="capitalize">{displayName}</div>
            <div className="">
              <input
                type="file"
                onChange={(e) => handleFileSelect(e, documentName)}
                name={documentName}
              />
              <CustomButton
                label={`Upload`}
                className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] !ms-5`}
                onClick={() => handleFileUpload()}
              />
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0">
      {(getAllDocumentLoading || studentUploadDocumentIsLoading) && (
        <CircleLoader />
      )}
      <div
        className={`bg-white lg:w-[40rem] h-[100vh] tablet:w-[60%] shadow-lg relative ${rightPopup}`}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <div className="flex items-center gap-2">
            <VscAccount className="text-2xl text-gray-400" />
            <p className="text-xl font-semibold text-[#2B60EC] capitalize">
              {studentData?.personalDetails?.firstName}{" "}
              {studentData?.personalDetails?.lastName}
            </p>
          </div>
          <IoCloseSharp
            className={`text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300`}
            onClick={() => setShowUploadPopup(false)}
          />
        </div>
        <div className="p-5 h-[92vh] flex flex-col gap-4 items-start w-full">
          {renderInputFields()}
        </div>
      </div>
    </div>
  );
}

export default UploadStudentDocument;
