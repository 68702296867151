import Loader from "react-js-loader";
const CircleLoader = () => {
  return (
    <div className="w-full h-screen  fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900/60 z-50">
      <Loader type="spinner-default" bgColor={`#FFFFFF`} size={100} />
    </div>
  );
};

export default CircleLoader;
