import React from "react";
import MobileHeader from "../Header/MobileHeader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import GalleryContainer from "./GalleryContainer";
import MainGallery from "./MainGallery";

function Gallery() {
  return (
    <div className="flex flex-col">
      <div className="hidden lg:block">
        <Header />
      </div>
      <div className="block lg:hidden">
        <MobileHeader />
      </div>
      <div className="py-10">
        <MainGallery />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default Gallery;
