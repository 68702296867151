import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import { IoMdSearch } from "react-icons/io";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { API_CONSTANT } from "../../ApiConstant/ApiConstant";
import { Utils } from "../../Utils/Utils";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CircleLoader from "../../AllLoaders/CircleLoader";

function ViewAttendance({
  setShowViewAttendance,
  rightPopup,
  currentScholarId,
}) {
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(1)?.[0]
  );
  const [studentsAttendanceData, setStudentsAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFind = async () => {
    if (
      academicYear === null ||
      academicYear === undefined ||
      academicYear === ""
    ) {
      enqueueSnackbar("Please choose academic year !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      return;
    }
    setIsLoading(true);
    axios
      .get(
        `${API_CONSTANT?.BASE_URL_PROD}${API_CONSTANT?.GET_STUDENT_ATTENDANCES}/${currentScholarId}/${academicYear}`
      )
      .then((response) => {
        console.log(response.data?.data);
        setStudentsAttendanceData(response.data?.data);
        setIsLoading(false);

        // setCurrentStudentClass(response.data?.student?.class);
        // setCurrentStudentAllData(response.data?.student);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
      });
  };

  console.log(studentsAttendanceData);

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0 z-50 ">
      {isLoading && <CircleLoader />}
      <div
        className={`bg-white min-w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${rightPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <p className="text-xl font-semibold text-[#2B60EC]">
            View Attendance
          </p>
          <IoCloseSharp
            className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
            onClick={() => setShowViewAttendance(false)}
          />
        </div>
        <div className="h-[92vh] overflow-y-scroll no-scrollbar">
          <div className="p-5">
            {/* <div className="">{Utils?.generateAcademicYears(5)}</div> */}
            <div className="mb-5">
              <select
                value={academicYear}
                onChange={(e) => {
                  setAcademicYear(e.target.value);
                }}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
              >
                <option value="" disabled>
                  Choose Academic Year
                </option>
                {Utils?.generateAcademicYears(5)?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>

              <CustomButton
                label={`Find`}
                className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] mt-5`}
                onClick={() => handleFind()}
              />
            </div>

            <div className=" mx-10 ">
              {studentsAttendanceData?.length > 0 ? (
                <table className="w-full">
                  <thead>
                    <tr className="border border-black">
                      <th className="border-r border-black w-[50%] p-1">
                        Date
                      </th>
                      <th className="border-r border-black w-[50%] p-1">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentsAttendanceData?.map((attdance, index) => (
                      <tr
                        key={index}
                        className="w-full border border-black text-center "
                      >
                        <td className="border-r border-black w-[50%] p-1">
                          {Utils?.formatDate(attdance?.createdAt)}
                        </td>
                        <td className="w-[50%] border-l border-black p-1">
                          {attdance?.status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="flex flex-col items-center justify-center text-xl  ">
                  <p className=" p-10 rounded-xl border-red-500 text-red-500">
                    No Data Found
                  </p>
                </div>
              )}

              {/* <div className="flex items-center justify-between border border-dashed border-black ">
                <p className="border-r border-black px-5"></p>
                <div className="w-[10px] bg-black h-full">1</div>
                <p className="border-l border-black px-5"></p>
              </div> */}
            </div>

            {/* <div className="flex justify-between gap-3 items-end mb-5">
              <InputFieldWithBorder
                value={studentId || ""}
                label="Enter Student Id"
                onChange={(e) => {
                  setStudentId(e.target.value);
                  // setShowStudentDetailPopup(false);
                }}
              />
              <IoMdSearch
                className="border text-4xl p-1 rounded-lg mb-1 cursor-pointer text-[#2B60EC] border-[#2B60EC] hover:bg-[#2B60EC] hover:text-white"
                onClick={() => handleStudentSearch()}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAttendance;
