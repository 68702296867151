import React from "react";
import { Outlet } from "react-router-dom";

function ContentAriea() {
  return (
    <div className="bg-[#F4F4FF] w-full h-screen">
      <Outlet />
    </div>
  );
}

export default ContentAriea;
