import React from "react";
import CertificateTem1 from "./CertificateTem1";

function CertificateTemplates() {
  const stInfo = {
    nameOfCertificate: "completion",
    descriptionOfCertificate: "",
    stName: "Krishna",
  };
  return (
    <div className="">
      <CertificateTem1 stInfo={stInfo} />
    </div>
  );
}

export default CertificateTemplates;
