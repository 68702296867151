import React, { useContext, useState } from "react";
import CountSection from "./CountSection/CountSection";
import SendNotification from "./SendNotification/SendNotification";
import CollectionTable from "./CollectionTable/CollectionTable";
import { CiMoneyBill } from "react-icons/ci";
import { AuthContext } from "../../Provider/AuthProvider";

function Dashboard() {
  const [expenseDetails, setExpenceDetails] = useState();

  // console.log(expenseDetails);
  return (
    <div className="m-5 h-[97vh] overflow-y-scroll no-scrollbar ">
      <div className="">
        <CountSection setExpenceDetails={setExpenceDetails} />
      </div>
      <div className="flex justify-between gap-5 my-5">
        <div className="w-full">
          <CollectionTable />
        </div>
        <div className="flex flex-col justify-between  rounded-lg w-[29%]">
          <div className="flex flex-col w-full gap-5">
            <div className="min-h-[5rem] bg-white relative p-5 rounded-lg  shadow-lg">
              <div className="h-full flex flex-col justify-between gap-5">
                <p className=" text-[#acacac] text-sm">Weekly Expense</p>
                <p className="text-xl font-semibold text-[#616161]">
                  ₹{" "}
                  {expenseDetails?.weeklyExpense
                    ? expenseDetails?.weeklyExpense
                    : 0}
                </p>
              </div>
              <div className="bg-gradient-to-r  from-[#5e72e4] to-[#825ee4] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
                <CiMoneyBill className="text-white text-2xl" />
              </div>
            </div>{" "}
            <div className="min-h-[5rem] bg-white relative p-5 rounded-lg  shadow-lg">
              <div className="h-full flex flex-col justify-between gap-5">
                <p className="text-[#acacac] text-sm">Twoweek Expense</p>
                <p className="text-xl font-semibold text-[#616161]">
                  ₹
                  {expenseDetails?.twoWeekExpense
                    ? expenseDetails?.twoWeekExpense
                    : 0}
                </p>
              </div>
              <div className="bg-gradient-to-r from-[#f5365c] to-[#f56036] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
                <CiMoneyBill className="text-white text-2xl" />
              </div>
            </div>
            <div className="min-h-[5rem] bg-white relative p-5 rounded-lg  shadow-lg">
              <div className="h-full flex flex-col justify-between gap-5">
                <p className="text-[#acacac] text-sm">Monthly Expense</p>
                <p className="text-xl font-semibold text-[#616161]">
                  ₹
                  {expenseDetails?.monthlyExpense
                    ? expenseDetails?.monthlyExpense
                    : 0}
                </p>
              </div>
              <div className="bg-gradient-to-r from-[#2dce89] to-[#2dcecc] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
                <CiMoneyBill className="text-white text-2xl" />
              </div>
            </div>
          </div>
          <SendNotification />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
