import React from "react";

function HindiTcDscriptionTable() {
  return (
    <div className="">
      <table className="w-full text-xsm  rounded ">
        <thead className="border border-black bg-gray-700/20">
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">कक्षा</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <p className="font-bold">प्रवेश तिथि</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="font-bold">कक्षोन्नति</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="font-bold">निष्कासन</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="font-bold">
                निष्कासन का कारण, जैसे शुल्क न देना <br /> कुटुंब का स्थानांतरण,
                निर्वासन इत्यादि
              </p>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <p className="font-bold">वर्ष</p>
            </td>
            <td className=" p-1 text-center">
              <p className="font-bold">आचरण तथा कार्य</p>
            </td>
          </tr>
        </thead>
        <tbody className="border border-black">
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">VI</p>
              <p className="font-bold">6</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none "
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">VII</p>
              <p className="font-bold">7</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">VIII</p>
              <p className="font-bold">8</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">IX</p>
              <p className="font-bold">9</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">X</p>
              <p className="font-bold">10</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">XI</p>
              <p className="font-bold">11</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="font-bold">XII</p>
              <p className="font-bold">12</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HindiTcDscriptionTable;
