import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ErpApp from "../../ErpApp/ErpApp";
import Dashboard from "../../ErpApp/Dashboard/Dashboard";
import StudentManagement from "../../ErpApp/StudentManagement/StudentManagement";
import StudentRegistrationForm from "../../ErpApp/StudentManagement/StudentRegistrationForm/StudentRegistrationForm";
import TeacherManagement from "../../ErpApp/TeacherManagement/TeacherManagement";
import TCMigrationManagement from "../../ErpApp/TCMigrationManagement/TCMigrationManagement";
import UpdateStudentInfo from "../../ErpApp/StudentManagement/UpdateStudentInfo/UpdateStudentInfo";
import TimeTableAndHolyday from "../../ErpApp/TimeTableAndHolyday/TimeTableAndHolyday";
import MainLogin from "../../ErpApp/AllLogin/MainLogin/MainLogin";
import StaffProfile from "../../ErpApp/StaffProfile/StaffProfile";
import MyStudents from "../../ErpApp/MyStudents/MyStudents";
import TransportManagement from "../../ErpApp/TransportManagement/TransportManagement";
import ExpenseManagement from "../../ErpApp/ExpenseManagement/ExpenseManagement";
import ChooseMediumPage from "../../ErpApp/ChooseMediumPage/ChooseMediumPage";
import AfterLoginUnauthorise from "../../WebsitePages/WildCardRouteComponent/AfterLoginUnauthorise";
import FeeReceiptEnglish from "../../ErpApp/StudentManagement/StudentRegistrationForm/FeeReceiptEnglish";
import FeeReceiptHindi from "../../ErpApp/StudentManagement/StudentRegistrationForm/FeeReceiptHindi";
import { AuthContext } from "../../Provider/AuthProvider";
import Unauthorise from "../../WebsitePages/WildCardRouteComponent/Unauthorise";
import ClassAttendanceReport from "../../ErpApp/ClassAttendanceReport/ClassAttendanceReport";

function ErpRouter() {
  const { accessToken, userData } = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("role"));
  }, []);

  return accessToken !== null && accessToken !== undefined ? (
    <Routes>
      <Route path="*" Component={AfterLoginUnauthorise} />

      <Route path={`/${userData?.medium}`} Component={ErpApp}>
        <Route path={`/${userData?.medium}`} Component={Dashboard} />
        <Route
          path={`/${userData?.medium}/student`}
          Component={StudentManagement}
        />
        <Route
          path={`/${userData?.medium}/student-registration`}
          Component={StudentRegistrationForm}
        />
        <Route
          path={`/${userData?.medium}/update-studentInfo`}
          Component={UpdateStudentInfo}
        />
        {userData?.medium === "ENGLISH" ? (
          <Route
            path={`/${userData?.medium}/student-fee`}
            // path="/english/student-fee"
            Component={FeeReceiptEnglish}
          />
        ) : (
          <Route
            path={`/${userData?.medium}/student-fee`}
            // path="/hindi/student-fee"
            Component={FeeReceiptHindi}
          />
        )}
        <Route
          path={`/${userData?.medium}/teacher`}
          Component={TeacherManagement}
        />
        <Route
          path={`/${userData?.medium}/tc-migration`}
          Component={TCMigrationManagement}
        />
        <Route
          path={`/${userData?.medium}/time-table`}
          Component={TimeTableAndHolyday}
        />
        <Route
          path={`/${userData?.medium}/staff-profile`}
          // path="/staff-profile"
          Component={StaffProfile}
        />
        <Route
          path={`/${userData?.medium}/transport`}
          Component={TransportManagement}
        />
        <Route
          path={`/${userData?.medium}/expense`}
          // path="/expense"
          Component={ExpenseManagement}
        />{" "}
      </Route>
    </Routes>
  ) : userRole === "TEACHER" ? (
    <Routes>
      <Route path={`/`} Component={ErpApp}>
        <Route
          // path={`/${userData?.medium}/staff-profile`}
          path="/"
          Component={StaffProfile}
        />{" "}
        <Route
          // path={`/${userData?.medium}/staff-profile`}
          path="/staff-profile"
          Component={StaffProfile}
        />
        <Route
          // path={`/${userData?.medium}/my-students`}
          path="/my-students"
          Component={MyStudents}
        />{" "}
        <Route
          // path={`/${userData?.medium}/my-students`}
          path="/attendance-report"
          Component={ClassAttendanceReport}
        />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route path="*" Component={Unauthorise} />
      {/* <Route path="/" Component={ChooseMediumPage} /> */}
      <Route path="/" Component={MainLogin} />
    </Routes>
  );
}

export default ErpRouter;
