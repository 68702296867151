import React from "react";
import InputFieldWithBorder from "../../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import TextareaWithBorder from "../../../Shared/TextareaWithBorder/TextareaWithBorder";
import CustomButton from "../../../Shared/CustomButton/CustomButton";

function SendNotification() {
  return (
    <div className="p-5 bg-white rounded-lg">
      <p className="">Message</p>
      <div className="mt-5">
        <p className="mb-3">To : krishna</p>
        <div className="mb-5">
          <InputFieldWithBorder placeholder={`Topic`} className={`mb-4`} />
        </div>
        <div className="mb-5">
          <TextareaWithBorder rows={4} placeholder={`write details..`} />
        </div>
        <div className="mb-5">
          <input type="file" name="" id="" />
        </div>
        <CustomButton
          label={`Send`}
          className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] w-full`}
        />
      </div>
    </div>
  );
}

export default SendNotification;
