import React from "react";
import "./Enquery.css";
import { FaPhoneAlt } from "react-icons/fa";
import InputFieldWithOutBorder from "../../Shared/InputFieldWithOutBorder/InputFieldWithOutBorder";
import TextareaWithBorder from "../../Shared/TextareaWithBorder/TextareaWithBorder";
import CustomButton from "../../Shared/CustomButton/CustomButton";

function Enquery() {
  return (
    <div className="enquiry-bg-image lg:h-[70vh] flex justify-center ">
      <div className="bg-[#000000]/20 w-full flex justify-center ">
        <div className="flex flex-col justify-between lg:flex-row   w-[95%] lg:w-[70%] tablet:w-[80%]">
          <div className="lg:w-[50%] text-white flex flex-col justify-center gap-5 p-5 lg:p-0">
            <p className="font-extrabold text-xl  lg:text-4xl">
              YOUR QUESTIONS AND <br /> SUGGESTIONS ARE WELCOME! <br /> TALK TO
              US!
            </p>
            <p className="lg:w-[80%]">
              ANY DOUBT OR THOUGHTS ON ANY ASPECT OF THE SCHOOL, PLEASE FEEL
              FREE TO CONTACT US.
            </p>
            <div className="flex items-center gap-3 text-3xl font-extrabold">
              <FaPhoneAlt className="bg-white text-[#000000] p-2 text-4xl rounded-lg" />
              <p className="">0512 - 400 1137</p>
            </div>
          </div>
          <div className="lg:w-[40%] h-full pb-5 lg:pb-0">
            <div className=" h-full bg-white ">
              <div className="text-center  bg-red-600 rounded-b-full py-5 text-2xl font-extrabold text-white">
                <p className=""> SUBMIT YOUR </p>
                <p className=""> ENQUIRY</p>
              </div>
              <div className=" p-3 lg:p-10">
                <div className="mb-5">
                  <InputFieldWithOutBorder
                    inputClassName={`!text-lg mb-5`}
                    placeholder={`Name`}
                  />
                </div>{" "}
                <div className="mb-5">
                  <InputFieldWithOutBorder
                    inputClassName={`!text-lg mb-5`}
                    placeholder={`Phone`}
                    type={`number`}
                  />
                </div>{" "}
                <div className="mb-5">
                  <InputFieldWithOutBorder
                    inputClassName={`!text-lg mb-5`}
                    placeholder={`Email`}
                    type={`email`}
                  />
                </div>{" "}
                <div className="mb-5">
                  <TextareaWithBorder
                    inputClassName={`!text-lg mb-5`}
                    placeholder={`Message`}
                    rows={4}
                  />
                </div>
                <div className="flex justify-center">
                  <CustomButton
                    label={`SEND MESSAGE`}
                    className={`hover:bg-[#edd156] text-white bg-[#ED0C0A] duration-300 border-none !p-5  font-extrabold hover:text-black`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enquery;
