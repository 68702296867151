import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline, IoCloseSharp } from "react-icons/io5";
import InputFieldWithBorder from "../../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { Calendar } from "primereact/calendar";
import { enqueueSnackbar } from "notistack";
import CustomAlertPopup from "../../../Shared/CustomAlertPopup/CustomAlertPopup";
import { useCreateStudentMutation } from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../../StringConstant/MotiLalAllConstant";
import { Utils } from "../../../Utils/Utils";
import { addLocale } from "primereact/api";
import axios from "axios";
import { API_CONSTANT } from "../../../ApiConstant/ApiConstant";
import { AuthContext } from "../../../Provider/AuthProvider";

function StudentRegistrationForm() {
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(1)?.[0]
  );
  const [udiceNumber, setUdiceNumber] = useState("");
  const [penNumber, setPenNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState(" ");
  const [adharNumber, setAdharNumber] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentMobile, setStudentMobile] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [dobDate, setDobDate] = useState(null);
  const [houseNumber, setHouseNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [homeState, setHomeState] = useState("Uttar Pradesh");
  const [pincode, setPincode] = useState("");
  const [previousSchool, setPreviousSchool] = useState("");
  const [previousClass, setPreviousClass] = useState("");
  const [previousPercentage, setPreviousPercentage] = useState("");
  const [previousBoard, setPreviousBoard] = useState("");
  const [previousPassingYear, setPreviousPassingYear] = useState("");
  const [hasScholarAttendedSchoolBefore, setHasScholarAttendedSchoolBefore] =
    useState(false);
  const [fathersOccupaton, setFathersOccupaton] = useState("");
  const [mothersOccupation, setMothersOccupation] = useState("");
  const [guardianRelationship, setGuardianRelationship] = useState("");
  const [nameOfGuardian, setNameOfGuardian] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [caste, setCaste] = useState("");
  const [religion, setReligion] = useState("");
  const [nationality, setNationality] = useState("INDIAN");
  const [medium, setMedium] = useState(userData?.medium);
  const [scholarNumber, setScholarNumber] = useState("");
  const [dateofAdmission, setDateofAdmission] = useState(new Date());
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [declaration1, setDeclaration1] = useState(false);
  const [declaration2, setDeclaration2] = useState(false);
  const [declaration3, setDeclaration3] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [balanceDue, setBalanceDue] = useState(0);
  const [subjectValue, setSubjectValue] = useState("");
  const [subjectsArray, setSubjectsArray] = useState([]);

  const [subjectDropdownValue, setSubjectDropdownValue] = useState("");
  const [relationDropdownValue, setRelationDropdownValue] = useState("");
  const [udiceNoDropdownValue, setUdiceNoDropdownValue] = useState(null);
  const [fathersOccupatonDropdownValue, setFathersOccupatonDropdownValue] =
    useState("");
  const [motherOccupatonDropdownValue, setMotherOccupatonDropdownValue] =
    useState("");

  const [checkboxWhatsaapNumber, setCheckboxWhatsaapNumber] = useState(false);

  const [createStudent, { isLoading: createStudentIsLoading }] =
    useCreateStudentMutation();

  const time = new Date();

  console.log("khfdcsn,", time.getTime());

  useEffect(() => {
    setScholarNumber(time.getTime().toString());
  }, []);

  console.log("sch", scholarNumber);

  const handleFormSave = () => {
    if (
      udiceNumber === null ||
      udiceNumber === undefined ||
      udiceNumber === ""
    ) {
      enqueueSnackbar("Udice Number is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (penNumber === null || penNumber === undefined || penNumber === "") {
      enqueueSnackbar("PEN Number is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      firstName === null ||
      firstName === undefined ||
      firstName === "" ||
      firstName.trim().length <= 2
    ) {
      enqueueSnackbar(
        "First name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (lastName === null || lastName === undefined || lastName === "") {
      enqueueSnackbar(
        "Last name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (
      adharNumber === null ||
      adharNumber === undefined ||
      adharNumber === ""
    ) {
      enqueueSnackbar("Aadhar Number is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      fatherName === null ||
      fatherName === undefined ||
      fatherName === "" ||
      fatherName.trim().length <= 2
    ) {
      enqueueSnackbar(
        "Father name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (
      motherName === null ||
      motherName === undefined ||
      motherName === "" ||
      motherName.trim().length <= 2
    ) {
      enqueueSnackbar(
        "Mother name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }

    if (
      studentEmail === null ||
      studentEmail === undefined ||
      studentEmail === ""
    ) {
      enqueueSnackbar("Student Email Id  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      studentMobile === null ||
      studentMobile === undefined ||
      studentMobile === ""
    ) {
      enqueueSnackbar("Student  Mobile Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      whatsAppNumber === null ||
      whatsAppNumber === undefined ||
      whatsAppNumber === ""
    ) {
      enqueueSnackbar("Whatsapp Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (dobDate === null || dobDate === undefined || dobDate === "") {
      enqueueSnackbar("Date of Birth  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      houseNumber === null ||
      houseNumber === undefined ||
      houseNumber === ""
    ) {
      enqueueSnackbar("House Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      houseNumber === null ||
      houseNumber === undefined ||
      houseNumber === ""
    ) {
      enqueueSnackbar("House Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      addressLine1 === null ||
      addressLine1 === undefined ||
      addressLine1 === ""
    ) {
      enqueueSnackbar("Address 1  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      addressLine2 === null ||
      addressLine2 === undefined ||
      addressLine2 === ""
    ) {
      enqueueSnackbar("Address 2  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (city === null || city === undefined || city === "") {
      enqueueSnackbar("City  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (homeState === null || homeState === undefined || homeState === "") {
      enqueueSnackbar("State  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (pincode === null || pincode === undefined || pincode === "") {
      enqueueSnackbar("Pin Code / Zip Code  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      fathersOccupaton === null ||
      fathersOccupaton === undefined ||
      fathersOccupaton === ""
    ) {
      enqueueSnackbar("Father Occupaton   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      mothersOccupation === null ||
      mothersOccupation === undefined ||
      mothersOccupation === ""
    ) {
      enqueueSnackbar("Mother Occupaton   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      nameOfGuardian === null ||
      nameOfGuardian === undefined ||
      nameOfGuardian === "" ||
      nameOfGuardian.trim().length <= 2
    ) {
      enqueueSnackbar(
        "Guardian  name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (
      guardianRelationship === null ||
      guardianRelationship === undefined ||
      guardianRelationship === ""
    ) {
      enqueueSnackbar("Guardian relationship   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      guardianPhone === null ||
      guardianPhone === undefined ||
      guardianPhone === ""
    ) {
      enqueueSnackbar("Guardian Phone No / Mobile No   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      guardianEmail === null ||
      guardianEmail === undefined ||
      guardianEmail === ""
    ) {
      enqueueSnackbar("Guardian Email Id   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (caste === null || caste === undefined || caste === "") {
      enqueueSnackbar("Scholar Cast   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (religion === null || religion === undefined || religion === "") {
      enqueueSnackbar("Religion   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      nationality === null ||
      nationality === undefined ||
      nationality === ""
    ) {
      enqueueSnackbar("Nationality   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (medium === null || medium === undefined || medium === "") {
      enqueueSnackbar("Medium   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      studentClass === null ||
      studentClass === undefined ||
      studentClass === ""
    ) {
      enqueueSnackbar("Class   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      studentSection === null ||
      studentSection === undefined ||
      studentSection === ""
    ) {
      enqueueSnackbar("Section   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      scholarNumber === null ||
      scholarNumber === undefined ||
      scholarNumber === ""
    ) {
      enqueueSnackbar("Scholar Number   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    if (
      dateofAdmission === null ||
      dateofAdmission === undefined ||
      dateofAdmission === ""
    ) {
      enqueueSnackbar("Date of Admission   is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      declaration1 === false ||
      declaration2 === false ||
      declaration3 === false
    ) {
      enqueueSnackbar("Please check all checkboxes !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (balanceDue < 0) {
      setBalanceDue(0);
    }
    setShowAlertPopup(true);
  };

  const handleCallApi = async () => {
    // console.log("sa", dobDate);
    const withPreviousYearEducationDataBody = {
      personalDetails: {
        dob: `${dobDate.getFullYear()}-${
          dobDate.getMonth() + 1
        }-${dobDate.getDate()}`,
        // dob: dobDate,
        firstName: firstName,
        lastName: lastName,
        motherName: motherName,
        fatherName: fatherName,
        email: studentEmail,
        phone: studentMobile,
        adharNumber: adharNumber,
        whatsAppNumber: whatsAppNumber,
      },
      addressDetails: {
        houseNumber: houseNumber,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: homeState,
        pincode: pincode,
      },
      educationDetails: {
        hasScholarAttendedSchoolBefore: hasScholarAttendedSchoolBefore,
        previousSchool: previousSchool,
        previousClass: previousClass,
        previousPercentage: previousPercentage,
        previousBoard: previousBoard,
        previousPassingYear: previousPassingYear,
      },
      otherDetails: {
        fathersOccupaton: fathersOccupaton,
        mothersOccupation: mothersOccupation,
        guardianRelationship: guardianRelationship,
        nameOfGuardian: nameOfGuardian,
        guardianPhone: guardianPhone,
        guardianEmail: guardianEmail,
        caste: caste.toUpperCase(),
        religion: religion.toUpperCase(),
        nationality: nationality.toUpperCase(),
        medium: userData?.medium,
        balanceDue: balanceDue,
      },
      subjects: subjectsArray,
      udiceNumber: udiceNumber,
      penNumber: penNumber,
      scholarNumber: scholarNumber,
      // dateofAdmission: dateofAdmission,
      dateofAdmission: `${dateofAdmission.getFullYear()}-${
        dateofAdmission.getMonth() + 1
      }-${dateofAdmission.getDate()}`,
      // dateofAdmission: "2020-04-30T18:30:00.000Z",
      password: "MLIC@123",
      role: "student",
      class: studentClass,
      section: studentSection,
      academicYear: academicYear,
    };
    const withOutPreviousYearEducationDataBody = {
      personalDetails: {
        dob: `${dobDate.getFullYear()}-${
          dobDate.getMonth() + 1
        }-${dobDate.getDate()}`,
        firstName: firstName,
        lastName: lastName,
        motherName: motherName,
        fatherName: fatherName,
        email: studentEmail,
        phone: studentMobile,
        adharNumber: adharNumber,
        whatsAppNumber: whatsAppNumber,
      },
      addressDetails: {
        houseNumber: houseNumber,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: homeState,
        pincode: pincode,
      },

      otherDetails: {
        fathersOccupaton: fathersOccupaton,
        mothersOccupation: mothersOccupation,
        guardianRelationship: guardianRelationship,
        nameOfGuardian: nameOfGuardian,
        guardianPhone: guardianPhone,
        guardianEmail: guardianEmail,
        caste: caste.toUpperCase(),
        religion: religion.toUpperCase(),
        nationality: nationality.toUpperCase(),
        medium: userData?.medium,
        balanceDue: balanceDue,
      },
      subjects: subjectsArray,
      udiceNumber: udiceNumber,
      penNumber: penNumber,
      scholarNumber: scholarNumber,
      dateofAdmission: `${dateofAdmission.getFullYear()}-${
        dateofAdmission.getMonth() + 1
      }-${dateofAdmission.getDate()}`,
      password: "MLIC@123",
      role: "student",
      class: studentClass,
      section: studentSection,
      academicYear: academicYear,
    };

    // console.log(
    //   "withPreviousYearEducationDataBody",
    //   withPreviousYearEducationDataBody
    // );
    // console.log(
    //   "withOutPreviousYearEducationDataBody",
    //   withOutPreviousYearEducationDataBody
    // );

    try {
      const response = await createStudent({
        body: hasScholarAttendedSchoolBefore
          ? withPreviousYearEducationDataBody
          : withOutPreviousYearEducationDataBody,
      });

      if (response.data) {
        // console.log(response);
        navigate(`/${userData?.medium}/student`);
      } else {
        // console.log(response?.error?.data?.message);
        enqueueSnackbar(response?.error?.data?.message, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  const handleRemoveChips = (index) => {
    const newChips = [...subjectsArray];
    newChips.splice(index, 1);
    setSubjectsArray(newChips);
  };

  const handleAddChips = () => {
    if (subjectValue.trim() === "") {
      enqueueSnackbar("Subject is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    if (subjectValue.trim() !== "") {
      const isSubjectExists = subjectsArray.some(
        (subject) => subject.subjectName === subjectValue.trim()
      );

      if (isSubjectExists) {
        enqueueSnackbar("This subject is  already exists !", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        return;
      }

      const newSubject = { subjectName: subjectValue.trim() };
      setSubjectsArray([...subjectsArray, newSubject]);
      setSubjectValue("");
    }
  };

  useEffect(() => {
    if (checkboxWhatsaapNumber === true) {
      setWhatsAppNumber(studentMobile);
    } else {
      setWhatsAppNumber("");
    }
  }, [checkboxWhatsaapNumber]);

  return (
    <div>
      {createStudentIsLoading && <CircleLoader />}
      <div className="flex justify-between items-center p-5 border-b pb-5">
        <p className="text-xl font-semibold text-[#2B60EC]">
          Student Registration form
        </p>
        <IoCloseSharp
          className={` text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
          onClick={() => navigate(`/${userData?.medium}/student`)}
        />
      </div>

      <div className="h-[90vh]  overflow-scroll">
        <div className=" border m-5   bg-white p-5 rounded-md ">
          <div className="rounded-md flex items-center  gap-10">
            <div className="flex items-start gap-3">
              <input
                type="radio"
                name="ScholarNumber"
                className="size-5 cursor-pointer"
                onChange={() =>
                  setAcademicYear(Utils?.generateAcademicYears(1)?.[0])
                }
                checked={academicYear === Utils?.generateAcademicYears(1)?.[0]}
              />
              <p className="">Current academic year</p>
            </div>
            <div className="flex items-start gap-3">
              <input
                type="radio"
                name="ScholarNumber"
                className="size-5 cursor-pointer"
                onChange={() => setAcademicYear(Utils?.previousAcademicYear())}
                checked={academicYear !== Utils?.generateAcademicYears(1)?.[0]}
              />
              <p className="">Previous academic year</p>
            </div>
          </div>
          <div className="my-3  flex items-end gap-5 ">
            {academicYear === Utils?.generateAcademicYears(1)?.[0] && (
              <InputFieldWithBorder
                inputFieldDisable
                label={`Academic year`}
                value={academicYear}
              />
            )}
            {academicYear !== Utils?.generateAcademicYears(1)?.[0] && (
              <div className="w-full mb-1">
                <select
                  value={academicYear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                  className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm  text-[#787B7C]"
                >
                  <option value="" disabled>
                    Choose academic year
                  </option>
                  {Utils?.generateAcademicYears(7)?.map(
                    (studentClassData, index) => (
                      <option value={studentClassData} key={index}>
                        {studentClassData}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
            <InputFieldWithBorder
              label={`Enter Scholar Number`}
              value={scholarNumber}
              onChange={(e) => setScholarNumber(e.target.value)}
            />

            {udiceNoDropdownValue !== "" && (
              <div className="flex flex-col w-full text-sm  ">
                <p className="mb-1 text-[#797b7c] ">Choose udice number</p>
                <select
                  value={udiceNoDropdownValue || udiceNumber}
                  onChange={(e) => {
                    if (e.target.value !== "Other") {
                      setUdiceNumber(e.target.value);
                      setUdiceNoDropdownValue(e.target.value);
                    } else {
                      setUdiceNoDropdownValue(e.target.value);
                    }
                  }}
                  className="w-full p-1.5 mb-1 border border-[#E4E7EB] rounded-md text-sm "
                >
                  <option value="" disabled>
                    Choose udice number
                  </option>
                  {STUDENT_REGISTRATION_FORM_CONSTANT?.UDICE_NUMBER_ARRAY?.map(
                    (uNumber, index) => (
                      <option value={uNumber?.number} key={index}>
                        {uNumber?.lable}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
            {udiceNoDropdownValue === "" && (
              <div className="w-full ">
                <InputFieldWithBorder
                  label={`Enter Udice Number`}
                  value={udiceNumber}
                  onChange={(e) => setUdiceNumber(e.target.value)}
                />
              </div>
            )}

            <InputFieldWithBorder
              label={`Enter PEN Number`}
              value={penNumber}
              onChange={(e) => setPenNumber(e.target.value)}
            />
          </div>
          <div className="my-3 w-[30%]"></div>
        </div>
        <div className="border m-5  rounded-md bg-white">
          <p className="p-5 pb-2 text-[#2B60EC]">PERSONAL DETAILS</p>
          <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>
          <div className="">
            <div className="  p-5  overflow-auto ">
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full ">
                  <p className={`text-[#797b7c] text-sm mb-1  `}>
                    Date of Application
                  </p>

                  <Calendar
                    inputClassName="border  p-1.5 rounded-lg text-sm mb-1  text-[#787B7C]"
                    value={dateofAdmission}
                    onChange={(e) => {
                      console.log(e.value);
                      const date = e.value;

                      setDateofAdmission(date);
                      console.log(date);
                    }}
                    dateFormat="dd/mm/yy"
                    className="w-full "
                    placeholder={`dd/mm/yy`}
                  />
                </div>

                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`First Name of Scholar`}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Last Name of Scholar`}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Aadhar No. of Scholar `}
                    value={adharNumber}
                    onChange={(e) => setAdharNumber(e.target.value)}
                    type={`number`}
                  />
                </div>

                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Full Name of Father`}
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Full Name of Mother`}
                    value={motherName}
                    onChange={(e) => setMotherName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full flex items-end  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Student Email Id of Scholar`}
                    type={`email`}
                    value={studentEmail}
                    onChange={(e) => setStudentEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full relative ">
                  <InputFieldWithBorder
                    label={` Student Phone No. / Mob.No.`}
                    value={studentMobile}
                    onChange={(e) => setStudentMobile(e.target.value)}
                    type={`number`}
                  />
                  <div className="flex items-center gap-2 absolute top-[100%] ">
                    <input
                      type="checkbox"
                      className="size-4 cursor-pointer"
                      onChange={() => {
                        setCheckboxWhatsaapNumber(!checkboxWhatsaapNumber);
                      }}
                      checked={checkboxWhatsaapNumber}
                    />
                    <p className="text-[#797b7c] text-sm">
                      This number is whatsapp number
                    </p>
                  </div>
                </div>

                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Whatsapp No. `}
                    value={whatsAppNumber}
                    onChange={(e) => setWhatsAppNumber(e.target.value)}
                    type={`number`}
                  />
                </div>
              </div>
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-[32%]">
                  <p className={`text-[#797b7c] text-sm mb-1  `}>
                    Date of Birth in certificate
                  </p>

                  <Calendar
                    inputClassName="border  p-1.5 rounded-lg text-sm mb-1  text-[#787B7C]"
                    value={dobDate}
                    onChange={(e) => {
                      const date = e.value;

                      setDobDate(date);
                      console.log("dob date ", date);
                    }}
                    dateFormat="dd/mm/yy"
                    className="w-full "
                    placeholder={`DD/MM/YYYY`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" m-5 ">
          <div className="flex items-start gap-4 text-sm">
            <input
              type="checkbox"
              className="size-5 cursor-pointer"
              onChange={() =>
                setHasScholarAttendedSchoolBefore(
                  !hasScholarAttendedSchoolBefore
                )
              }
              checked={hasScholarAttendedSchoolBefore}
            />
            <p className="">Has scholar attended school before.</p>
          </div>
        </div>
        {hasScholarAttendedSchoolBefore && (
          <div className="border m-5  rounded-md bg-white">
            <p className="p-5 pb-2 text-[#2B60EC]">EDUCATION DETAILS</p>
            <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>
            <div className="">
              <div className="  p-5  overflow-auto ">
                <div className="w-full flex  gap-5 items-end">
                  <div className="mb-3 w-full ">
                    {/* <InputFieldWithBorder
                      label={`Previous Board`}
                      value={previousBoard}
                      onChange={(e) => setPreviousBoard(e.target.value)}
                    /> */}
                    <select
                      value={previousBoard}
                      onChange={(e) => setPreviousBoard(e.target.value)}
                      className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose previous board
                      </option>
                      <option value={`UP BOARD`}>UP BOARD</option>
                      <option value={`CBSE BOARD`}>CBSE BOARD</option>
                      <option value={`ICSE BOARD`}>ICSE BOARD</option>
                      <option value={`BSA OFFICE`}>BSA OFFICE</option>
                    </select>
                  </div>
                  <div className="mb-3 w-full ">
                    <InputFieldWithBorder
                      label={`Previous School`}
                      value={previousSchool}
                      onChange={(e) => setPreviousSchool(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 w-full">
                    <select
                      value={previousClass}
                      onChange={(e) => setPreviousClass(e.target.value)}
                      className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose previous class
                      </option>
                      {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                        (studentClassData, index) => (
                          <option value={studentClassData} key={index}>
                            {studentClassData}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="w-[50%] flex  gap-5 items-end">
                  <div className="mb-3 w-full ">
                    <InputFieldWithBorder
                      label={`Previous Percentage `}
                      value={previousPercentage}
                      onChange={(e) => setPreviousPercentage(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 w-full ">
                    {/* <InputFieldWithBorder
                      label={`Previous Passing Year `}
                      value={previousPassingYear}
                      onChange={(e) => setPreviousPassingYear(e.target.value)}
                    /> */}

                    <select
                      value={previousPassingYear}
                      onChange={(e) => setPreviousPassingYear(e.target.value)}
                      className="w-full p-1.5 mb-1 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose previous year
                      </option>
                      {Utils?.generateYears(20)?.map((year, index) => (
                        <option value={year} key={index}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="border m-5  rounded-md bg-white">
          <p className="p-5 pb-2 text-[#2B60EC]">ADDRESS DETAILS</p>
          <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>
          <div className="">
            <div className="  p-5  overflow-auto ">
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Building No`}
                    value={houseNumber}
                    onChange={(e) => setHouseNumber(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Address Line 1`}
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={` Address Line 2`}
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`City `}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`State`}
                    value={homeState}
                    onChange={(e) => setHomeState(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Pin code / Zip code`}
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border m-5  rounded-md bg-white">
          <p className="p-5 pb-2 text-[#2B60EC]">OTHER DETAILS</p>
          <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>
          <div className="p-5 flex items-start gap-5 justify-between">
            <div className=" w-[50%]  border border-[#2B60EC] p-5 rounded min-h-[17rem]  flex flex-col justify-between">
              <div className="w-full flex items-end  gap-5">
                {fathersOccupatonDropdownValue !== "Other" && (
                  <div className="mb-3 w-full ">
                    <p className="text-sm mb-1  text-[#787B7C]">
                      {" "}
                      Father's Occupation
                    </p>

                    <select
                      value={fathersOccupatonDropdownValue || fathersOccupaton}
                      onChange={(e) => {
                        if (e.target.value !== "Other") {
                          setFathersOccupaton(e.target.value);
                          setFathersOccupatonDropdownValue("");
                        } else {
                          setFathersOccupatonDropdownValue(e.target.value);
                        }
                      }}
                      className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose occupation
                      </option>
                      {STUDENT_REGISTRATION_FORM_CONSTANT?.FATHER_OCCUPATION?.map(
                        (studentClassData, index) => (
                          <option value={studentClassData} key={index}>
                            {studentClassData}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                {fathersOccupatonDropdownValue === "Other" && (
                  <div className="w-full mb-3">
                    <InputFieldWithBorder
                      label={`Name of father's occupation `}
                      value={fathersOccupaton}
                      onChange={(e) => setFathersOccupaton(e.target.value)}
                    />
                  </div>
                )}
                {motherOccupatonDropdownValue !== "Other" && (
                  <div className="mb-3 w-full ">
                    <p className="text-sm mb-1  text-[#787B7C]">
                      {" "}
                      Mother's Occupation
                    </p>

                    <select
                      value={motherOccupatonDropdownValue || mothersOccupation}
                      onChange={(e) => {
                        if (e.target.value !== "Other") {
                          setMothersOccupation(e.target.value);
                          setMotherOccupatonDropdownValue("");
                        } else {
                          setMotherOccupatonDropdownValue(e.target.value);
                        }
                      }}
                      className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose occupation
                      </option>
                      {STUDENT_REGISTRATION_FORM_CONSTANT?.MOTHER_OCCUPATION?.map(
                        (studentClassData, index) => (
                          <option value={studentClassData} key={index}>
                            {studentClassData}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                {motherOccupatonDropdownValue === "Other" && (
                  <div className="w-full mb-3">
                    <InputFieldWithBorder
                      label={`Name of mother's occupation `}
                      value={mothersOccupation}
                      onChange={(e) => setMothersOccupation(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex items-end  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Name Of Guardian `}
                    value={nameOfGuardian}
                    onChange={(e) => setNameOfGuardian(e.target.value)}
                  />
                </div>
                {relationDropdownValue !== "Other" && (
                  <div className="mb-4 w-full  flex flex-col  ">
                    <p className="text-sm mb-1  text-[#787B7C]">
                      {" "}
                      Guardian relationship
                    </p>

                    <select
                      value={relationDropdownValue || guardianRelationship}
                      onChange={(e) => {
                        if (e.target.value !== "Other") {
                          setGuardianRelationship(e.target.value);
                          setRelationDropdownValue("");
                        } else {
                          setRelationDropdownValue(e.target.value);
                        }
                      }}
                      className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    >
                      <option value="" disabled>
                        Choose relationship
                      </option>
                      {STUDENT_REGISTRATION_FORM_CONSTANT?.RELETIONS?.map(
                        (studentClassData, index) => (
                          <option value={studentClassData} key={index}>
                            {studentClassData}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                {relationDropdownValue === "Other" && (
                  <div className="mb-3 w-full ">
                    <InputFieldWithBorder
                      label={` Guardian  relationship `}
                      value={guardianRelationship}
                      onChange={(e) => setGuardianRelationship(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex items-end  gap-5">
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Guardian Phone No / Mobile No`}
                    value={guardianPhone}
                    onChange={(e) => setGuardianPhone(e.target.value)}
                    type={`number`}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <InputFieldWithBorder
                    label={`Guardian Email Id `}
                    value={guardianEmail}
                    onChange={(e) => setGuardianEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[50%]  border border-[#2B60EC] p-5 rounded min-h-[17rem] flex flex-col justify-between ">
              <div className="w-full flex items-end gap-5">
                <div className="mb-3 w-full ">
                  <p className="text-sm mb-1  text-[#787B7C]"> Scholar caste</p>
                  <select
                    value={caste}
                    onChange={(e) => setCaste(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                  >
                    <option value="" disabled>
                      Choose caste
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CASTE_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="mb-3 w-full ">
                  <p className="text-sm mb-1  text-[#787B7C]">
                    {" "}
                    Scholar religion
                  </p>

                  <select
                    value={religion}
                    onChange={(e) => setReligion(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                  >
                    <option value="" disabled>
                      Choose religion
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_RELIGION_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>{" "}
              <div className="w-full flex items-end gap-5">
                <div className="mb-2 w-full  ">
                  <InputFieldWithBorder
                    label={`Nationality`}
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    inputClassName={`!uppercase`}
                  />
                </div>
                <div className="mb-3 w-full ">
                  <p className="text-sm mb-1  text-[#787B7C]">
                    {" "}
                    Scholar medium
                  </p>

                  <select
                    value={userData?.medium}
                    onChange={(e) => setMedium(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                    disabled
                  >
                    <option value="">Choose medium</option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_MEDIUM_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="w-full flex items-end gap-5">
                <div className="mb-3 w-full ">
                  <p className="text-sm mb-1  text-[#787B7C]"> Scholar class</p>

                  <select
                    value={studentClass}
                    onChange={(e) => setStudentClass(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                  >
                    <option value="" disabled>
                      Choose class
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="mb-3 w-full ">
                  <p className="text-sm mb-1  text-[#787B7C]">
                    {" "}
                    Scholar section
                  </p>

                  <select
                    value={studentSection}
                    onChange={(e) => setStudentSection(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                  >
                    <option value="" disabled>
                      Choose Scholar Section
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
                      (studentSectionData, index) => (
                        <option value={studentSectionData} key={index}>
                          {studentSectionData}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex  gap-5 px-5">
            <div className="mb-3 w-[15rem]">
              <InputFieldWithBorder
                type={`number`}
                label={` Previous year due balance  `}
                value={balanceDue}
                onChange={(e) => {
                  if (balanceDue < 0) {
                    setBalanceDue(0);
                  } else {
                    setBalanceDue(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="border m-5  rounded-md bg-white">
          <p className="p-5 pb-2 text-[#2B60EC]">SUBJECT DETAILS</p>
          <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>

          <div className=" p-5 flex items-start gap-5">
            <div className="w-[50%] border border-[#2B60EC] p-5 rounded">
              {/* {subjectDropdownValue !== "Other" && ( */}
              <div className="mb-3 w-full ">
                <select
                  value={subjectDropdownValue || subjectValue}
                  onChange={(e) => {
                    if (e.target.value !== "Other") {
                      setSubjectValue(e.target.value);
                      setSubjectDropdownValue("");
                    } else {
                      setSubjectDropdownValue(e.target.value);
                    }
                  }}
                  className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm "
                >
                  <option value="" disabled>
                    Choose Subjects
                  </option>
                  {STUDENT_REGISTRATION_FORM_CONSTANT?.COMMON_SUBJECTS?.map(
                    (subjectData, index) => (
                      <option value={subjectData} key={index}>
                        {subjectData}
                      </option>
                    )
                  )}
                </select>
              </div>
              {/* )} */}
              {subjectDropdownValue === "Other" && (
                <div className="w-full mb-3">
                  <InputFieldWithBorder
                    label={`Subject name`}
                    value={subjectValue}
                    onChange={(e) => setSubjectValue(e.target.value)}
                  />
                </div>
              )}

              <CustomButton
                label={`Add Subject`}
                className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
                onClick={() => handleAddChips()}
              />
            </div>
            <div className=" flex flex-row flex-wrap  w-[50%%]">
              {subjectsArray.map((chip, index) => (
                <div
                  key={index}
                  className="bg-[#DFDFDF] text-gray-800 p-3 rounded-xl cursor-pointer me-2 flex items-center capitalize"
                >
                  {chip.subjectName} {/* Render the subjectName property */}
                  <IoCloseOutline
                    className="ms-5 text-xl text-white rounded-full bg-[#A7A7A7]"
                    onClick={() => handleRemoveChips(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="border m-5  rounded-md bg-white">
          <p className="p-5 pb-2 text-[#2B60EC]">Declaration</p>
          <div className="h-[2px] bg-[#2B60EC] w-[4rem] mx-5"></div>
          <div className="flex flex-col gap-3  p-5  text-[#787B7C] text-sm">
            <div className="flex items-start gap-4">
              <input
                type="checkbox"
                className="size-5 cursor-pointer"
                onChange={() => setDeclaration1(!declaration1)}
                checked={declaration1}
              />
              <p className="">
                I hereby declare that all statements made in the application are
                true, complete and correct to the best of my knowledge and
                belief.
              </p>
            </div>
            <div className="flex items-start gap-4">
              <input
                type="checkbox"
                className="size-5 cursor-pointer"
                onChange={() => setDeclaration2(!declaration2)}
                checked={declaration2}
              />
              <p className="">
                I fulfill all conditions of eligibility regarding Date of birth,
                Attendance, Rules and Discipline.
              </p>
            </div>
            <div className="flex items-start gap-4">
              <input
                type="checkbox"
                className="size-5 cursor-pointer"
                onChange={() => setDeclaration3(!declaration3)}
                checked={declaration3}
              />
              <p className="">
                I have read and agreed with the instruction and other relevant
                information.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <CustomButton
            label={`Save`}
            className={` text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] mx-5`}
            onClick={() => handleFormSave()}
          />
        </div>
      </div>
      {showAlertPopup && (
        <CustomAlertPopup
          title={`warning`}
          discreption={`Check the information given by you once again because once the form is submitted, there will be no changes in this form.`}
          cancelBtnLabel={`Cancel`}
          cancelBtnOnClick={setShowAlertPopup}
          submitBtnLabel={`Ok`}
          submitBtnOnClick={handleCallApi}
        />
      )}
    </div>
  );
}

export default StudentRegistrationForm;
