import React, { useEffect, useState } from "react";
import {
  useGetStudentsByStaffIdQuery,
  useMarkAttendanceMutation,
} from "../../Redux/ReduxToolkit";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CircleLoader from "../../AllLoaders/CircleLoader";
import { enqueueSnackbar } from "notistack";
import ViewAttendance from "./ViewAttendance";
import { Utils } from "../../Utils/Utils";

function MyStudents() {
  const staffId = localStorage.getItem("staffId");
  const [status, setStatus] = useState("A");
  const [studentsAttendance, setStudentsAttendance] = useState([]);
  const [currentClass, setCurrentClass] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const [academicYear, setAcademicYear] = useState(
    Utils.generateAcademicYears(1)?.[0]
  );
  const [showViewAttendance, setShowViewAttendance] = useState(false);
  const [currentScholarId, setCurrentScholarId] = useState("");

  const [markAttendance, { isLoading: markAttendanceLoading }] =
    useMarkAttendanceMutation();

  const {
    data: getStudentsByStaffId,
    isLoading: getStudentsByStaffIdIsLoading,
  } = useGetStudentsByStaffIdQuery(staffId || "");

  const handleAttendanceChange = (
    scholarNumber,
    index,
    attendanceStatus,
    studentName,
    studentWhatsappNumber,
    student_id,
    fatherName
  ) => {
    // console.log(
    //   "Attendance for scholar number",
    //   scholarNumber,
    //   "changed to",
    //   attendanceStatus
    // );

    const updatedAttendance = [...studentsAttendance];
    updatedAttendance[index] = {
      scholarNumber: scholarNumber,
      status: attendanceStatus,
      studentName: studentName,
      // studentNumber: studentWhatsappNumber,
      studentNumbe: "7651893713",
      studentUid: student_id,
      fatherName: fatherName,
    };
    // console.log("update student data : ", updatedAttendance);

    setStudentsAttendance(updatedAttendance);
  };

  useEffect(() => {
    if (getStudentsByStaffId?.students) {
      const attendanceData = getStudentsByStaffId.students.map((student) => ({
        scholarNumber: student?.scholarNumber,
        status: status,
        fatherName: student?.personalDetails?.fatherName,
        studentName: `${student?.personalDetails?.firstName} ${student?.personalDetails?.lastName}`,
        // studentNumber: student?.personalDetails?.phone,
        studentNumber: "7651893713",
        student_id: student?._id,
      }));
      // console.log("useEffct ke andar : ", attendanceData);
      setStudentsAttendance(attendanceData);
    }
  }, [getStudentsByStaffId]);

  useEffect(() => {
    setCurrentClass(getStudentsByStaffId?.students?.[0]?.class);
    setCurrentSection(getStudentsByStaffId?.students?.[0]?.section);
    // console.log("Students Attendance:", studentsAttendance);
    console.log("Array", studentsAttendance);
  }, [studentsAttendance]);

  // console.log("attendanceData", studentsAttendance);

  const handleSave = async () => {
    try {
      // Filter out null or unchanged attendance records
      const filteredAttendance = studentsAttendance.filter(
        (record) => record !== null && record?.status !== null
      );

      const response = await markAttendance({
        body: {
          className: currentClass,
          section: currentSection,
          academicYear: Utils.generateAcademicYears(1)?.[0],
          attendanceRecords: filteredAttendance,
        },
      });

      if (response.data) {
        // console.log("Attendance saved successfully:");
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      } else {
        console.log("Oops! Attendance not saved.");
      }
    } catch (error) {
      console.error("Attendance saving failed:", error?.message);
    }
  };

  return (
    <div className="lg:p-5 h-full rounded-md py-12 lg:pt-5  px-2 ">
      {(getStudentsByStaffIdIsLoading || markAttendanceLoading) && (
        <CircleLoader />
      )}

      <div className="h-full  overflow-y-scroll no-scrollbar ">
        <div className="lg:w-[20rem] lg:px-5 mb-5 flex gap-5">
          <select
            value={academicYear}
            onChange={(e) => {
              setAcademicYear(e.target.value);
            }}
            className="w-full p-2 border border-[#E4E7EB] rounded-md lg:text-sm"
          >
            <option value="" disabled>
              Choose Academic Year
            </option>
            {Utils?.generateAcademicYears(5)?.map((studentClassData, index) => (
              <option value={studentClassData} key={index}>
                {studentClassData}
              </option>
            ))}
          </select>
          <CustomButton
            label={`Save`}
            className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] px-10 `}
            onClick={() => handleSave()}
          />
        </div>

        <div className="lg:p-5 text-sm">
          {studentsAttendance && studentsAttendance?.length > 0 ? (
            <div className="overflow-auto max-h-[580px] lg:max-h-[600px] ">
              <table className="w-full text-sm bg-white rounded">
                <thead className="sticky top-0 bg-white shadow  z-40">
                  <tr className="text-gray-700">
                    <th className="p-3 text-start">S.No</th>
                    <th className="p-3 text-start hidden lg:block">
                      Scholar Number
                    </th>
                    <th className="p-3 text-start">Scholar Name</th>
                    {/* <th className="p-3 text-start">Father Name</th> */}
                    <th className="p-3 text-start hidden lg:block">
                      Mobile No
                    </th>
                  </tr>
                </thead>
                <tbody className="z-0">
                  {studentsAttendance?.map((stDetails, index) => (
                    <tr
                      className={`text-gray-500 cursor-pointer hover:bg-gray-400/40 z-0 ${
                        index % 2 == 0 ? "bg-gray-100" : ""
                      }`}
                      key={index}
                    >
                      <td className="p-3 text-start">{index + 1}</td>
                      <td className="p-3 text-start capitalize hidden lg:block">
                        {stDetails?.scholarNumber}
                      </td>
                      <td className="p-3 text-start capitalize">
                        {stDetails?.studentName}
                      </td>
                      {/* <td className="p-3 text-start capitalize">
                        {stDetails?.fatherName}
                      </td> */}
                      <td className="p-3 text-start capitalize hidden lg:block">
                        {stDetails?.studentNumber}
                      </td>
                      <td className="py-2  relative z-0">
                        <AttendanceSwitchBtn
                          setStatus={setStatus}
                          index={index}
                          scholarNumber={stDetails?.scholarNumber}
                          studentName={stDetails?.studentName}
                          studentWhatsappNumber={stDetails?.studentNumber}
                          student_id={stDetails?.student_id}
                          fatherName={stDetails?.fatherName}
                          onAttendanceChange={handleAttendanceChange}
                        />
                      </td>
                      <td className="p-3 text-center capitalize  relative hidden ">
                        <CustomButton
                          label={`View Attendance`}
                          className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
                          onClick={() => {
                            setShowViewAttendance(true);
                            setCurrentScholarId(stDetails?.scholarNumber);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-xl pt-20 ">
              <img
                src={require("../../assets/noData_image.png")}
                alt=""
                className="w-[10rem] mb-5 bg-transparent"
              />
              <p>No students in this class.</p>
            </div>
          )}
        </div>

        {/* <CustomButton
          label={`Save`}
          className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] hidden lg:block`}
          onClick={() => handleSave()}
        /> */}
      </div>

      {showViewAttendance && (
        <ViewAttendance
          setShowViewAttendance={setShowViewAttendance}
          rightPopup={`animate-rightPopup`}
          currentScholarId={currentScholarId}
        />
      )}
    </div>
  );
}

const AttendanceSwitchBtn = ({
  setStatus,
  index,
  scholarNumber,
  onAttendanceChange,
  studentName,
  studentWhatsappNumber,
  student_id,
  fatherName,
}) => {
  const [present, setPresent] = useState("A");

  useEffect(() => {
    setStatus(present);
    onAttendanceChange(
      scholarNumber,
      index,
      present,
      studentName,
      studentWhatsappNumber,
      student_id,
      fatherName
    ); // Pass scholarNumber to the handler
  }, [present]);

  return (
    <div className="flex flex-col lg:flex-row  items-center  text-xs lg:text-base  justify-center z-0">
      <p
        className={`py-1.5 px-2 rounded-t-lg lg:rounded-l-lg lg:rounded-tr-none border ${
          present === "A" ? "bg-red-500 text-white" : ""
        }`}
        onClick={() => setPresent("A")}
      >
        Absent
      </p>
      <p
        className={`py-1.5 px-2 rounded-b-lg lg:rounded-r-lg lg:rounded-bl-none border ${
          present === "P" ? "bg-green-500 text-white" : ""
        }`}
        onClick={() => setPresent("P")}
      >
        Present
      </p>
    </div>
  );
};

export default MyStudents;
