import React, { useEffect, useState } from "react";
import {
  useGetClassAttendanceByRangeQuery,
  useGetStudentsByStaffIdQuery,
} from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";
import { Calendar as PrimeCalendar } from "primereact/calendar";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { Utils } from "../../Utils/Utils";
import ClassAttendanceReportTable from "./ClassAttendanceReportTable";

function ClassAttendanceReport() {
  const staffId = localStorage.getItem("staffId");
  const [currentClass, setCurrentClass] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [formateFromDate, setFormateFromDate] = useState("");
  const [formateToDate, setFormateToDate] = useState("");
  const [dateRange, setDateRange] = useState("today");
  const [todayAttendanceReport, setTodayAttendanceReport] = useState({
    totalStudent: 0,
    presentStudent: 0,
    absentStudent: 0,
  });
  const [classAttendanceData, setClassAttendanceData] = useState();

  const {
    data: getStudentsByStaffId,
    isLoading: getStudentsByStaffIdIsLoading,
  } = useGetStudentsByStaffIdQuery(staffId, { skip: !staffId });

  const {
    data: getClassAttendanceApiResponce,
    isLoading: getClassAttendanceApiResponceIsLoading,
    refetch: getClassAttendanceApiResponceRefetch,
  } = useGetClassAttendanceByRangeQuery(
    {
      studentClass: currentClass,
      studentSection: currentSection,
      fromDate: formateFromDate,
      toDate: formateToDate,
    },
    {
      skip:
        currentClass === "" ||
        currentSection === "" ||
        !formateFromDate ||
        !formateToDate,
    }
  );

  useEffect(() => {
    if (getStudentsByStaffId) {
      setCurrentClass(getStudentsByStaffId?.students?.[0]?.class);
      setCurrentSection(getStudentsByStaffId?.students?.[0]?.section);
    }
  }, [getStudentsByStaffId]);

  useEffect(() => {
    if (getClassAttendanceApiResponce) {
      countAttendanceStatuses();
      setClassAttendanceData(getClassAttendanceApiResponce?.data);
    }
  }, [getClassAttendanceApiResponce]);

  useEffect(() => {
    setFormateFromDate(
      Utils?.formateDateForAttendanceRangeApiRequest(fromDate)
    );
    setFormateToDate(Utils?.formateDateForAttendanceRangeApiRequest(toDate));
  }, [fromDate, toDate]);

  const handleSearch = () => {
    console.log("Search parameters", {
      fromDate: formateFromDate,
      toDate: formateToDate,
      currentClass: currentClass,
      currentSection: currentSection,
    });
  };

  // useEffect(() => {
  //   console.log("Current date useEffct me ", fromDate);
  // }, [dateRange]);

  // console.log(
  //   "count",
  //   getClassAttendanceApiResponce?.data?.[0]?.attendanceEntries
  // );

  const countAttendanceStatuses = () => {
    // Safely access the attendanceEntries array
    const entries =
      getClassAttendanceApiResponce?.data?.[
        getClassAttendanceApiResponce?.data?.length - 1
      ]?.attendanceEntries || [];

    // Count the number of present and absent entries
    const presentCount = entries.filter((entry) => entry.status === "P").length;
    const absentCount = entries.filter((entry) => entry.status === "A").length;

    setTodayAttendanceReport({
      totalStudent: presentCount + absentCount,
      presentStudent: presentCount,
      absentStudent: absentCount,
    });

    // console.log("Count of Present Entries:", presentCount);
    // console.log("Count of Absent Entries:", absentCount);
  };

  // console.log(classAttendanceData);
  console.log("getClassAttendanceApiResponce", getClassAttendanceApiResponce);
  // console.log({
  //   studentClass: currentClass,
  //   studentSection: currentSection,
  //   fromDate: formateFromDate,
  //   toDate: formateToDate,
  // });

  return (
    <div className="p-5 h-full flex flex-col  ">
      {(getStudentsByStaffIdIsLoading ||
        getClassAttendanceApiResponceIsLoading) && <CircleLoader />}
      <div className="shadow-no-border-shadow-2  p-5 rounded-xl flex items-start justify-between bg-white ">
        <div className="">
          <div className="flex items-center gap-5 mb-1 ">
            <p>Class - {currentClass}</p>
            <p>Section - {currentSection}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p>
              Total Student in the class - {todayAttendanceReport?.totalStudent}
            </p>
            <p>
              Today Present Student - {todayAttendanceReport?.presentStudent}
            </p>
            <p>Today Absent Student - {todayAttendanceReport?.absentStudent}</p>
          </div>
        </div>
        <div className=" w-[55%] flex flex-col justify-end gap-3">
          <div className="mb-2 flex justify-end">
            <CustomButton
              label="Today"
              className={`${
                dateRange === "today"
                  ? "border-[#2B60EC] bg-[#2B60EC] text-white"
                  : "border-[#acacac] text-[#acacac]"
              }`}
              onClick={() => {
                setDateRange("today");
                setFromDate(
                  Utils?.formatDateBeforeInString(new Date(), "today")
                );
                getClassAttendanceApiResponceRefetch();
              }}
            />
            <CustomButton
              label="Last 7 Days"
              className={`${
                dateRange === "week"
                  ? "border-[#2B60EC] bg-[#2B60EC] text-white"
                  : "border-[#acacac] text-[#acacac]"
              }`}
              onClick={() => {
                setDateRange("week");
                setFromDate(
                  Utils?.formatDateBeforeInString(new Date(), "week")
                );
                getClassAttendanceApiResponceRefetch();
              }}
            />
            <CustomButton
              label="Last 15 Days"
              className={`${
                dateRange === "2week"
                  ? "border-[#2B60EC] bg-[#2B60EC] text-white"
                  : "border-[#acacac] text-[#acacac]"
              }`}
              onClick={() => {
                setDateRange("2week");
                setFromDate(
                  Utils?.formatDateBeforeInString(new Date(), "2week")
                );
              }}
            />
            <CustomButton
              label="Last 30 Days"
              className={`me-0 ${
                dateRange === "month"
                  ? "border-[#2B60EC] bg-[#2B60EC] text-white"
                  : "border-[#acacac] text-[#acacac]"
              }`}
              onClick={() => {
                setDateRange("month");
                setFromDate(
                  Utils?.formatDateBeforeInString(new Date(), "month")
                );
              }}
            />
          </div>
          <div className="flex  items-end justify-between pe-5">
            <div className=" flex items-end gap-3 ">
              <p className="mb-1 text-lg">From Date </p>
              <PrimeCalendar
                inputClassName="border px-4 w-[12rem] h-[3rem] rounded-lg"
                value={fromDate}
                onChange={(e) => setFromDate(e.value)}
                dateFormat="dd/mm/yy"
                className="w-[10rem]"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div className=" flex items-end gap-3">
              <p className="mb-1 text-lg">To Date </p>
              <PrimeCalendar
                inputClassName="border px-4 w-[12rem] h-[3rem] rounded-lg"
                value={toDate}
                onChange={(e) => setToDate(e.value)}
                dateFormat="dd/mm/yy"
                className="w-[10rem]"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
      <ClassAttendanceReportTable
        getClassAttendanceApiResponce={getClassAttendanceApiResponce?.data}
      />
    </div>
  );
}

export default ClassAttendanceReport;
