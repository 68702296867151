import aleart_svg from "../../assets/aleart_svg.svg";
function CustomAlertPopup({
  title,
  discreption,
  cancelBtnLabel,
  submitBtnLabel,
  cancelBtnOnClick,
  submitBtnOnClick,
}) {
  return (
    <div className="fixed top-0 left-0  bg-gray-500/50 h-screen w-full  z-20 flex justify-center items-center">
      <div className="bg-white p-5  w-[30rem] rounded-2xl text-start animate-bottomPopup flex flex-col gap-20">
        <div className="flex flex-col gap-5">
          <div className="w-full  flex justify-center">
            <img src={aleart_svg} alt="" />
          </div>
          <div className="text-center text-gray-700  flex flex-col items-center ">
            <p className="font-semibold  capitalize text-xl mb-5">{title}</p>
            <p className="text-sm capitalize w-[80%]">{discreption}</p>
          </div>
        </div>

        <div className="flex items-center w-full  gap-5">
          {cancelBtnLabel && (
            <button
              className={`w-full hover:bg-red-500 rounded-md hover:text-white border hover:border-transparent  border-red-500 text-red-500 bg-transparent p-2 `}
              onClick={() => cancelBtnOnClick(false)}
            >
              {cancelBtnLabel}
            </button>
          )}
          <button
            // className=" bg-[#06C277] hover:bg-[#51b48c] border-none text-white   font-normal   px-5 py-1.5 rounded-md  flex items-center gap-3 text-sm"
            className={`w-full p-2 rounded-md text-green-600/90 border border-green-600/90 uppercase hover:bg-green-600/90 hover:text-white`}
            onClick={submitBtnOnClick}
          >
            {submitBtnLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomAlertPopup;
