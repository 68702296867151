import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../WebsitePages/HomePage/HomePage";
import WebsitePages from "../../WebsitePages/WebsitePages";
import CertificateTemplates from "../../WebsitePages/CertificateTemplates/CertificateTemplates";
import AfterLoginUnauthorise from "../../WebsitePages/WildCardRouteComponent/AfterLoginUnauthorise";
import Gallery from "../../WebsitePages/Gallery/Gallery";

function WebRouter() {
  return (
    <Routes>
      <Route path="*" Component={AfterLoginUnauthorise} />
      <Route path="/" Component={WebsitePages} />
      <Route path="/certificate" Component={CertificateTemplates} />
      <Route path="/gallery" Component={Gallery} />
    </Routes>
  );
}

export default WebRouter;
