import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import InputFieldWithBorder from "../../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import {
  MLMEC_AllClassFeeStructure,
  PG_TO_UKG,
} from "../../../StringConstant/FeeStructure";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import CustomAlertPopup from "../../../Shared/CustomAlertPopup/CustomAlertPopup";
import {
  useGetPaymentByScholarNumberQuery,
  useGetStudentByScholarNumberQuery,
  useSubmitPaymentMutation,
} from "../../../Redux/ReduxToolkit";
import axios from "axios";
import { API_CONSTANT } from "../../../ApiConstant/ApiConstant";
import MLMEC_AllClassFeeStructureJson from "../../../StringConstant/MLMECFeeStructure.json";

function FeeSubmission({
  setShowFeePopup,
  bottomPopup,
  setshowFeeSubmitPopup,
}) {
  const navigate = useNavigate();
  const [studentId, setStudentId] = useState(null);
  const [showStudentDetailBox, setShowStudentDetailPopup] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedFees, setSelectedFees] = useState(null);
  const [totalFees, setTotalFees] = useState(0);
  const [gTotalFees, setGTotalFees] = useState(0);
  const [payAmountInputValue, setPayAmountInputValue] = useState(0);
  const [showClearBalancePopup, setShowClearBalancePopup] = useState(false);
  const [currentStudentClass, setCurrentStudentClass] = useState("");
  const [currentStudentAllData, setCurrentStudentAllData] = useState();
  const [studentFullYearFeeStructure, setStudentFullYearFeeStructure] =
    useState([]);

  // const { data } = useGetPaymentByScholarNumberQuery("SCH123456");
  const [submitPayment] = useSubmitPaymentMutation();
  // const { data: studentDetails } = useGetStudentByScholarNumberQuery(
  //   studentId,
  //   {
  //     skip: !studentId || studentId.length < 4,
  //   }
  // );

  // console.log(studentDetails?.student?.scholarNumber);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    const feesObject = studentFullYearFeeStructure?.find(
      (item) => item.month === selectedMonth
    )?.fees;
    setSelectedMonth(selectedMonth);
    setSelectedFees(feesObject);
    // console.log("feesObject", feesObject);
  };

  useEffect(() => {
    if (selectedFees) {
      const total =
        parseInt(selectedFees.academic.tuitionFee || 0) +
        parseInt(selectedFees.academic.termFee || 0) +
        parseInt(selectedFees.academic.registrationFee || 0) +
        parseInt(selectedFees.academic.annualCharges || 0) +
        parseInt(selectedFees.academic.admissitionCharges || 0) +
        parseInt(selectedFees.extra_curricular?.computer_lab || 0) +
        parseInt(selectedFees.conveyance?.van || 0);
      setTotalFees(total);

      const gTotal = total + parseInt(selectedFees?.dueAmount);

      setGTotalFees(gTotal);
    }
  }, [selectedFees]);

  const handleStudentSearch = async () => {
    if (studentId === null) {
      enqueueSnackbar("Please Enter Student ID !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      return;
    }
    setShowStudentDetailPopup(true);

    axios
      .get(
        `${API_CONSTANT?.BASE_URL_PROD}${API_CONSTANT?.GET_STUDENT_BY_SCHOLAR_NUMBER}/${studentId}`
      )
      .then((response) => {
        // console.log(response.data?.student);
        setCurrentStudentClass(response.data?.student?.class);
        setCurrentStudentAllData(response.data?.student);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
      });
  };

  const handlePayFees = async () => {
    navigate("/student-fee", {
      state: {
        receiptData: selectedFees,
        gTotalFees: gTotalFees,
        totalFees: totalFees,
        month: selectedMonth,
        paidBalence: parseInt(payAmountInputValue),
        dueBalence: parseInt(gTotalFees) - parseInt(payAmountInputValue),
      },
    });
    // const body = {
    //   scholarNumber: studentId,
    //   month: 2,
    //   amountPaid: parseInt(payAmountInputValue),
    //   totalFeeForCurrentMonth: totalFees,
    //   academicYear: "2024-2025",
    // };
    // // console.log(body);
    // try {
    //   const response = await submitPayment({
    //     body: body,
    //   });

    //   console.log(response);

    //   if (response.data) {
    //     console.log("response ", response);
    //     navigate("/student-fee", {
    //       state: {
    //         receiptData: selectedFees,
    //         gTotalFees: gTotalFees,
    //         totalFees: totalFees,
    //         month: selectedMonth,
    //         paidBalence: parseInt(payAmountInputValue),
    //         dueBalence: parseInt(gTotalFees) - parseInt(payAmountInputValue),
    //       },
    //     });
    //   } else {
    //     console.log("Oops! payment not submited ! ");
    //   }
    // } catch (error) {
    //   console.error("Mutation failed:", error?.message);
    // }
  };

  useEffect(() => {
    if (
      currentStudentClass === "PG" ||
      currentStudentClass === "LKG" ||
      currentStudentClass === "UKG"
    ) {
      setStudentFullYearFeeStructure(
        MLMEC_AllClassFeeStructureJson?.MLMEC_AllClassFeeStructureJson
          ?.PG_TO_UKG
      );
    }
    if (
      currentStudentClass === "1st" ||
      currentStudentClass === "2nd" ||
      currentStudentClass === "3rd" ||
      currentStudentClass === "4th" ||
      currentStudentClass === "5th"
    ) {
      setStudentFullYearFeeStructure(
        MLMEC_AllClassFeeStructureJson?.MLMEC_AllClassFeeStructureJson
          ?.ONE_TO_FIVE
      );
    }
    if (
      currentStudentClass === "6th" ||
      currentStudentClass === "7th" ||
      currentStudentClass === "8th"
    ) {
      setStudentFullYearFeeStructure(
        MLMEC_AllClassFeeStructureJson?.MLMEC_AllClassFeeStructureJson
          ?.SIX_TO_EIGHT
      );
    }
    if (currentStudentClass === "9th" || currentStudentClass === "10th") {
      setStudentFullYearFeeStructure(
        MLMEC_AllClassFeeStructureJson?.MLMEC_AllClassFeeStructureJson
          ?.NINE_TO_TEN
      );
    }
    if (currentStudentClass === "11th" || currentStudentClass === "12th") {
      setStudentFullYearFeeStructure(
        MLMEC_AllClassFeeStructureJson?.MLMEC_AllClassFeeStructureJson
          ?.ELEVEN_TO_TWELVE
      );
    }
  }, [currentStudentClass]);

  // console.log("year", studentFullYearFeeStructure);

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0 z-50 ">
      <div
        className={`bg-white  min-w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${bottomPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <p className="text-xl font-semibold text-[#2B60EC]">Fee Submission</p>
          <IoCloseSharp
            className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
            onClick={() => {
              setshowFeeSubmitPopup(false);
            }}
          />
        </div>
        <div className="p-5 h-[92vh] overflow-scroll">
          <div className="flex justify-between gap-3 items-end mb-5">
            <InputFieldWithBorder
              value={studentId || ""}
              label="Enter Student Id"
              onChange={(e) => {
                setStudentId(e.target.value);
                // setShowStudentDetailPopup(false);
              }}
            />
            <IoMdSearch
              className="border text-4xl p-1 rounded-lg mb-1 cursor-pointer text-[#2B60EC] border-[#2B60EC] hover:bg-[#2B60EC] hover:text-white"
              onClick={() => handleStudentSearch()}
            />
          </div>
          {showStudentDetailBox && (
            <div className="">
              <div className="px-5 pb-5 mb-5 text-sm border-b border-dashed border-black">
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">
                    Scholar Id Number
                  </p>
                  <p className="text-gray-500">
                    {currentStudentAllData?.scholarNumber}
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">
                    Scholar Full Name
                  </p>
                  <p className="text-gray-500 capitalize">
                    {currentStudentAllData?.personalDetails?.firstName}{" "}
                    {currentStudentAllData?.personalDetails?.lastName}
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">Father Name</p>
                  <p className="text-gray-500 capitalize">
                    {currentStudentAllData?.personalDetails?.fatherName}
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">Mobile No</p>
                  <p className="text-gray-500">
                    +91
                    {currentStudentAllData?.personalDetails?.phone}
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">Class</p>
                  <p className="text-gray-500">
                    {currentStudentAllData?.class}
                  </p>
                </div>
                {/* <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">Admission Year</p>
                  <p className="text-gray-500">2020</p>
                </div> */}
              </div>
              <div className="">
                <select
                  className="w-full p-2 border border-black rounded-md text-sm"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="" disabled>
                    SELECT MONTH
                  </option>
                  {studentFullYearFeeStructure?.map((monthData, index) => (
                    <option value={monthData.month} key={index}>
                      {monthData?.month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {selectedFees?.academic && (
            <div className="">
              <div className="px-5 pt-3 my-5 text-sm ">
                <div className="flex justify-between mb-3">
                  <p className="font-semibold text-[#313639]">Tuition Fee</p>
                  <p className="text-gray-500">
                    {selectedFees?.academic?.tuitionFee}
                  </p>
                </div>
                {selectedFees?.academic?.admissitionCharges && (
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">
                      Admissition Charges
                    </p>
                    <p className="text-gray-500">
                      {selectedFees?.academic?.admissitionCharges}
                    </p>
                  </div>
                )}
                {selectedFees?.academic?.annualCharges && (
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">
                      Annual Charges
                    </p>
                    <p className="text-gray-500">
                      {selectedFees?.academic?.annualCharges}
                    </p>
                  </div>
                )}
                {selectedFees?.academic?.registrationFee && (
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">
                      Registration Fee
                    </p>
                    <p className="text-gray-500">
                      {selectedFees?.academic?.registrationFee}
                    </p>
                  </div>
                )}
                {selectedFees?.academic?.termFee && (
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Term Fee</p>
                    <p className="text-gray-500">
                      {selectedFees?.academic?.termFee}
                    </p>
                  </div>
                )}
                {selectedFees?.isConveyance &&
                  selectedFees?.conveyance?.van && (
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-[#313639]">
                        Conveyance Van
                      </p>
                      <p className="text-gray-500">
                        {selectedFees?.conveyance?.van}
                      </p>
                    </div>
                  )}
                {selectedFees?.extra_curricular?.computer_lab && (
                  <div className="flex justify-between mb-3">
                    <p className="font-semibold text-[#313639]">Computer Lab</p>
                    <p className="text-gray-500">
                      {selectedFees?.extra_curricular?.computer_lab}
                    </p>
                  </div>
                )}

                <div className="flex justify-between mb-3 py-2 font-semibold text-[#313639] text-base border-y border-dashed border-black">
                  <p className=" ">Total</p>
                  <p className="">{totalFees}</p>
                </div>

                {selectedFees?.dueAmount && (
                  <div className="flex justify-between mb-3">
                    <div className="flex items-center gap-2">
                      <p className="font-semibold text-[#313639]">
                        Previous Amount
                      </p>
                      <p
                        className=" flex items-center uppercase !text-[10px] font-light p-1 px-3 rounded bg-red-500 text-white cursor-pointer"
                        onClick={() => setShowClearBalancePopup(true)}
                      >
                        Clear balance
                      </p>
                    </div>
                    <p className="text-gray-500">{selectedFees?.dueAmount}</p>
                  </div>
                )}
                <div className="flex justify-between mb-3 py-2 font-semibold text-[#313639] text-base border-y border-dashed border-black">
                  <p className=" ">G. Total</p>
                  <p className="">{gTotalFees}</p>
                </div>
              </div>

              <div className="">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    label={`Enter Pay Amount`}
                    onChange={(e) => setPayAmountInputValue(e.target.value)}
                  />
                </div>
              </div>

              <CustomButton
                label="Pay Fees"
                className="!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]"
                onClick={() => handlePayFees()}
              />
            </div>
          )}
        </div>
      </div>
      {showClearBalancePopup && (
        <CustomAlertPopup
          title={`warning`}
          discreption={`This action cannot be undone. This will clear any past due & past history. make sure that you confirm before you initiate this action`}
          cancelBtnLabel={`Cancel`}
          cancelBtnOnClick={setShowClearBalancePopup}
          submitBtnLabel={`Ok`}
          // submitBtnOnClick={handleCallApi}
        />
      )}
    </div>
  );
}

export default FeeSubmission;
