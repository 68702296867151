import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Calendar as PrimeCalendar } from "primereact/calendar";
import CustomButton from "../../../Shared/CustomButton/CustomButton";

function AdvancedFeeDeposit({ bottomPopup, setShowAdvanceFeePopup }) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());


  return (
    <div>
      <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0 z-50 ">
        {/* {<CircleLoader />} */}
        <div
          className={`bg-white  w-[40rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${bottomPopup} `}
        >
          <div className="flex justify-between items-center p-5 border-b pb-5">
            <p className="text-xl font-semibold text-[#2B60EC]">
              Advanced Fee Deposit
            </p>
            <IoCloseSharp
              className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
              onClick={() => {
                setShowAdvanceFeePopup(false);
              }}
            />
          </div>
          <div className="h-[92vh] overflow-y-scroll no-scrollbar p-5   ">
            <div className="flex flex-col justify-start  ">
              <p className="font-bold text-[#313639] text-xl mb-5">
                SELECT DATE
              </p>

              <div className="flex flex-row gap-8 items-end">
                <div className="me-8">
                  <p className="mb-1">From</p>
                  <PrimeCalendar
                    inputClassName="border px-4 w-[12rem] h-[3rem]  rounded-lg"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    className="w-[10rem] "
                  />
                </div>
                <div className="ms-8">
                  <p className="mb-1">To</p>
                  <PrimeCalendar
                    inputClassName="border px-4 w-[12rem] h-[3rem]  rounded-lg"
                    value={toDate}
                    onChange={(e) => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    className="w-[10rem] "
                  />
                </div>
                <CustomButton
                  label={`Find Fee`}
                  className={`mx-5 bg-green-600 text-white border-none w-[12rem] h-[3rem] `}
                  //   onClick={() => handleSearch()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedFeeDeposit;
