import React from "react";
import "./PrincipleBox.css";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import PrincipleBoxCarousel from "./PrincipleBoxCarousel";

function PrincipleBox() {
  return (
    <div className="bg-image ">
      <div className="bg-[#FFE577]/70  flex flex-col lg:flex-row  items-center  pt-10 lg:pt-0">
        <div className="  w-[95%] lg:w-[70%] tablet:w-[80%] flex flex-col justify-center items-center ">
          {/* <p className="text-2xl font-extrabold lg:-translate-y-28 ">
            FROM THE PRINCIPAL'S DESK
          </p> */}
          <div className="lg:mx-20 mt-32 lg:mt-0">
            <div className="bg-[#293B61] relative my-10 p-10 py-20 text-white text-center flex flex-col gap-5   ">
              <img
                src={require("../../assets/principal.jpeg")}
                alt=""
                className="absolute top-0 left-0 right-0 mx-auto -translate-y-28 w-[10rem] rounded-2xl  "
              />

              <img
                src={require("../../assets/icon_quote_open.png")}
                alt=""
                className="absolute top-0 left-5 -translate-y-8"
              />
              <img
                src={require("../../assets/icon_quote_close.png")}
                alt=""
                className="absolute bottom-0 right-5 translate-y-8"
              />
              <p className=" italic">
                All the children reading this remember - You are loved and loved
                a lot by your parents, by your teachers, your siblings (even if
                you fight a lot) your true friends and the Almighty. You are a
                masterpiece, with some flaws which every human has and even Gods
                who incarnated as humans had.
              </p>
              <p className="italic">
                Be who you are. There is no one on this planet like you. No one
                has exactly the same talents, likes or dislikes or personality
                like you. Respect their individuality without losing yours.
                Never be afraid to be yourself, and keep improving towards being
                a better human being.
              </p>
              <CustomButton
                label={`READ MORE`}
                className={`hover:bg-[#edd156] text-white bg-[#ED0C0A] duration-300 border-none !p-5 w-[10rem] absolute mx-auto left-0 right-0 bottom-0  translate-y-8 font-extrabold hover:text-black`}
              />
            </div>
          </div>
        </div>
        {/* <img
          src={require("../../assets/center-3.avif")}
          alt=""
          className="h-full  lg:w-[50%] w-full mt-10 lg:mt-0"
        /> */}
        <div className="w-[60%]">
          <PrincipleBoxCarousel />
        </div>
      </div>
    </div>
  );
}

export default PrincipleBox;
