import React, { useEffect, useState } from "react";
import { useGetTeacherByStaffIdQuery } from "../../Redux/ReduxToolkit";
import { useLocation } from "react-router-dom";
import { Utils } from "../../Utils/Utils";
import CircleLoader from "../../AllLoaders/CircleLoader";

function StaffProfile() {
  const location = useLocation();
  const staffId = localStorage.getItem("staffId");

  const {
    data: getTeacherByStaffId,
    isLoading: getTeacherByStaffIdIsLoading,
    refetch: getTeacherByStaffIdRefetch,
  } = useGetTeacherByStaffIdQuery(staffId || "");

  console.log(getTeacherByStaffId?.data?.other?.classTeacherOf);
  // console.log("fgrfd",staffId);

  return (
    <div className="lg:m-5 lg:p-10 h-full w-full overflow-y-scroll no-scrollbar pt-16 px-4">
      {getTeacherByStaffIdIsLoading && <CircleLoader />}
      <p className="pb-2 font-bold text-xl border-b-2 border-gray-400 lg:mb-5">
        PROFILE INFORMATION
      </p>
      <div className="p-10 text-sm w-[30rem] border rounded-md hidden lg:block bg-white shadow-no-border-shadow-2">
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]">Teacher Name</p>
          <p className="text-gray-500 capitalize">
            {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
          </p>
        </div>
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]">Class Teacher Of</p>
          <p className="text-gray-500">
            {`${getTeacherByStaffId?.data?.other?.classTeacherOf} (${getTeacherByStaffId?.data?.other?.classTeacherSection})`}
          </p>
        </div>{" "}
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]">Email Id</p>
          <p className="text-gray-500">
            {getTeacherByStaffId?.data?.personalDetails?.email}
          </p>
        </div>
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]">Aadhar No</p>
          <p className="text-gray-500">
            {getTeacherByStaffId?.data?.personalDetails?.adharNumber}
          </p>
        </div>
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]">Mobile No</p>
          <p className="text-gray-500">
            +91 {getTeacherByStaffId?.data?.personalDetails?.phone}
          </p>
        </div>
        <div className="flex justify-between mb-3">
          <p className="font-semibold text-[#313639]"> Date Of Birth</p>
          <p className="text-gray-500">
            {Utils?.formatDate(getTeacherByStaffId?.data?.personalDetails?.dob)}
          </p>
        </div>
      </div>
      <div className="flex flex-col my-5 gap-5 lg:hidden">
        <div className="border-b pb-1 border-gray-500">
          <p className="text-gray-500 text-sm mb-2">Name</p>
          <p className="text-base font-semibold">
            {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
          </p>
        </div>
        <div className="border-b pb-1 border-gray-500">
          <p className="text-gray-500 text-sm mb-2">Class Teacter Of </p>
          <p className="text-base font-semibold">
            {`${getTeacherByStaffId?.data?.other?.classTeacherOf} (${getTeacherByStaffId?.data?.other?.classTeacherSection})`}
          </p>
        </div>{" "}
        <div className="border-b pb-1 border-gray-500">
          <p className="text-gray-500 text-sm mb-2">Your Email </p>
          <p className="text-base font-semibold">
            {getTeacherByStaffId?.data?.personalDetails?.email}
          </p>
        </div>{" "}
        <div className="border-b pb-1 border-gray-500">
          <p className="text-gray-500 text-sm mb-2">Your Mobile No. </p>
          <p className="text-base font-semibold">
            {getTeacherByStaffId?.data?.personalDetails?.phone}
          </p>
        </div>
        <div className="border-b pb-1 border-gray-500">
          <p className="text-gray-500 text-sm mb-2">Aadhar No. </p>
          <p className="text-base font-semibold">
            {getTeacherByStaffId?.data?.personalDetails?.adharNumber}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StaffProfile;
