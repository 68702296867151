import Header from "../Header/Header";
import MobileHeader from "../Header/MobileHeader";
import HeroContainer from "../HeroContainer/HeroContainer";
import WellcomeBox from "../WellcomeBox/WellcomeBox";
import Cards from "../Cards/Cards";
import PrincipleBox from "../PrincipleBox/PrincipleBox";
import SchoolEvents from "../SchoolEvents/SchoolEvents";
import Enquery from "../Enquery/Enquery";
import Footer from "../Footer/Footer";
import AdmissionFormPopup from "../AdmissionFormPopup/AdmissionFormPopup";

function HomePage() {
  return (
    <div>
      {/* <AdmissionFormPopup /> */}
      <div className="hidden lg:block">
        <Header />
      </div>
      <div className="block lg:hidden">
        <MobileHeader />
      </div>
      <div className="">
        <HeroContainer />
      </div>
      <div className="">
        <WellcomeBox />
      </div>
      <div className="">
        <Cards />
      </div>
      <div className="">
        <PrincipleBox />
      </div>
      <div className="">
        <SchoolEvents />
      </div>
      <div className="">
        <Enquery />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
