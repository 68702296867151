import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../StringConstant/MotiLalAllConstant";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { enqueueSnackbar } from "notistack";
import { useCreateTimeTableMutation } from "../../Redux/ReduxToolkit";

function CreateTimeTableForm({ setShowCreateTimeTablePopup, rightPopup }) {
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [season, setSeason] = useState("");
  const [nameOfTimeTable, setNameOfTimeTable] = useState("");
  const [day, setDay] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [teachertName, setTeacherName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [createTimeTable] = useCreateTimeTableMutation();

  const dayArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleSave = async () => {
    if (
      nameOfTimeTable === null ||
      nameOfTimeTable === undefined ||
      nameOfTimeTable === ""
    ) {
      enqueueSnackbar("Name of Time Table is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (season === null || season === undefined || season === "") {
      enqueueSnackbar("Please Choose Season !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (day === null || day === undefined || day === "") {
      enqueueSnackbar("Please Choose Day !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      studentClass === null ||
      studentClass === undefined ||
      studentClass === ""
    ) {
      enqueueSnackbar("Please Choose Class !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      studentSection === null ||
      studentSection === undefined ||
      studentSection === ""
    ) {
      enqueueSnackbar("Please Choose Section !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      teachertName === null ||
      teachertName === undefined ||
      teachertName === ""
    ) {
      enqueueSnackbar("Teacher Name  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      subjectName === null ||
      subjectName === undefined ||
      subjectName === ""
    ) {
      enqueueSnackbar("Subject Name  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    const body = {
      academicYear: "2024-2025",
      nameOfTimeTable: nameOfTimeTable,
      season: season,
      class: studentClass,
      section: studentSection,
      timeTable: [
        {
          day: day,
          periods: [
            {
              subject: subjectName,
              teacher: teachertName,
              startTime: startTime,
              endTime: endTime,
            },
          ],
        },
      ],
    };

    // console.log(body)

    try {
      const response = await createTimeTable({
        body: body,
      });

      if (response.data) {
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setShowCreateTimeTablePopup(false);
      } else {
        console.log("Oops! time table not created ! ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };
  return (
    <div>
      <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0 z-50 ">
        <div
          className={`bg-white  lg:w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative  ${rightPopup} `}
        >
          <div className="flex justify-between items-center p-5 border-b pb-5">
            <p className="text-xl font-semibold text-[#2B60EC]">
              Time Table Management Form
            </p>
            <IoCloseSharp
              className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
              onClick={() => setShowCreateTimeTablePopup(false)}
            />
          </div>
          <div className="p-10  h-[92vh] overflow-y-scroll no-scrollbar ">
            <div className=" w-full ">
              <div className="flex flex-col  w-full gap-4 ">
                <div className=" w-full">
                  <InputFieldWithBorder
                    label={`Name of Time Table`}
                    value={nameOfTimeTable}
                    onChange={(e) => setNameOfTimeTable(e.target.value)}
                  />
                </div>
                <div className=" w-full">
                  <select
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
                  >
                    <option value="" disabled>
                      Choose Season
                    </option>{" "}
                    <option value="SUMMER">SUMMER</option>{" "}
                    <option value="WINTER">WINTER</option>{" "}
                    <option value="FALL">FALL</option>
                  </select>
                </div>
                <div className=" w-full">
                  <select
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
                  >
                    <option value="" disabled>
                      Choose Day
                    </option>
                    {dayArray?.map((dayData, index) => (
                      <option value={dayData} key={index}>
                        {dayData}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" w-full">
                  <select
                    value={studentClass}
                    onChange={(e) => setStudentClass(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
                  >
                    <option value="" disabled>
                      Choose Class
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" w-full">
                  <select
                    value={studentSection}
                    onChange={(e) => setStudentSection(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
                  >
                    <option value="" disabled>
                      Choose Section
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
                      (studentSectionData, index) => (
                        <option value={studentSectionData} key={index}>
                          {studentSectionData}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className=" w-full">
                  <InputFieldWithBorder
                    label={`Subject Name`}
                    value={subjectName}
                    onChange={(e) => setSubjectName(e.target.value)}
                  />
                </div>
                <div className=" w-full">
                  <InputFieldWithBorder
                    label={`Teacher Name`}
                    value={teachertName}
                    onChange={(e) => setTeacherName(e.target.value)}
                  />
                </div>

                <div className=" w-full">
                  <InputFieldWithBorder
                    label={`Class Start Time`}
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
                <div className=" w-full">
                  <InputFieldWithBorder
                    label={`Class End Time`}
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <CustomButton
              label={`Save`}
              className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] mt-5`}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTimeTableForm;
