import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Utils } from "../../Utils/Utils";

function ClassAttendanceReportTable({ getClassAttendanceApiResponce }) {
  const [showDropdownBox, setShowDropdownBox] = useState(-1);

  const handleOpenDetails = (outerIndex) => {
    if (showDropdownBox === outerIndex) {
      setShowDropdownBox(-1);
      console.log("click huaa", showDropdownBox, outerIndex);
    } else {
      setShowDropdownBox(outerIndex);
    }
  };

  return (
    <div className="bg-white shadow-no-border-shadow-2 rounded-xl p-5 mt-5 max-h-full overflow-auto ">
      {/* <div className="">
        <div className=""></div>
      </div>
      <div className="">
        {getClassAttendanceApiResponce?.map((data, index) => (
          <div className="" key={index}>
            {index}
          </div>
        ))}
      </div> */}

      {getClassAttendanceApiResponce &&
      getClassAttendanceApiResponce?.length > 0 ? (
        <table className="w-full text-sm bg-white rounded">
          <thead className="sticky top-0 bg-white ">
            <tr className="shadow text-gray-700 ">
              <div className="">
                <th className=" p-3 text-center">S. No.</th>
                <th className=" p-3 text-center">Date & Day</th>
              </div>
            </tr>
          </thead>
          <tbody className="">
            {getClassAttendanceApiResponce?.map((data, outerIndex) => (
              <tr
                key={outerIndex}
                className="shadow text-gray-500 cursor-pointer  "
                //   onClick={() => handleTableClick(index, studentData)}
                // onClick={() => setShowStudentFullInfoBox(true)}
              >
                <div
                  className={`flex items-center justify-between  ${
                    showDropdownBox === outerIndex ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleOpenDetails(outerIndex)}
                >
                  <div className="flex items-center gap-5 ">
                    <td className=" p-3 text-center ">{outerIndex + 1}</td>
                    <td className=" p-3 text-center capitalize flex items-center gap-5">
                      <p className="">{data?._id}</p> -
                      <p className="">{Utils?.getDayName(data?._id)} </p>
                    </td>
                  </div>
                  <td className=" p-3  text-xl  ">
                    <IoIosArrowDown
                      className={` duration-300 ${
                        showDropdownBox === outerIndex ? "rotate-180" : ""
                      }`}
                    />
                  </td>
                </div>
                {/* <div
                className={`px-5  max-h-[400px] overflow-auto flex flex-col gap-5 `}
              >
                <div className={` flex items-center gap-20 font-medium `}>
                  <p className="pb-2">S. No.</p>
                  <p className="pb-2">Scholar No.</p>
                  <p className={`pb-2  `}>Status</p>
                </div>
              </div> */}
                {showDropdownBox === outerIndex && (
                  <div
                    className={`p-5  max-h-[370px] overflow-auto flex flex-col gap-5 `}
                  >
                    {data?.attendanceEntries?.map((report, innerIndex) => (
                      <div
                        key={innerIndex}
                        className={` flex items-center gap-20 font-medium ${
                          innerIndex - 1 !== innerIndex ? "border-b" : ""
                        }`}
                      >
                        <p className="pb-2">{`1.${innerIndex + 1}`}</p>
                        <p className="pb-2">{report?.scholarNumber}</p>
                        <p className="pb-2 w-[15rem]">{report?.studentName}</p>
                        <p
                          className={`pb-2  ${
                            report?.status === "P"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {report?.status}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="h-[30rem] flex items-center justify-center">
          <div className="flex flex-col items-center justify-center ">
            <img
              src={require("../../assets/noData_image.png")}
              alt=""
              className="w-[15em]"
            />
            <p className="text-center text-2xl">
              No records found in the given range !
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClassAttendanceReportTable;
