import React, { useState } from "react";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../../StringConstant/MotiLalAllConstant";
import { VscAccount } from "react-icons/vsc";
import { useGetFeeDetailsByClassYearRangeQuery } from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { Utils } from "../../../Utils/Utils";

function CollectionTable() {
  const [studentClass, setStudentClass] = useState("3rd");
  const [studentSection, setStudentSection] = useState("B");
  const [selectFeesDuration, setSelectFeesDuration] = useState("today");
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(5)?.[0]
  );
  const [startDate, setStartDate] = useState(
    Utils?.formateDateForApiRequest(new Date())
  );
  const [endDate, setEndDate] = useState(
    Utils?.formateDateForApiRequestNextDayDate(new Date())
  );

  const {
    data: getFeeDetailsByClassYearRange,
    isLoading: getFeeDetailsByClassYearRangeIsLoading,
  } = useGetFeeDetailsByClassYearRangeQuery(
    {
      class: studentClass,
      section: studentSection,
      academicYear: academicYear,
      startDate: startDate,
      endDate: endDate,
    },
    {
      skip:
        !studentSection ||
        !studentClass ||
        !endDate ||
        !startDate ||
        !academicYear,
    }
  );

  return (
    <div className="bg-white p-5 rounded-lg  h-[81vh] overflow-y-scroll no-scrollbar">
      {getFeeDetailsByClassYearRangeIsLoading && <CircleLoader />}
      <div className="flex justify-between items-center ">
        <p className="text-xl font-semibold">Collection</p>
        <div className=" flex items-center gap-5">
          <select
            value={academicYear}
            onChange={(e) => setAcademicYear(e.target.value)}
            className="w-[10rem] p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
          >
            <option value="" disabled>
              Choose class
            </option>
            {Utils?.generateAcademicYears(5)?.map((studentClassData, index) => (
              <option value={studentClassData} key={index}>
                {studentClassData}
              </option>
            ))}
          </select>{" "}
          <select
            value={studentClass}
            onChange={(e) => setStudentClass(e.target.value)}
            className="w-[10rem] p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
          >
            <option value="" disabled>
              Choose class
            </option>
            {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
              (studentClassData, index) => (
                <option value={studentClassData} key={index}>
                  {studentClassData}
                </option>
              )
            )}
          </select>{" "}
          <select
            value={studentSection}
            onChange={(e) => setStudentSection(e.target.value)}
            className="w-[10rem] p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
          >
            <option value="" disabled>
              Choose section
            </option>
            {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
              (studentClassData, index) => (
                <option value={studentClassData} key={index}>
                  {studentClassData}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <div className="flex items-end  my-5 ">
        <p
          className={`cursor-pointer border-b-2 px-3  pb-2 text-xs font-semibold ${
            selectFeesDuration === "today"
              ? "border-[#2B60EC] text-[#2B60EC]"
              : "border-[#acacac] text-[#acacac]"
          }`}
          onClick={() => {
            setSelectFeesDuration("today");
            setStartDate(
              Utils?.formatDateBefore(Utils?.getTodayDate(), "today")
            );
          }}
        >
          Today
        </p>
        <p
          className={`cursor-pointer border-b-2 px-3  pb-2 text-xs font-semibold ${
            selectFeesDuration === "week"
              ? "border-[#2B60EC] text-[#2B60EC]"
              : "border-[#acacac] text-[#acacac]"
          }`}
          onClick={() => {
            setSelectFeesDuration("week");
            setStartDate(
              Utils?.formatDateBefore(Utils?.getTodayDate(), "week")
            );
          }}
        >
          Week
        </p>
        <p
          className={`cursor-pointer border-b-2 px-3  pb-2 text-xs font-semibold ${
            selectFeesDuration === "month"
              ? "border-[#2B60EC] text-[#2B60EC]"
              : "border-[#acacac] text-[#acacac]"
          }`}
          onClick={() => {
            setSelectFeesDuration("month");
            setStartDate(
              Utils?.formatDateBefore(Utils?.getTodayDate(), "month")
            );
          }}
        >
          Month
        </p>
        <p
          className={`cursor-pointer border-b-2 px-3  pb-2 text-xs font-semibold ${
            selectFeesDuration === "year"
              ? "border-[#2B60EC] text-[#2B60EC]"
              : "border-[#acacac] text-[#acacac]"
          }`}
          onClick={() => {
            setSelectFeesDuration("year");
            setStartDate(
              Utils?.formatDateBefore(Utils?.getTodayDate(), "year")
            );
          }}
        >
          Year
        </p>
      </div>

      {getFeeDetailsByClassYearRange?.fee?.length > 0 ? (
        <div className="">
          <table className=" w-full text-sm bg-white rounded">
            <thead>
              <tr className="shadow text-gray-700">
                {/* <th className=" p-3 text-start">Photo</th> */}

                <th className=" p-3 text-center">Scholar Name</th>
                <th className=" p-3 text-center">Father Name</th>
                <th className=" p-3 text-center">Whatsapp No</th>
                <th className=" p-3 text-center">Payment Date</th>
                <th className=" p-3 text-center">Total Amount</th>
                <th className=" p-3 text-center">Paid Amount</th>
                <th className=" p-3 text-center">Due Amount</th>
              </tr>
            </thead>
            <tbody>
              {getFeeDetailsByClassYearRange?.fee.map((feeData, index) => (
                <tr
                  className="text-gray-400 shadow  cursor-pointer hover:bg-gray-400/10"
                  key={index}
                >
                  {/* <td className=" p-3 text-center  ">
                    <VscAccount className=" text-2xl " />
                  </td> */}
                  <td className=" p-3 text-center ">{`  ${feeData?.uid?.personalDetails?.firstName}
                ${feeData?.uid?.personalDetails?.lastName}`}</td>
                  <td className=" p-3 text-center ">
                    {feeData?.uid?.personalDetails?.fatherName}
                  </td>
                  <td className=" p-3 text-center ">
                    {feeData?.uid?.personalDetails?.whatsAppNumber
                      ? feeData?.uid?.personalDetails?.whatsAppNumber
                      : "NA"}
                  </td>
                  <td className=" p-3 text-center ">
                    {/* {Utils?.formatDate(
                    feeData?.otherDetails?.lastPaymentDate
                  )} */}
                    {feeData?.createdAt
                      ? Utils?.formatDate(feeData?.createdAt)
                      : "-"}
                  </td>
                  <td className=" p-3 text-center ">
                    ₹ {feeData?.totalFeeForCurrentMonth}
                  </td>
                  <td className=" p-3 text-center ">
                    {" "}
                    ₹ {feeData?.amountPaid}
                  </td>
                  <td className=" p-3 text-center text-red-500">
                    ₹ {feeData?.currentDue}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-xl  ">
          <p className=" p-10 rounded-xl border-red-500 text-red-500">
            No Data Found
          </p>
        </div>
      )}
    </div>
  );
}

export default CollectionTable;
