import React from "react";
import { MOTILAL_STRINGS } from "../../../../StringConstant/MotiLalAllConstant";

function HindiTcHeader({ currentStudentAllData }) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between text-xs">
        <div className="flex flex-col items-start">
          <div className="flex items-center gap-4  ">
            <p className="border border-black p-1 rounded-md px-5 ">
              स्कूल कोड : 1154
            </p>
            <p className="">(राजकीय मान्यता प्राप्त)</p>
          </div>
          <div className="flex items-center gap-4  mt-4 ">
            <p className="font-bold">
              Udice No. : {currentStudentAllData?.udiceNumber}
            </p>
          </div>
        </div>

        <p className="">Phone : 0512-2510131</p>
      </div>
      <div className="flex  text-sm  gap-3 items-center justify-center relative">
        <img
          src={require("../../../../assets/school_logo.png")}
          className="w-[3rem]"
          alt=""
        />
        <div className=" ">
          <p className="font-bold text-xl ">
            {MOTILAL_STRINGS?.MLMIC_NAME_HINDI}
          </p>
          <p className="text-center">{MOTILAL_STRINGS?.SCHOOL_ADDRESS_HINDI}</p>
        </div>
        <img
          src={require("../../../../assets/barcode.png")}
          className="w-[6rem] absolute right-0"
          alt=""
        />
      </div>
      <div className="flex justify-between text-xs border border-black p-1 px-3 mt-2 rounded">
        <div className="flex items-center gap-4  ">
          <p className=" ">प्रवेश पत्रावली संख्या </p>{" "}
          <input
            type="text"
            className="border-b border-black border-dotted bg-transparent w-[10rem]  hover:border-[#2B60EC] focus:outline-none focus:border-[#2B60EC]"
          />
        </div>
        <div className="flex items-center gap-4  ">
          <p className=" ">छत्र पंजिका संख्या </p>{" "}
          <input
            disabled
            type="text"
            className="bg-transparent w-[10rem]  "
            value={currentStudentAllData?.scholarNumber}
          />
        </div>
      </div>
    </div>
  );
}

export default HindiTcHeader;
