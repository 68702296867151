import "./App.css";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { getRouter } from "./Utils/RouteUtils";
import AuthProvider from "./Provider/AuthProvider";

function App() {
  const Router = getRouter();
  return (
    <Provider store={store}>
      <AuthProvider>
        <SnackbarProvider>
          <BrowserRouter>
            {/* <ErpRouter /> */}
            {/* <WebRouter /> */}
            <Router />
          </BrowserRouter>
        </SnackbarProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
