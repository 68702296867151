import React, { useContext, useEffect, useRef, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import SignUpForm from "./SignUpForm/SignUpForm";
import StudentTable from "./StudentTable/StudentTable";
import { useNavigate } from "react-router-dom";
import FeeSubmission from "./FeeSubmission/FeeSubmission";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../StringConstant/MotiLalAllConstant";
import { Utils } from "../../Utils/Utils";
import CreateFeeStructure from "../CreateFeeStructure/CreateFeeStructure";
import { MdOutlinePersonSearch } from "react-icons/md";
import axios from "axios";
import { API_CONSTANT } from "../../ApiConstant/ApiConstant";
import StudentFullInfoInTable from "./StudentTable/StudentFullInfoInTable";
import no_data_avalible from "../../assets/no_data_avalible.json";
import Lottie from "lottie-react";
import { AuthContext } from "../../Provider/AuthProvider";

function StudentManagement() {
  const navigate = useNavigate();
  let popupRef = useRef();
  const { userData } = useContext(AuthContext);

  const [showCreateFeeStructure, setCreateFeeStructure] = useState("");
  const [showFeePopup, setShowFeePopup] = useState(false);
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(1)?.[0]
  );
  const [showStudentFullInfoBox, setShowStudentFullInfoBox] = useState(false);
  const [studentInfoByScholarNumbar, setStudentInfoByScholarNumbar] =
    useState();
  const [fuzzySearchResult, setFuzzySearchResult] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    let handler = (e) => {
      if (!popupRef?.current?.contains(e?.target)) {
        setFuzzySearchResult([]);
        setShowFeePopup(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  function debounce(func, delay) {
    let timeoutId;

    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const makeFuzzySearch = async (e) => {
    const searchTerm = e.target.value;
    if (!searchTerm || searchTerm.trim().length === 0) {
      setFuzzySearchResult([]);
      return;
    }
    try {
      const { data } = await axios.get(
        `${API_CONSTANT.BASE_URL_PROD}student/search?searchTerm=${searchTerm}&medium=${userData?.medium}`
      );
      setFuzzySearchResult(data?.results);
      console.log("", data?.results);
    } catch (error) {
      console.log("Error");
    }
  };

  const debouncedBetterFunction = debounce(makeFuzzySearch, 500);

  const handleShowStudentDetailPopup = (stData) => {
    console.log(stData?.scholarNumber);
    setStudentInfoByScholarNumbar(stData);
    setShowStudentFullInfoBox(true);
    setFuzzySearchResult([]);
    setSearchInputValue("");
  };

  // console.log(studentInfoByScholarNumbar);


  return (
    <div className="m-5" ref={popupRef}>
      <div className="w-full flex justify-between items-center bg-white px-5 py-2 rounded-lg">
        <div className="flex flex-col w-full ">
          <div className="w-full flex items-center justify-between">
            <p className="">Student Management</p>
            <div className="flex items-center  text-sm gap-3">
              <div className="flex items-center ">
                <div className="flex items-center ">
                  <div className=" relative">
                    <div className=" flex items-center">
                      <input
                        type="text"
                        placeholder="Search student"
                        className="border p-2 rounded-lg hover:border-[#2B60EC] focus:outline-none focus:border-[#2B60EC] w-[25rem]"
                        onChange={(e) => {
                          debouncedBetterFunction(e);
                          setSearchInputValue(e.target.value);
                        }}
                        value={searchInputValue}
                      />
                    </div>
                    {
                      fuzzySearchResult && fuzzySearchResult.length > 0 && (
                        <ul className="shadow-2xl  absolute top-[100%] left-0 right-0 rounded-b-xl  w-full z-40 bg-white  py-3 max-h-[16rem] overflow-y-scroll ">
                          {fuzzySearchResult?.map((stData, index) => (
                            <li
                              key={index}
                              className={`w-full cursor-pointer hover:bg-gray-100 py-1.5 border-y px-4 capitalize text-gray-800/70 line-clamp-1   ${
                                (index === 0 ||
                                  index === fuzzySearchResult?.length - 1) &&
                                "border-none"
                              }`}
                              onClick={() =>
                                handleShowStudentDetailPopup(stData)
                              }
                            >
                              <div className="flex justify-between ">
                                <div className="flex items-center gap-2 ">
                                  <p className="text-[#313639]">{`${
                                    index < 9 ? 0 : ""
                                  }${index + 1}.`}</p>
                                  <p>
                                    {`${stData?.personalDetails?.firstName} ${stData?.personalDetails?.lastName}`}
                                  </p>
                                </div>
                                <p className="font-semibold">
                                  {stData?.scholarNumber}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )
                      // : (
                      //   <div className="shadow-2xl  absolute top-[100%] left-0 right-0 rounded-b-xl  w-full z-40 bg-white  p-3 max-h-[15rem] overflow-y-scroll ">
                      //     <Lottie
                      //       animationData={no_data_avalible}
                      //       loop={true}
                      //       className="h-[200px]"
                      //     />
                      //   </div>
                      // )
                    }
                  </div>
                </div>
              </div>
              <CustomButton
                label={`Add New Student`}
                className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
                onClick={() =>
                  navigate(`/${userData?.medium}/student-registration`)
                }
              />
            </div>
          </div>

          <div className="w-full flex  gap-5 items-center bg-white   rounded-lg mt-2">
            <p className="">Filter By :</p>
            <div className=" min-w-[10rem]">
              <select
                value={academicYear}
                onChange={(e) => setAcademicYear(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C] "
              >
                <option value="" disabled>
                  Choose academic year
                </option>
                {Utils?.generateAcademicYears(5)?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className=" w-[10rem]">
              <select
                value={studentClass}
                onChange={(e) => setStudentClass(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
              >
                <option value="" disabled>
                  Choose class
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                  (studentClassData, index) => (
                    <option value={studentClassData} key={index}>
                      {studentClassData}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className=" w-[10rem]">
              <select
                value={studentSection}
                onChange={(e) => setStudentSection(e.target.value)}
                className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
              >
                <option value="" disabled>
                  Choose section
                </option>
                {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
                  (studentSectionData, index) => (
                    <option value={studentSectionData} key={index}>
                      {studentSectionData}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between max-h-[80vh] overflow-y-scroll no-scrollbar gap-5  pb-5 mt-3">
        <div className=" rounded-lg w-full">
          <StudentTable
            studentClass={studentClass}
            studentSection={studentSection}
            academicYear={academicYear}
          />
        </div>
        {/* <div className=" my-5 rounded-lg w-[29%]">
          <SendNotification />
        </div> */}
      </div>
      {showFeePopup && (
        <FeeSubmission
          setShowFeePopup={setShowFeePopup}
          rightPopup={`animate-rightPopup`}
        />
      )}
      {showCreateFeeStructure && (
        <CreateFeeStructure
          setCreateFeeStructure={setCreateFeeStructure}
          bottomPopup={`animate-bottomPopup`}
        />
      )}
      {showStudentFullInfoBox && (
        <StudentFullInfoInTable
          setShowStudentFullInfoBox={setShowStudentFullInfoBox}
          rightPopup={`animate-rightPopup`}
          currentStudentFullInfoObject={studentInfoByScholarNumbar}
        />
      )}
    </div>
  );
}

export default StudentManagement;
