import React, { useState } from "react";
import "./ChooseMediumPage.css";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function ChooseMediumPage() {
  const navigate = useNavigate();
  const [mediumValue, setMediumValue] = useState("");

  const handleGoToDashboard = () => {
    if (!mediumValue || mediumValue === "") {
      enqueueSnackbar("Please choose medium !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "left", vertical: "top" },
      });
    } else {
      // localStorage.setItem("medium", mediumValue);
      navigate(`/login`);
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center chooseMediumBgImage">
      <div className="w-full h-screen flex justify-start items-center">
        <div className="min-w-[50%] border rounded-xl min-h-[40vh] flex flex-col items-center gap-10 p-5 mx-10">
          <p className="text-2xl font-bold text-white">Choose Medium</p>
          <div className="flex items-center gap-10">
            <div
              className={`w-full md:w-[45%] h-[7rem] md:h-[13rem] bg-[#f0d0c4] relative rounded-lg mt-5 cursor-pointer duration-300 ${
                mediumValue === "HINDI"
                  ? "scale-110 border-4 border-[#e78460]"
                  : " "
              }`}
              onClick={() => setMediumValue("HINDI")}
            >
              <p className="text-3xl w-[20rem] p-10 font-bold text-gray-700">
                Hindi
              </p>
              <img
                src={require("../../assets/Hindi.png")}
                className="absolute bottom-5 right-0 w-[50%]"
                alt=""
              />
            </div>
            <div
              className={`w-full md:w-[45%] h-[7rem] md:h-[13rem]  bg-[#fff6df] relative rounded-lg mt-5 cursor-pointer duration-300 ${
                mediumValue === "ENGLISH"
                  ? "scale-110 border-4 border-[#f3d486]"
                  : " "
              }`}
              onClick={() => setMediumValue("ENGLISH")}
            >
              <p className="text-3xl w-[20rem] p-10 font-bold text-gray-700">
                English
              </p>
              <img
                src={require("../../assets/English.png")}
                className="absolute bottom-5 right-0 w-[50%]"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <CustomButton
              label={`GO TO DASHBOARD`}
              className={`hover:bg-[#91de7b] hover:text-gray-700 text-white  bg-[#14A2B8] duration-300 !mx-0 !p-5 !font-bold border-none`}
              onClick={() => handleGoToDashboard()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseMediumPage;
