function InputFieldWithOutBorder({
  label,
  onChange,
  inputClassName,
  labelClassName,
  value,
  placeholder,
  type
}) {
  return (
    <div className="my-1 w-full">
      <p className={`text-[#797b7c] text-sm mb-2 ${labelClassName}`}>{label}</p>
      <input
        type={type || "text"}
        className={`border-b hover:border-[#2B60EC] focus:outline-none focus:border-[#2B60EC] w-full text-sm p-1.5 ${inputClassName}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default InputFieldWithOutBorder;
