import React, { useState } from "react";
import TextareaWithBorder from "../../Shared/TextareaWithBorder/TextareaWithBorder";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { enqueueSnackbar } from "notistack";
import { useCreateExpenseCategoryMutation } from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";

function CreateExpanceCategoryForm({ getCategoryDataRefetch }) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");

  const [createExpenseCategory, { isLoading: createExpenseCategoryisLoading }] =
    useCreateExpenseCategoryMutation();

  const handleSave = async () => {
    if (
      categoryName === null ||
      categoryName === undefined ||
      categoryName === ""
    ) {
      enqueueSnackbar(" Category name is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      categoryDescription === null ||
      categoryDescription === undefined ||
      categoryDescription === ""
    ) {
      enqueueSnackbar("Category description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    const body = {
      categoryName: categoryName,
      categoryDescription: categoryDescription,
    };

    try {
      const response = await createExpenseCategory({
        body: body,
      });

      if (response) {
        // console.log(response);
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setCategoryDescription("");
        setCategoryName("");
        getCategoryDataRefetch();
      } else {
        console.log(response);
        enqueueSnackbar(response?.error?.message, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  return (
    <div className="p-5 bg-white rounded-lg w-[25rem] capitalize">
      {createExpenseCategoryisLoading && <CircleLoader />}
      <p className="">Create Expense Category</p>
      <div className="mt-5">
        <div className="mb-5">
          <InputFieldWithBorder
            placeholder={`Category Name`}
            className={`mb-4`}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            inputClassName={`capitalize`}
          />
        </div>
        <div className="mb-5">
          <TextareaWithBorder
            rows={4}
            placeholder={`Category Description`}
            value={categoryDescription}
            onChange={(e) => setCategoryDescription(e.target.value)}
          />
        </div>

        <CustomButton
          label={`Save`}
          className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] w-full`}
          onClick={() => handleSave()}
        />
      </div>
    </div>
  );
}

export default CreateExpanceCategoryForm;
