import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

function TransportManagement() {
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "50vw",
    height: "80vh",
  };
  const center = {
    lat: 26.4499, // default latitude
    lng: 80.3319, // default longitude
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDAtZMgeOskFUxG0sbtrIeAwMSNcbQAwqo",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const apiKey = "AIzaSyCX0H704z0uV2suCjrpDp-kBz3YjJjCF9g";
  return (
    <div className="m-5  h-[90vh]">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
}

export default TransportManagement;
