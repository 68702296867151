import React from "react";
import HindiTcHeader from "./HindiTcHeader";
import HindiTcMiddleBox from "./HindiTcMiddleBox";
import HindiTcDscriptionTable from "./HindiTcDscriptionTable";
import HindiTcDecleartion from './HindiTcDecleartion';

function TcHindiTemplate({ componentRef, currentStudentAllData }) {
  return (
    <div className="my-5  p-5 relative z-10 " ref={componentRef}>
      <img
        src={require("../../../../assets/school_logo.png")}
        alt=""
        className="absolute opacity-[0.07] left-0 right-0 top-0 bottom-0 m-auto w-[25rem] -z-10"
      />
      <div className="">
        <HindiTcHeader currentStudentAllData={currentStudentAllData} />
      </div>{" "}
      <div className="">
        <HindiTcMiddleBox currentStudentAllData={currentStudentAllData} />
      </div>
      <div className="">
        <HindiTcDscriptionTable currentStudentAllData={currentStudentAllData} />
      </div>{" "}
      <div className="">
        <HindiTcDecleartion currentStudentAllData={currentStudentAllData} />
      </div>
    </div>
  );
}

export default TcHindiTemplate;
