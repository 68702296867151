import React, { useEffect, useState } from "react";
import TextareaWithBorder from "../../Shared/TextareaWithBorder/TextareaWithBorder";
import InputFieldWithBorder from "../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useAddNewExpenseMutation } from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";
import { enqueueSnackbar } from "notistack";

function CreateExpance({ getCategoryData, getExpenceTableRefetch }) {
  const [expenseName, setExpenseName] = useState("");
  const [expenseNote, setExpenseNote] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");

  // const {
  //   data: getCategoryData,
  //   isLoading: getCategoryDataIsLoading,
  //   refetch: getCategoryDataRefetch,
  // } = useGetExpenseCategoryQuery();

  const [addNewExpense, { isLoading: addNewExpenseIsLoading }] =
    useAddNewExpenseMutation();

  const handleSave = async () => {
    if (
      expenseCategory === null ||
      expenseCategory === undefined ||
      expenseCategory === ""
    ) {
      enqueueSnackbar("expense category  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      expenseName === null ||
      expenseName === undefined ||
      expenseName === ""
    ) {
      enqueueSnackbar("expense name  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      expenseNote === null ||
      expenseNote === undefined ||
      expenseNote === ""
    ) {
      enqueueSnackbar("expense note  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      expenseAmount === null ||
      expenseAmount === undefined ||
      expenseAmount === ""
    ) {
      enqueueSnackbar("expense amount  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    const body = {
      expenseCategory: expenseCategory,
      expenseAmount: expenseAmount,
      expenseNote: expenseName,
      expenseName: expenseNote,
    };

    try {
      const response = await addNewExpense({
        body: body,
      });
      if (response) {
        // console.log(response);
        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setExpenseCategory("");
        setExpenseAmount("");
        setExpenseName("");
        setExpenseNote("");
        getExpenceTableRefetch();
      } else {
        console.log(response?.error?.message);
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  return (
    <div className="p-5 bg-white rounded-lg w-[25rem] capitalize">
      {addNewExpenseIsLoading && <CircleLoader />}

      <p className="">Add New Expense </p>

      <div className=" my-5">
        <select
          value={expenseCategory}
          onChange={(e) => setExpenseCategory(e.target.value)}
          className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#797b7c] "
        >
          <option value="" disabled>
            Choose expense category
          </option>
          {getCategoryData?.map((categoryData, index) => (
            <option value={categoryData?._id} key={index}>
              {categoryData?.categoryName}
            </option>
          ))}
        </select>{" "}
      </div>
      <div className="mt-5">
        <div className="mb-5">
          <InputFieldWithBorder
            placeholder={`Expense Name`}
            className={`mb-4`}
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
            inputClassName={`capitalize`}
          />
        </div>
        <div className="mb-5">
          <TextareaWithBorder
            rows={4}
            placeholder={`Expense Note`}
            value={expenseNote}
            onChange={(e) => setExpenseNote(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <InputFieldWithBorder
            placeholder={`Expense Amount`}
            className={`mb-4`}
            value={expenseAmount}
            onChange={(e) => setExpenseAmount(e.target.value)}
            inputClassName={`capitalize`}
            type={`number`}
          />
        </div>

        <CustomButton
          label={`Save`}
          className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] w-full`}
          onClick={() => handleSave()}
        />
      </div>
    </div>
  );
}

export default CreateExpance;
