import React from "react";
import certificate1 from "../../assets/cer1.png";

function CertificateTem1({ stInfo }) {
  return (
    <div className="relative w-[50%] h-[50vh] flex justify-center items-center">
      <img src={certificate1} alt="" className="absolute top-0 left-0" />
      <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="flex flex-col items-center">
            <p
              className="text-4xl font-bold text-[#313639] capitalize"
              style={{ fontFamily: "Georgia, serif" }}
            >
              Certificate of {stInfo?.nameOfCertificate}
            </p>
            <p
              className=" text-[#313639] capitalize mt-4"
              style={{ fontFamily: "Gill Sans, sans-serif" }}
            >
              Lorem ipsum dolor, sit amet consectetur adipisicing elit
            </p>
          </div>
          <div className="w-full flex items-center flex-col">
            <p
              className=" text-[#313639] capitalize mt-4 underline text-4xl"
              style={{ fontFamily: "cursive" }}
            >
              {stInfo?.stName}
            </p>
            <p
              className=" text-[#313639] capitalize mt-4 w-[80%] text-sm"
              style={{ fontFamily: "Gill Sans, sans-serif" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              laboriosam ex error neque! Obcaecati quia laudantium totam, unde
              quisquam officiis ipsum officia quas perferendis, quasi sit soluta
              consequatur quo deleniti?
            </p>

            <div className="">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificateTem1;
