import React, { useContext, useRef } from "react";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { useReactToPrint } from "react-to-print";
import { MOTILAL_STRINGS } from "../../../StringConstant/MotiLalAllConstant";
import { useLocation } from "react-router-dom";
import { Utils } from "../../../Utils/Utils";
import { AuthContext } from "../../../Provider/AuthProvider";

function FeeReceiptEnglish() {
  const componentRef = useRef();
  const location = useLocation();
  const date = Date.now();
  const medium = localStorage.getItem("medium");

  const { userData } = useContext(AuthContext);

  console.log(userData?.medium);

  const data = [
    {
      "sno.": "1",
      tuition_fee: "1000.00",
      annual_charges: "1000.00",
      admission_charges: "1000.00",
      registration_fee: "500.00",
      total: "3500.00",
      "conveyance_(van)": "800.00",
      "(bus)": "1000.00",
      "g.total": "",
      mode: "Cash",
      paid: "1000000",
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log(location?.state.showConveyanceDropdown);
  console.log(location?.state.previousDueBalance);

  return (
    <div className="">
      <div
        className=" flex justify-between items-start h-[100vh]  overflow-scroll w-full gap-5 "
        ref={componentRef}
      >
        <div className=" p-10 w-1/2 border-r border-dashed border-black">
          <div className="text-center mb-5">Student Copy</div>

          <div className="  border-b border-dashed border-black pb-3 flex items-center gap-3 justify-center">
            <img
              src={require("../../../assets/school_logo.png")}
              className="w-[3rem]"
              alt=""
            />
            {medium === "ENGLISH" && (
              <div className="">
                <p className="font-bold text-xl">
                  {MOTILAL_STRINGS?.MLMEC_NAME_ENGLISH}
                </p>
                <p className="text-center">
                  {MOTILAL_STRINGS?.SCHOOL_ADDRESS_ENGLISH}
                </p>
              </div>
            )}
            {medium === "HINDI" && (
              <div className="">
                <p className="font-bold text-xl">
                  {MOTILAL_STRINGS?.MLMIC_NAME_ENGLISH}
                </p>
                <p className="text-center">
                  {MOTILAL_STRINGS?.SCHOOL_ADDRESS_ENGLISH}
                </p>
              </div>
            )}
          </div>
          <div className="font-medium text-sm mt-5 w-full flex justify-between items-start">
            <p className="">Receipt No : MLMIC/{date}</p>
            <p className="">
              {`Date :
               ${Utils?.formatDate(
                 location?.state?.allPaymentDetails?.updateFee?.createdAt
               )}`}
            </p>
          </div>
          <div className="flex justify-between items-start font-semibold mt-5">
            <div className="flex flex-col gap-3 w-full  ">
              <div className="font-medium text-xs  pb-3  flex flex-col items-start gap-0 ">
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">Student Id/Scholar : </p>
                    <p className="">
                      {
                        location?.state?.currentStudentFullInfoObject
                          ?.scholarNumber
                      }
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">Student Name : </p>
                    <p className="">
                      {`${location?.state?.currentStudentFullInfoObject?.personalDetails?.firstName} ${location?.state?.currentStudentFullInfoObject?.personalDetails?.lastName}`}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">Father's Name : </p>
                    <p className="">
                      {`${location?.state?.currentStudentFullInfoObject?.personalDetails?.fatherName} `}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">Class : </p>
                    <p className="">
                      {location?.state?.currentStudentFullInfoObject?.class}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">
                      For the month of <b>{location?.state?.month}</b>
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">section : </p>
                    <p className="">
                      {location?.state?.currentStudentFullInfoObject?.section}
                    </p>
                  </div>
                </div>

                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3 font-bold">
                    <p className="">Payment Mode : </p>
                    <p className=""> Cash</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table className="border border-black w-full text-xs">
            <tbody>
              {location?.state?.receiptData?.academic?.tuitionFee && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Tuition Fee</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.tuitionFee}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.receiptData?.academic?.annualCharges && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Annual Charges</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.annualCharges}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.academicYear ===
                Utils?.generateAcademicYears(1)?.[0] &&
                location?.state?.receiptData?.academic?.admissitionCharges && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="">Admission Charges</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {
                        location?.state?.receiptData?.academic
                          ?.admissitionCharges
                      }
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.academicYear ===
                Utils?.generateAcademicYears(1)?.[0] &&
                location?.state?.receiptData?.academic?.registrationFee && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="">Registration Fee</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.academic?.registrationFee}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.receiptData?.academic?.termFee && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Term Fee</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.termFee}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.showConveyanceDropdown === "van" &&
                location?.state?.receiptData?.conveyance?.van && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="text-end w-full">Conveyance (Van)</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.conveyance?.van}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.showConveyanceDropdown === "bus" &&
                location?.state?.receiptData?.conveyance?.van && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="text-end w-full">Conveyance (Bus)</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.conveyance?.bus}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.totalFees && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full">Total</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2 font-semibold">
                    {location?.state?.totalFees}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state.previousDueBalance ||
                location?.state.previousDueBalance > 0) && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full">
                      Previous Due Balance
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2 font-semibold">
                    {location?.state.previousDueBalance}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {/* {location?.state?.receiptData?.extra_curricular?.computer_lab && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="text-end w-full">Computer Lab</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {
                      location?.state?.receiptData?.extra_curricular
                        ?.computer_lab
                    }
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )} */}
              {location?.state?.gTotalFees && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full h-full">
                      G. Total
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2  font-semibold">
                    {location?.state?.gTotalFees}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state?.paidBalence === 0 ||
                location?.state?.paidBalence) && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full h-full">
                      Paid Amount
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2  font-semibold">
                    {location?.state?.paidBalence}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state?.dueBalence ||
                location?.state?.dueBalence > 0) && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%]">
                    <div className="font-extrabold text-end w-full pt-1 h-full ">
                      Current Due Balance
                      <sup className="text-sm font-normal ">*</sup>
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-1.5  font-extrabold ">
                    {location?.state?.dueBalence}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="w-full mt-10  flex justify-between font-semibold text-xs">
            <p className="">Parent's Signature</p>
            <p className="">School Stamp / Signature</p>
          </div>
          <div className="w-full mt-5  flex flex-col justify-between  text-xs border p-3   rounded border-black border-dashed">
            <p className="">
              <span className="text-sm font-medium me-2">*</span>Due balance
              will be adjusted in next month, it will be reflected as due last
              month.
            </p>
            <p className="">
              <span className="text-sm font-normal me-2">*</span>बकाया शेष अगले
              महीने में समायोजित किया जाएगा, यह पिछले महीने के रूप में
              प्रतिबिम्बित होगा।
            </p>
          </div>
        </div>
        <div className=" ps-5 pe-10 p-10 w-1/2">
          <div className="text-center mb-5">School Copy</div>

          <div className="  border-b border-dashed border-black pb-3 flex items-center gap-3 justify-center">
            <img
              src={require("../../../assets/school_logo.png")}
              className="w-[3rem]"
              alt=""
            />
            {medium === "ENGLISH" && (
              <div className="">
                <p className="font-bold text-xl">
                  {MOTILAL_STRINGS?.MLMEC_NAME_ENGLISH}
                </p>
                <p className="text-center">
                  {MOTILAL_STRINGS?.SCHOOL_ADDRESS_ENGLISH}
                </p>
              </div>
            )}
            {medium === "HINDI" && (
              <div className="">
                <p className="font-bold text-xl">
                  {MOTILAL_STRINGS?.MLMIC_NAME_ENGLISH}
                </p>
                <p className="text-center">
                  {MOTILAL_STRINGS?.SCHOOL_ADDRESS_ENGLISH}
                </p>
              </div>
            )}
          </div>
          <div className="font-medium text-sm mt-5 w-full flex justify-between items-start">
            <p className="">Receipt No : MLMIC/{date}</p>
            <p className="">
              {`Date :
               ${Utils?.formatDate(
                 location?.state?.allPaymentDetails?.updateFee?.createdAt
               )}`}
            </p>
          </div>
          <div className="flex justify-between items-start font-semibold mt-5">
            <div className="flex flex-col gap-3 w-full  ">
              <div className="font-medium text-xs  pb-3  flex flex-col items-start gap-0 ">
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">Student Id/Scholar : </p>
                    <p className="">
                      {
                        location?.state?.currentStudentFullInfoObject
                          ?.scholarNumber
                      }
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">Student Name : </p>
                    <p className="">
                      {`${location?.state?.currentStudentFullInfoObject?.personalDetails?.firstName} ${location?.state?.currentStudentFullInfoObject?.personalDetails?.lastName}`}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">Father's Name : </p>
                    <p className="">
                      {`${location?.state?.currentStudentFullInfoObject?.personalDetails?.fatherName} `}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">Class : </p>
                    <p className="">
                      {location?.state?.currentStudentFullInfoObject?.class}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3">
                    <p className="">
                      For the month of <b>{location?.state?.month}</b>
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="">section : </p>
                    <p className="">
                      {location?.state?.currentStudentFullInfoObject?.section}
                    </p>
                  </div>
                </div>

                <div className="w-full flex justify-between">
                  <div className="flex items-center gap-3 font-bold">
                    <p className="">Payment Mode : </p>
                    <p className=""> Cash</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table className="border border-black w-full text-xs">
            <tbody>
              {location?.state?.receiptData?.academic?.tuitionFee && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Tuition Fee</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.tuitionFee}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.receiptData?.academic?.annualCharges && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Annual Charges</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.annualCharges}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.academicYear ===
                Utils?.generateAcademicYears(1)?.[0] &&
                location?.state?.receiptData?.academic?.admissitionCharges && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="">Admission Charges</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {
                        location?.state?.receiptData?.academic
                          ?.admissitionCharges
                      }
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.academicYear ===
                Utils?.generateAcademicYears(1)?.[0] &&
                location?.state?.receiptData?.academic?.registrationFee && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="">Registration Fee</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.academic?.registrationFee}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.receiptData?.academic?.termFee && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="">Term Fee</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {location?.state?.receiptData?.academic?.termFee}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {location?.state?.showConveyanceDropdown === "van" &&
                location?.state?.receiptData?.conveyance?.van && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="text-end w-full">Conveyance (Van)</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.conveyance?.van}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.showConveyanceDropdown === "bus" &&
                location?.state?.receiptData?.conveyance?.van && (
                  <tr className="flex items-start justify-between border-b border-black">
                    <td className="flex items-start gap-3 w-[45%] p-2">
                      <div className="text-end w-full">Conveyance (Bus)</div>
                    </td>
                    <td className="w-[30%] border-x text-center border-black p-2">
                      {location?.state?.receiptData?.conveyance?.bus}
                    </td>
                    <td className="w-[20%] p-2"></td>
                  </tr>
                )}
              {location?.state?.totalFees && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full">Total</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2 font-semibold">
                    {location?.state?.totalFees}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state.previousDueBalance ||
                location?.state.previousDueBalance > 0) && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full">
                      Previous Due Balance
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2 font-semibold">
                    {location?.state.previousDueBalance}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {/* {location?.state?.receiptData?.extra_curricular?.computer_lab && (
                <tr className="flex items-start justify-between border-b border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="text-end w-full">Computer Lab</div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2">
                    {
                      location?.state?.receiptData?.extra_curricular
                        ?.computer_lab
                    }
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )} */}
              {location?.state?.gTotalFees && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full h-full">
                      G. Total
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2  font-semibold">
                    {location?.state?.gTotalFees}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state?.paidBalence === 0 ||
                location?.state?.paidBalence) && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%] p-2">
                    <div className="font-semibold text-end w-full h-full">
                      Paid Amount
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-2  font-semibold">
                    {location?.state?.paidBalence}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
              {(location?.state?.dueBalence ||
                location?.state?.dueBalence > 0) && (
                <tr className="flex items-start justify-between border border-black">
                  <td className="flex items-start gap-3 w-[45%]">
                    <div className="font-extrabold text-end w-full pt-1 h-full ">
                      Current Due Balance
                      <sup className="text-sm font-normal ">*</sup>
                    </div>
                  </td>
                  <td className="w-[30%] border-x text-center border-black p-1.5  font-extrabold ">
                    {location?.state?.dueBalence}
                  </td>
                  <td className="w-[20%] p-2"></td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="w-full mt-10  flex justify-between font-semibold text-xs">
            <p className="">Parent's Signature</p>
            <p className="">School Stamp / Signature</p>
          </div>
          <div className="w-full mt-5  flex flex-col justify-between  text-xs border p-3   rounded border-black border-dashed">
            <p className="flex items-center">
              <span className="text-sm font-medium me-2">*</span>Due balance
              will be adjusted in next month, it will be reflected as due last
              month.
            </p>
            <p className="flex items-center">
              <span className="text-sm font-normal me-2">*</span>बकाया शेष अगले
              महीने में समायोजित किया जाएगा, यह पिछले महीने के रूप में
              प्रतिबिम्बित होगा।
            </p>
          </div>
        </div>
      </div>
      <div className="absolute bottom-10 ">
        <CustomButton
          label={`Print Receipt`}
          className={` text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] mx-5`}
          onClick={handlePrint}
        />
      </div>
    </div>
  );
}

export default FeeReceiptEnglish;
