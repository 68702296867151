import React, { useContext, useState } from "react";
import { CiMail } from "react-icons/ci";
import { IoIosUnlock } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { useStaffLoginMutation } from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { AuthContext } from "../../../Provider/AuthProvider";

function TeacherLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login: setAccessToken } = useContext(AuthContext);

  const [staffLogin, { isLoading: staffLoginIsLoading }] =
    useStaffLoginMutation();

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleLogin = async () => {
    if (email === null || email === undefined || email.length === 0) {
      enqueueSnackbar("Email is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered Email is incorrect", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      return;
    }
    if (password === null || password === undefined || password.length === 0) {
      enqueueSnackbar("Password is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    // navigate("/");

    try {
      const response = await staffLogin({
        body: {
          email: email,
          password: password,
        },
      });

      if (response.data) {
        // console.log("user login : ", response?.data?.staff?.staffId);
        localStorage.setItem("role", response?.data?.staff?.role);
        localStorage.setItem("staffId", response?.data?.staff?.staffId);

        navigate(`/`);
      } else {
        // console.log("Oops! user is not login");
        enqueueSnackbar("Something went wrong !", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        return;
      }
    } catch (error) {
      console.error("Mutation failed : ", error?.message);
    }
  };
  return (
    <div className="w-full lg:px-10 my-5">
      {staffLoginIsLoading && <CircleLoader />}
      {/* {isError && (
            <div className="border border-red-400 p-4 rounded-lg text-red-600 text-xs">
              {error?.data?.message}
            </div>
          )} */}

      <div className="">
        <div className="mt-3">
          <div className=" relative  border rounded-full px-5 py-3 ">
            <span className="absolute top-3.5  bottom-0 my-auto text-xl">
              <CiMail />
            </span>
            <input
              autoComplete="off"
              type="email"
              className=" w-full rounded-full border-0 text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 ms-5 px-3 focus:outline-none"
              placeholder="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className=" relative border rounded-full px-5 py-3">
            <span className="absolute mt-0 mb-0 my-auto text-xl top-3.5">
              <IoIosUnlock className="text-gray-500" />
            </span>
            <input
              autoComplete="off"
              type="password"
              className=" w-full rounded-full border-0  text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 mx-5 px-3 focus:outline-none "
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                // isError(false);
              }}
            />
          </div>
        </div>
        <CustomButton
          label={`LOG IN`}
          className={`!mx-0 !py-3 w-full !rounded-full text-white bg-blue-600 border-none  mt-3 hover:bg-blue-600`}
          onClick={() => handleLogin()}
        />
      </div>
      <div className="flex justify-center w-full text-sm mt-5">
        <p className=" mt-2 text-blue-500 cursor-pointer">Forgot password ?</p>
      </div>
    </div>
  );
}

export default TeacherLogin;
