import React, { useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { Calendar } from "primereact/calendar";

import CreateExpanceCategoryForm from "./CreateExpanceCategoryForm";
import CreateExpance from "./CreateExpance";
import ExpenseRangeTable from "./ExpenseRangeTable";
import { Utils } from "../../Utils/Utils";
import {
  useGetExpenseCategoryQuery,
  useGetExpenseRangeQuery,
} from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";

function ExpenseManagement() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [expenceTableData, setExpenceTableData] = useState([]);
  const [expenceCategoryData, setExpenceCategoryData] = useState([]);

  const {
    data: getCategoryData,
    isLoading: getCategoryDataIsLoading,
    refetch: getCategoryDataRefetch,
  } = useGetExpenseCategoryQuery();

  const {
    data: getExpenceTable,
    isLoading: getExpenceTableIsLoading,
    refetch: getExpenceTableRefetch,
  } = useGetExpenseRangeQuery(
    {
      // startDate: startDate,
      // endDate: endDate,
      startDate: Utils?.formateDateForApiRequest(startDate),
      endDate: Utils?.formateDateForApiRequestNextDayDate(endDate),
    },
    { skip: !startDate || !endDate }
  );

  useEffect(() => {
    if (getExpenceTable) {
      setExpenceTableData(getExpenceTable?.expenses);
    }
  }, [getExpenceTable]);

  useEffect(() => {
    if (getCategoryData) {
      setExpenceCategoryData(getCategoryData?.categories);
    }
  }, [getCategoryData]);

  // const [startDate, setStartDate] = useState(
  //   Utils?.formateDateForApiRequest(new Date())
  // );
  // const [endDate, setEndDate] = useState(
  //   Utils?.formateDateForApiRequest(new Date())
  // );

  // const handleFindExpense = () => {
  //   if (startDate && endDate) {
  //     setStartDate();
  //     console.log("a");
  //   }

  //   console.log("start date ", startDate);
  //   console.log("end date ", endDate);
  // };

  return (
    <div className="m-5">
      {(getExpenceTableIsLoading || getCategoryDataIsLoading) && (
        <CircleLoader />
      )}
      <div className="w-full flex justify-between items-center bg-white px-5 py-2 rounded-lg">
        <p className="">Expense Management</p>
      </div>
      <div className="flex justify-between gap-5 items-start w-full my-5">
        <div className="flex flex-col gap-10 w-full">
          <div className="flex flex-col justify-start bg-white   p-5 rounded-lg">
            <p className="font-bold text-[#313639]  mb-3">Select Date</p>

            <div className="flex flex-row gap-8 items-end">
              <div className="">
                <p className="mb-1">From</p>

                <Calendar
                  inputClassName="border  p-2 rounded-lg text-sm "
                  value={startDate}
                  onChange={(e) => setStartDate(e.value)}
                  dateFormat="yy-mm-dd"
                  className="w-full "
                  placeholder={`DD/MM/YYYY`}
                />
              </div>
              <div className="">
                <p className="mb-1">To</p>

                <Calendar
                  inputClassName="border  p-2 rounded-lg text-sm "
                  value={endDate}
                  onChange={(e) => setEndDate(e.value)}
                  dateFormat="yy-mm-dd"
                  className="w-full "
                  placeholder={`DD/MM/YYYY`}
                />
              </div>
              {/* <CustomButton
                label={`Find Expense`}
                className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
                onClick={() => handleFindExpense()}
              /> */}
            </div>
          </div>

          <div className="">
            <ExpenseRangeTable expenceTableData={expenceTableData} />
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <CreateExpanceCategoryForm
            getCategoryDataRefetch={getCategoryDataRefetch}
          />
          <CreateExpance
            getExpenceTableRefetch={getExpenceTableRefetch}
            getCategoryData={expenceCategoryData}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpenseManagement;
