import { useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import img1 from "../../assets/slide5.JPG";
import img4 from "../../assets/slide3.jpg";
import img2 from "../../assets/slide1.JPG";
import img3 from "../../assets/slide2.JPG";
import img5 from "../../assets/slide4.JPG";

function HeroContainer() {
  const ref = useRef();
  const showNext = () => {
    ref.current.onClickNext();
  };

  const showRevious = () => {
    ref.current.onClickPrev();
  };
  const imageUrls = [
    {
      url: img1,
    },
    {
      url: img2,
    },
    // {
    //   url: img3,
    // },
    {
      url: img4,
    },
    {
      url: img5,
    },
  ];
  return (
    <div className="flex items-center justify-center  animate-blurIn ">
      {/* <div className="absolute flex w-[87%] tablet:w-[80%] lg:w-full justify-between mx-auto left-0 right-0 z-30  ">
        <button className="p-2 rounded-2xl" onClick={showRevious}>
          <img
            src={require("../../assets/prev-icon.png")}
            alt=""
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>
        <button className="p-2 rounded-2xl " onClick={showNext}>
          <img
            src={require("../../assets/next-icon.png")}
            alt=""
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>
      </div> */}
      {/* <Carousel
        ref={ref}
        centerMode={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        showIndicators={false}
        className="rounded-md  bg-red-900 "
        // centerSlidePercentage={75}
      >
        {imageUrls?.map((image, index) => (
          <img
            key={index}
            className=" md:rounded-xl  w-full tablet:w-[90%] h-[30rem] tablet:h-[15rem]   pe-5 md:pe-0 tablet:px-5 rounded-md"
            src={image?.url}
          />
        ))}
      </Carousel> */}
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        dynamicHeight={true}
        autoPlay={true}
      >
        {imageUrls?.map((image, index) => (
          <div className="">
            <img
              key={index}
              className="   w-full xl:h-[50rem] "
              src={image?.url}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default HeroContainer;
