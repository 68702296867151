import React, { useEffect, useState } from "react";
import { Utils, months } from "../../../Utils/Utils";
import { ToWords } from "to-words";

function TcMiddleBox({ currentStudentAllData }) {
  const [dobInWord1, setDobInWord1] = useState("");
  const [dobInWord2, setDobInWord2] = useState("");
  const [dobInWord3, setDobInWord3] = useState("");
  const toWords = new ToWords();

  useEffect(() => {
    const hasSlash =
      (currentStudentAllData?.personalDetails?.dob).includes("/");

    if (!hasSlash) {
      const date = new Date(currentStudentAllData?.personalDetails?.dob);

      setDobInWord1(toWords?.convert(date?.getDate()));
      setDobInWord2(
        months[
          parseInt(currentStudentAllData?.personalDetails?.dob.split("-")?.[1] -1)
        ]
      );
      setDobInWord3(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("-")?.[0]
        )
      );
    console.log(currentStudentAllData?.personalDetails?.dob.split("-")?.[1]);

    } else {
      console.log(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("/")?.[2]
        )
      );
      setDobInWord1(
        toWords?.convert(
          currentStudentAllData?.personalDetails?.dob.split("/")?.[0]
        )
      );
      setDobInWord2(
        months[
          parseInt(
            currentStudentAllData?.personalDetails?.dob.split("/")?.[1] - 1
          )
        ]
      );
      setDobInWord3(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("/")?.[2]
        )
      );
    }
    console.log("Slash Data", hasSlash);
  }, [toWords]);

  return (
    <div className="w-full">
      <div className="flex flex-col items-center w-full font-bold text-lg text-[#4d4d4d]">
        <p className="">Scholar's Register & Transfer Certificate Form</p>
        <p className="">छात्र पंजी एवं स्थानांतरण प्रमाण - पत्र </p>
      </div>
      <table className="w-full text-xsm rounded">
        <thead className="border-[0.5px] border-black ">
          <tr className="text-gray-700">
            <td className="p-1 text-center">
              <p className="">
                छात्र का नाम, जाति सहित,
                <br /> यदि हिंदू है अथवा धर्म
              </p>
              <p className="">
                Name of the scholar with cast <br />
                If Hindu Otherwise religion
              </p>
            </td>
            <td className="p-1 text-center border-x-[0.5px] border-black">
              <p className="">माता का नाम</p> <p className="">Mother's Name</p>
            </td>
            <td className="p-1 text-center border-x-[0.5px] border-black">
              <p className="">
                पिता अथवा संरक्षक का नाम,
                <br /> व्यवसाय तथा पूरा पता
              </p>
              <p className="">
                Name, Occupation & Address of <br /> Parent or Guardian
              </p>
            </td>
            <td className="p-1 text-center">
              <p className="">छात्र की जन्मतिथि</p>
              <p className="">Date of birth of the scholar</p>
            </td>
          </tr>
        </thead>
        <tbody className="border-[0.5px] border-black">
          <tr className="text-gray-700">
            <td className="p-1 text-center">
              <textarea
                value={`${currentStudentAllData?.personalDetails?.firstName} ${currentStudentAllData?.personalDetails?.lastName}`}
                className="w-full h-full bg-transparent focus:outline-none text-center rounded resize-none capitalize font-bold text-xs"
                rows={2}
                // disabled
              ></textarea>
            </td>
            <td className="p-1 text-center border-x-[0.5px] border-black">
              <textarea
                value={currentStudentAllData?.personalDetails?.motherName}
                className="w-full h-full bg-transparent focus:outline-none text-center rounded resize-none capitalize font-bold text-xs"
                rows={2}
                // disabled
              ></textarea>
            </td>
            <td className="p-1 text-center border-x-[0.5px] border-black ">
              <textarea
                value={`${currentStudentAllData?.personalDetails?.fatherName},( ${currentStudentAllData?.otherDetails?.fathersOccupaton} )( ${currentStudentAllData?.addressDetails?.houseNumber}, ${currentStudentAllData?.addressDetails?.addressLine1}, ${currentStudentAllData?.addressDetails?.addressLine2}, ${currentStudentAllData?.addressDetails?.city}, ${currentStudentAllData?.addressDetails?.state} )`}
                className="w-full h-full bg-transparent focus:outline-none text-center rounded resize-none capitalize font-bold text-xs"
                rows={3}
                // disabled
              ></textarea>
            </td>
            <td className="p-1 text-center">
              <textarea
                value={Utils?.formatDate(
                  currentStudentAllData?.personalDetails?.dob
                )}
                className="w-full h-full bg-transparent focus:outline-none text-center rounded resize-none capitalize font-bold text-sm"
                rows={2}
                // disabled
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="text-xsm">
        <div className="flex items-center gap-4 border-b border-black py-1">
          <p className="capitalize w-[20rem]">
            <span>जन्म तिथि (शब्दों में)</span>
            <span>Date of birth (in words)</span>
          </p>
          <input
            disabled
            value={`${dobInWord1} ${dobInWord2} ${dobInWord3}`}
            type="text"
            className="bg-transparent w-full focus:outline-none text-xs font-bold"
          />
        </div>

        <div className="flex items-center w-full gap-4 border-b border-black py-1">
          <div className="flex items-center gap-4 w-[50%]">
            <p className="capitalize w-[20rem]">
              <span>आधार नंबर</span>
              <span>Aadhar No.</span>
            </p>
            <input
              disabled
              value={currentStudentAllData?.personalDetails?.adharNumber}
              type="text"
              className="bg-transparent w-full focus:outline-none text-xs font-bold"
            />
          </div>
          <div className="flex items-center w-[50%]">
            <p className="capitalize w-[20rem]">{/* <span>PEN No.</span> */}</p>
            <input
              disabled
              value={currentStudentAllData?.penNumber}
              type="text"
              className="bg-transparent w-full focus:outline-none "
            />
          </div>
        </div>
        <div className="flex items-end gap-4 py-1">
          <p className="capitalize w-[50%]">
            <span>अंतिम संस्थान जहाँ आपने अध्ययन किया</span>
            <span>Last institution where you studied</span>
          </p>
          <input
            disabled
            value={`${currentStudentAllData?.educationDetails?.previousSchool} `}
            type="text"
            className="bg-transparent focus:outline-none w-[50%] capitalize text-xs font-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default TcMiddleBox;
