import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import CircleLoader from "../../AllLoaders/CircleLoader";
import {
  useFeeReminderMutation,
  useGetPaymentByScholarNumberQuery,
} from "../../Redux/ReduxToolkit";
import { Utils } from "../../Utils/Utils";
import Lottie from "lottie-react";
import search_animation from "../../assets/search_animation.json";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import FeeSubmissionPopup from "../StudentManagement/FeeSubmission/FeeSubmissionPopup";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import { enqueueSnackbar } from "notistack";
import FeeSubmissionHindi from "../StudentManagement/FeeSubmission/FeeSubmissionHindi";
import AdvancedFeeDeposit from "../StudentManagement/FeeSubmission/AdvancedFeeDeposit";

function FeeSummayReportHindi({
  rightPopup,
  currentStudentFullInfoObject,
  setShowStudentFeesInfoBox,
  getAllStudentsRefetch,
}) {
  const date = new Date();

  const [showFeeSubmitPopup, setshowFeeSubmitPopup] = useState(false);
  const [previousDueBalance, setPreviousDueBalance] = useState("");
  const [showAdvancedFeePopup, setShowAdvanceFeePopup] = useState(false);

  const [feeReminder, { isLoading: feeReminderIsLoading, isSuccess }] =
    useFeeReminderMutation();

  const {
    data: getPaymentByScholarId,
    isLoading: getPaymentByScholarIdIsLoading,
    refetch: getPaymentByScholarIdRefetch,
  } = useGetPaymentByScholarNumberQuery({
    scholarNumber: currentStudentFullInfoObject?.scholarNumber,
    academicYear: Utils?.generateAcademicYears(1)?.[0],
  });

  useEffect(() => {
    if (getPaymentByScholarId) {
      // getPaymentByScholarId?.student?.map((feeInfo,index) => {
      //   if()
      // });
      // console.log(
      //   "Student Data Due",
      //   getPaymentByScholarId?.student[
      //     getPaymentByScholarId?.student?.length - 1
      //   ]
      // );
      const lastPaidFeeObj =
        getPaymentByScholarId?.student[
          getPaymentByScholarId?.student?.length - 1
        ];
      setPreviousDueBalance(lastPaidFeeObj);
    }
  }, [getPaymentByScholarId]);

  const handleFeeReminder = async () => {
    const body = {
      campaignName: "FEE REMINDER",
      destination: `91${currentStudentFullInfoObject?.personalDetails?.whatsAppNumber}`,
      userName: `${
        currentStudentFullInfoObject?.personalDetails?.firstName
          .charAt(0)
          .toUpperCase() +
        currentStudentFullInfoObject?.personalDetails?.firstName.slice(1)
      } ${
        currentStudentFullInfoObject?.personalDetails?.lastName
          .charAt(0)
          .toUpperCase() +
        currentStudentFullInfoObject?.personalDetails?.lastName.slice(1)
      }`,
      templateParams: [
        `${currentStudentFullInfoObject?.personalDetails?.firstName} ${currentStudentFullInfoObject?.personalDetails?.lastName}`,
        `${currentStudentFullInfoObject?.class}, ${currentStudentFullInfoObject?.section},  with outstanding ₹ ${currentStudentFullInfoObject?.otherDetails?.balanceDue}`,
        `${Utils?.formatDate(date)}`,
      ],
    };

    try {
      const response = await feeReminder({
        body: body,
      });
      if (response) {
        // console.log("dsvefdsvcdsfcsxda",response?.data?.data);
        if (response?.data?.data?.success === "true") {
          enqueueSnackbar("The message was sent successfully 👍🏻", {
            autoHideDuration: 3000,
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        }
      } else {
        enqueueSnackbar("message not senty 📵", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  console.log("previousDueBalance", previousDueBalance);

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-start fixed top-0 left-0   ">
      {(getPaymentByScholarIdIsLoading || feeReminderIsLoading) && (
        <CircleLoader />
      )}
      <div
        className={`bg-white  min-w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${rightPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <div className="flex items-center gap-2">
            <VscAccount className=" text-2xl text-gray-400" />
            <p className="text-xl font-semibold text-[#2B60EC] capitalize">
              {`  ${currentStudentFullInfoObject?.personalDetails?.firstName} ${currentStudentFullInfoObject?.personalDetails?.lastName}`}
            </p>
          </div>
          Hindi
          <IoCloseSharp
            className={` text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300 `}
            onClick={() => setShowStudentFeesInfoBox(false)}
          />
        </div>
        <div
          className={`w-full flex items-center px-5  mt-5 ${
            currentStudentFullInfoObject?.otherDetails?.balanceDue !== 0
              ? " justify-between"
              : " justify-end"
          }`}
        >
          {currentStudentFullInfoObject?.otherDetails?.balanceDue !== 0 && (
            <div className="flex items-center gap-3  border p-3 rounded-lg border-red-400">
              <p className=" ">Previous due amount : </p>
              <p className={` font-bold text-red-500 `}>
                ₹ {currentStudentFullInfoObject?.otherDetails?.balanceDue}
              </p>
            </div>
          )}

          <div className="flex items-center">
            {/* <div className="">
              <CustomButton
                label={`Advanced Fee Deposit`}
                className={`bg-[#2B60EC] border-none text-white `}
                onClick={() => setShowAdvanceFeePopup(true)}
              ></CustomButton>
            </div> */}
            {currentStudentFullInfoObject?.otherDetails?.balanceDue !== 0 && (
              <div className="   flex  ">
                <CustomButton
                  hasIcon={true}
                  label={`Fee Reminder`}
                  className={`bg-red-500 border-none text-white `}
                  onClick={() => handleFeeReminder()}
                >
                  <BsFillSendArrowUpFill />
                </CustomButton>
              </div>
            )}
            <div className="">
              <CustomButton
                label={`Fee Submit`}
                className={`bg-orange-400 border-none text-white `}
                onClick={() => setshowFeeSubmitPopup(true)}
              ></CustomButton>
            </div>
          </div>
        </div>
        <div className="p-5 h-[85vh] overflow-y-auto">
          {getPaymentByScholarId?.student?.length > 0 ? (
            <div className="relative overflow-hidden rounded bg-white">
              <div className="overflow-y-auto max-h-[80vh]">
                <table className="w-full text-sm bg-white">
                  <thead className="sticky top-0 bg-white ">
                    <tr className="shadow text-gray-700">
                      <th className="p-3 text-center">S. No.</th>
                      <th className="p-3 text-center">Scholar Name</th>
                      <th className="p-3 text-center">Class</th>
                      <th className="p-3 text-center">Fee Submission Date</th>
                      <th className="p-3 text-center">Month</th>
                      <th className="p-3 text-center">Total Fee</th>
                      <th className="p-3 text-center">
                        Total Fee With Due Amount
                      </th>
                      <th className="p-3 text-center">Relaxation Amount</th>
                      <th className="p-3 text-center">Paid Amount</th>
                      <th className="p-3 text-center">Due Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPaymentByScholarId?.student?.map((feeInfo, index) => (
                      <tr
                        className={`shadow text-gray-500 cursor-pointer hover:bg-gray-400/10 mb-5 ${
                          feeInfo?.relaxationApplied
                            ? "bg-red-100 hover:!bg-red-200"
                            : ""
                        }`}
                        key={index}
                      >
                        <td className="p-3 text-center">{index + 1}</td>
                        <td className="p-3 text-center">
                          {`${currentStudentFullInfoObject?.personalDetails?.firstName} ${currentStudentFullInfoObject?.personalDetails?.lastName}`}
                        </td>
                        <td className="p-3 text-center">
                          {feeInfo?.currentClass}
                        </td>
                        <td className="p-3 text-center">
                          {Utils.formatDate(feeInfo?.createdAt)}
                        </td>
                        <td className="p-3 text-center">
                          {Utils?.getMonthName(feeInfo?.month)}
                        </td>
                        <td className="p-3 text-center">
                          {feeInfo?.totalFeeForCurrentMonth}
                        </td>{" "}
                        <td className="p-3 text-center">{feeInfo?.totalDue}</td>
                        <td className="p-3 text-center">
                          {feeInfo?.relaxationAmount
                            ? feeInfo?.relaxationAmount
                            : "NA"}
                        </td>
                        <td className="p-3 text-center">
                          {feeInfo?.amountPaid}
                        </td>
                        <td className="p-3 text-center text-red-500">
                          {feeInfo?.currentDue}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <Lottie
                animationData={search_animation}
                loop={true}
                className="w-[15rem]"
              />
              <p className="-translate-y-10 text-xl">
                No fees in this student.
              </p>
            </div>
          )}
        </div>
      </div>
      {showFeeSubmitPopup && (
        <FeeSubmissionHindi
          bottomPopup={`animate-bottomPopup`}
          setshowFeeSubmitPopup={setshowFeeSubmitPopup}
          currentStudentFullInfoObject={currentStudentFullInfoObject}
          getPaymentByScholarIdRefetch={getPaymentByScholarIdRefetch}
          previousDueBalance={previousDueBalance}
          getAllStudentsRefetch={getAllStudentsRefetch}
        />
      )}
      {showAdvancedFeePopup && (
        <AdvancedFeeDeposit
          bottomPopup={`animate-bottomPopup`}
          setShowAdvanceFeePopup={setShowAdvanceFeePopup}
        />
      )}
    </div>
  );
}

export default FeeSummayReportHindi;
