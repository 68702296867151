import React from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { MOTILAL_STRINGS } from "../../StringConstant/MotiLalAllConstant";

function WellcomeBox() {
  return (
    <div className="w-full flex  justify-center my-10">
      <div className="w-[95%] tablet:w-[80%] lg:w-[70%] flex flex-col lg:flex-row justify-between items-start">
        <div className="w-full lg:w-[45%] p-5 lg:px-0 ">
          <p className="text-xl lg:text-2xl font-extrabold">
            WELCOME TO THE <br /> {MOTILAL_STRINGS?.MLMIC_NAME_ENGLISH}
          </p>
          <p className="font-bold text-green-600">THE GREEN SCHOOL</p>
          <p className=" mt-4 text-sm lg:text-base">
            Set amidst verdant greenery and beautifully landscaped gardens and a
            panoramic view of the luxuriant lawns, MotiLal Memorial Inter
            College prides itself on the most sophisticated learning system and
            state of the art amenities. Green and cool, eco-friendly environs
            comprising of tender saplings, a variety of trees and a myriad of
            flowering plants infuse a spark among students and inspires them
            with zeal to learn and explore more.
          </p>
          <p className=" my-4 text-sm lg:text-base">
            Our vision is to encourage children to think independently, to be
            passionate about what they wish to do and to value excellence. With
            knowledge, compassion, confidence and cheer, children must proceed
            to make the world a better place than they found it.
          </p>
          <CustomButton
            label={`KNOW MORE`}
            className={`hover:bg-[#14A2B8] text-white bg-[#ED0C0A] duration-300 !mx-0 !p-5 !font-bold`}
          />
        </div>
        <div className="w-full lg:w-[50%] mt-5 lg:mt-0">
          <img
            src={require("../../assets/School_photos/galery7.jpg")}
            alt=""
            className="rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}

export default WellcomeBox;
