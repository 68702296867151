import React from "react";
import TcHeader from "./TcHeader";
import TcMiddleBox from "./TcMiddleBox";
import TcDescriptionTable from "./TcDescriptionTable";
import TCDecleartion from "./TCDecleartion";

function HinglishTemplate({
  componentRef,
  currentStudentAllData,
  templateLanguage,
}) {
  return (
    <div className="my-5  p-5 relative z-10" ref={componentRef}>
      <img
        src={require("../../../assets/school_logo.png")}
        alt=""
        className="absolute opacity-[0.07] left-0 right-0 top-0 bottom-0 m-auto w-[25rem] -z-10"
      />
      <div className="">
        <TcHeader
          currentStudentAllData={currentStudentAllData}
          templateLanguage={templateLanguage}
        />
      </div>
      <div className="">
        <TcMiddleBox
          currentStudentAllData={currentStudentAllData}
          templateLanguage={templateLanguage}
        />
      </div>
      <div className="">
        <TcDescriptionTable
          currentStudentAllData={currentStudentAllData}
          templateLanguage={templateLanguage}
        />
      </div>
      <div className="">
        <TCDecleartion
          currentStudentAllData={currentStudentAllData}
          templateLanguage={templateLanguage}
        />
      </div>
    </div>
  );
}

export default HinglishTemplate;
