import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Provider/AuthProvider";
import CustomButton from "../../Shared/CustomButton/CustomButton";

function Unauthorise() {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  return (
    <div className="">
      <div className="flex justify-center items-center h-screen p-5 md:p-0">
        <div className="text-center">
          <img src={require("../../assets/unauthorise.png")} alt="" />
          <p className="text-2xl font-bold text-gray-800">
            Oops! Page not found...
          </p>
          <p className="my-5 text-gray-400 text-sm">
            Looks like you're looking for something that doesn't exist.
          </p>
          <CustomButton
            label={"Go Home"}
            className={`bg-[#7499F1] text-white border-none rounded-md hover:bg-[#5e84de] !text-lg md:!text-base`}
            onClick={() => {
              navigate(`/`);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Unauthorise;
