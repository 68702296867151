import React from "react";

function TcDescriptionTable() {
  return (
    <div className="">
      <table className="w-full text-xsm  rounded ">
        <thead className="border border-black ">
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">Class</p>
              <p className="">कक्षा</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <p className="">
                Date of
                <br /> Admission
              </p>
              <p className="">प्रवेश तिथि</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="">
                Date of
                <br /> Promotion
              </p>
              <p className="">कक्षोन्नति</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="">
                Date of
                <br /> Removal
              </p>
              <p className="">निष्कासन</p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="">
                Cause of Removal e.g. non-payment
                <br /> of dues, removal of family expulsion etc.
              </p>
              <p className="">
                निष्कासन का कारण, जैसे शुल्क न देना <br /> कुटुंब का स्थानांतरण,
                निर्वासन इत्यादि
              </p>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <p className="">Year</p>
              <p className="">वर्ष</p>
            </td>
            <td className=" p-1 text-center">
              <p className="">Conduct & Wark</p>
              <p className="">आचरण तथा कार्य</p>
            </td>
          </tr>
        </thead>
        <tbody className="border border-black">
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">VI</p>
              <p className="">6</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold "
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">VII</p>
              <p className="">7</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">VIII</p>
              <p className="">8</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">IX</p>
              <p className="">9</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">X</p>
              <p className="">10</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">XI</p>
              <p className="">11</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="">XII</p>
              <p className="">12</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-0.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none text-center text-xs font-bold"
                rows={2}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TcDescriptionTable;
