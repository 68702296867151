import React, { useEffect, useState } from "react";
import { Utils, months } from "../../../../Utils/Utils";
import { ToWords } from "to-words";

function HindiTcMiddleBox({ currentStudentAllData }) {
  const [dobInWord1, setDobInWord1] = useState("");
  const [dobInWord2, setDobInWord2] = useState("");
  const [dobInWord3, setDobInWord3] = useState("");
  const toWords = new ToWords();

  useEffect(() => {
    const hasSlash =
      (currentStudentAllData?.personalDetails?.dob).includes("/");

    if (!hasSlash) {
      const date = new Date(currentStudentAllData?.personalDetails?.dob);

      setDobInWord1(toWords?.convert(date?.getDate()));
      setDobInWord2(
        months[
          parseInt(currentStudentAllData?.personalDetails?.dob.split("-")?.[1])
        ]
      );
      setDobInWord3(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("-")?.[0]
        )
      );
    } else {
      console.log(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("/")?.[2]
        )
      );
      setDobInWord1(
        toWords?.convert(
          currentStudentAllData?.personalDetails?.dob.split("/")?.[0]
        )
      );
      setDobInWord2(
        months[
          parseInt(currentStudentAllData?.personalDetails?.dob.split("/")?.[1])
        ]
      );
      setDobInWord3(
        toWords?.convert(
          (currentStudentAllData?.personalDetails?.dob).split("/")?.[2]
        )
      );
    }
    console.log("Slash Data", hasSlash);
  }, [toWords]);

  return (
    <div className=" w-full ">
      <div className="flex flex-col items-center w-full font-bold text-lg  my-5">
        <p className="">छात्र पंजी एवं स्थानांतरण प्रमाण - पत्र </p>
      </div>
      <table className="w-full text-xsm  rounded">
        <thead className="border-[0.5px] border-black bg-gray-700/20">
          <tr className=" text-gray-700">
            <td className=" p-1 text-center">
              <p className="">
                छात्र का नाम, जाति सहित,
                <br /> यदि हिंदू है अथवा धर्म
              </p>
            </td>
            <td className=" p-1 text-center border-x-[0.5px] border-black">
              <p className="">माता का नाम</p>
            </td>
            <td className=" p-1 text-center border-x-[0.5px] border-black">
              <p className="">
                पिता अथवा संरक्षक का नाम,
                <br /> व्यवसाय तथा पूरा पता
              </p>
            </td>
            <td className=" p-1 text-center">
              <p className="">छात्र की जन्मतिथि</p>
            </td>
          </tr>
        </thead>
        <tbody className=" border-[0.5px] border-black">
          <tr className=" text-gray-700">
            <td className=" p-1 text-center">
              <textarea
                value={`${currentStudentAllData?.personalDetails?.firstName} ${currentStudentAllData?.personalDetails?.lastName}`}
                className="w-full  h-full bg-transparent  focus:outline-none text-center rounded resize-none"
                rows={2}
                disabled
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x-[0.5px] border-black">
              <textarea
                value={currentStudentAllData?.personalDetails?.motherName}
                className="w-full  h-full bg-transparent  focus:outline-none text-center rounded resize-none"
                rows={2}
                disabled
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x-[0.5px] border-black">
              <textarea
                value={`${currentStudentAllData?.personalDetails?.fatherName},(${currentStudentAllData?.otherDetails?.fathersOccupaton})(${currentStudentAllData?.addressDetails?.houseNumber},${currentStudentAllData?.addressDetails?.addressLine1},${currentStudentAllData?.addressDetails?.addressLine2},${currentStudentAllData?.addressDetails?.city},${currentStudentAllData?.addressDetails?.state}${currentStudentAllData?.addressDetails?.pincode})`}
                className="w-full  h-full bg-transparent  focus:outline-none text-center rounded resize-none"
                rows={3}
                disabled
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                value={Utils.formatDate(
                  currentStudentAllData?.personalDetails?.dob
                )}
                className="w-full  h-full bg-transparent  focus:outline-none text-center rounded resize-none"
                rows={2}
                disabled
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <div className=" text-xsm ">
        <div className="flex items-center gap-4 border-b border-black py-2 ">
          <p className=" capitalize w-[20rem]">
            <span>जन्म तिथि (शब्दों में)</span>
          </p>
          <input
            disabled
            value={`${dobInWord1} ${dobInWord2} ${dobInWord3}`}
            type="text"
            className=" bg-transparent w-full   focus:outline-none"
          />
        </div>

        <div className="flex items-center gap-4 border-b border-black py-2 ">
          <div className="flex items-center w-[50%]">
            <p className=" capitalize w-[20rem]">
              <span>आधार नंबर</span>
            </p>
            <input
              disabled
              value={currentStudentAllData?.personalDetails?.adharNumber}
              type="text"
              className=" bg-transparent w-full   focus:outline-none "
            />
          </div>{" "}
          <div className="flex items-center w-[50%]">
            <p className=" capitalize w-[20rem]">
              <span>PEN No.</span>
            </p>
            <input
              disabled
              value={currentStudentAllData?.penNumber}
              type="text"
              className=" bg-transparent w-full   focus:outline-none "
            />
          </div>
        </div>
        <div className="flex items-end gap-4  py-2 ">
          <p className=" capitalize w-[21rem]">
            <span>अंतिम संस्थान जहाँ आपने अध्ययन किया</span>
          </p>
          <input
            disabled
            value={`${currentStudentAllData?.educationDetails?.previousSchool} `}
            type="text"
            className=" bg-transparent w-full   focus:outline-none "
          />
        </div>
      </div>
    </div>
  );
}

export default HindiTcMiddleBox;
