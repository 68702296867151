import React, { useContext, useEffect, useRef, useState } from "react";
import { VscAccount } from "react-icons/vsc";
import StudentFullInfoInTable from "./StudentFullInfoInTable";
import {
  useGetAllStudentByMediumTypeQuery,
  useGetAllStudentsByClassSectionAcademicYearQuery,
} from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import FeeSummayReport from "../../FeeSummayReport/FeeSummayReport";
import { HiDotsHorizontal } from "react-icons/hi";
import { AuthContext } from "../../../Provider/AuthProvider";

function StudentTable({ studentClass, academicYear, studentSection }) {
  let popupRef = useRef();
  const [showStudentFullInfoBox, setShowStudentFullInfoBox] = useState(false);
  const [showStudentFeesInfoBox, setShowStudentFeesInfoBox] = useState(false);
  const [studentAllData, setStudentAllData] = useState([]);
  const [showTableMenu, setShowTableMenu] = useState("");
  const [currentStudentFullInfoObject, setCurrentStudentFullInfoObject] =
    useState();

  const { userData } = useContext(AuthContext);

  const {
    data: getAllStudents,
    refetch: getAllStudentsRefetch,
    isLoading: getAllStudentsLoading,
  } = useGetAllStudentByMediumTypeQuery(
    {
      academicYear: academicYear,
      medium: userData?.medium,
    },
    {
      skip: !academicYear || studentClass !== "" || studentSection !== "",
    }
  );
  const {
    data: getAllStudentsByClass,
    refetch: getAllStudentsByClassRefetch,
    isLoading: getAllStudentsByClassLoading,
  } = useGetAllStudentsByClassSectionAcademicYearQuery(
    {
      academicYear: academicYear,
      medium: userData?.medium,
      studentClass: studentClass,
      studentSection: studentSection,
    },
    {
      skip:
        !academicYear ||
        academicYear === "" ||
        !studentClass ||
        !studentSection ||
        studentClass === "" ||
        studentSection === "",
    }
  );

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!popupRef?.current?.contains(e?.target)) {
  //       setShowTableMenu(-1);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  useEffect(() => {
    getAllStudentsRefetch();

    if (getAllStudents) {
      setStudentAllData(getAllStudents?.students);
    }
  }, [getAllStudents]);

  useEffect(() => {
    if (getAllStudentsByClass) {
      getAllStudentsByClassRefetch();
      setStudentAllData(getAllStudentsByClass?.students);
    }
  }, [getAllStudentsByClass]);

  const handleShowTableMenu = (index, studentData) => {
    // console.log(index);
    if (index === index) {
      setShowTableMenu(index);
      setCurrentStudentFullInfoObject(studentData);
    }
    if (showTableMenu === index) {
      setShowTableMenu(-1);
    }
  };
  const handleTableClick = (index, studentData) => {
    // console.log(index);
    if (index === index) {
      // setShowTableMenu(index);
      setCurrentStudentFullInfoObject(studentData);
      setShowStudentFullInfoBox(true);
    }
    if (showTableMenu === index) {
      setShowTableMenu(-1);
    }
  };

  return (
    <div className="">
      {(getAllStudentsLoading || getAllStudentsByClassLoading) && (
        <CircleLoader />
      )}
      {studentAllData?.length > 0 ? (
        <div className="flex flex-col  text-sm bg-white rounded-md ">
          <table className="w-full text-sm bg-white rounded">
            <thead className="sticky top-0 bg-white ">
              <tr className="shadow text-gray-700">
                <th className=" p-3 text-center">S. No.</th>
                {/* <th className=" p-3 text-center">Photo</th> */}
                <th className=" p-3 text-center">Scholar Number</th>
                <th className=" p-3 text-center">Scholar Name</th>
                <th className=" p-3 text-center">Father Name</th>
                <th className=" p-3 text-center">Class</th>
                <th className=" p-3 text-center">Whatsapp No</th>
                {/* <th className=" p-3 text-center">Mobile No</th> */}
                <th className=" p-3 text-center">Due Balance</th>
              </tr>
            </thead>
            <tbody>
              {studentAllData.map((studentData, index) => (
                <tr
                  key={index}
                  className="shadow text-gray-500 cursor-pointer hover:bg-gray-400/10"
                  onClick={() => handleTableClick(index, studentData)}
                  // onClick={() => setShowStudentFullInfoBox(true)}
                >
                  <td className=" p-3 text-center ">{index + 1}</td>
                  {/* <td className=" p-3 text-center ">
                    <VscAccount className=" text-2xl text-gray-400" />
                  </td> */}
                  <td className=" p-3 text-center capitalize">
                    {studentData?.scholarNumber}
                  </td>
                  <td className=" p-3 text-center capitalize">
                    {studentData?.personalDetails?.firstName}{" "}
                    {studentData?.personalDetails?.lastName}
                  </td>
                  <td className=" p-3 text-center capitalize">
                    {studentData?.personalDetails?.fatherName}
                  </td>
                  <td className=" p-3 text-center ">{studentData?.class}</td>
                  <td className=" p-3 text-center ">
                    +91{" "}
                    {studentData?.personalDetails?.whatsAppNumber
                      ? studentData?.personalDetails?.whatsAppNumber
                      : "NA"}
                  </td>
                  {/* <td className=" p-3 text-center ">
                    +91 {studentData?.personalDetails?.phone}
                  </td> */}
                  <td
                    className={` p-3 text-center ${
                      studentData?.otherDetails?.balanceDue === 0
                        ? ""
                        : "text-red-500"
                    }
                   `}
                  >
                    ₹ {studentData?.otherDetails?.balanceDue}
                  </td>
                  {false && (
                    <td className=" p-1 text-start relative">
                      <HiDotsHorizontal
                        className="text-xl"
                        onClick={() => handleShowTableMenu(index, studentData)}
                      />
                      {showTableMenu === index && (
                        <div className="absolute right-0 bg-gray-200 border py-1 rounded-lg z-10 shadow-lg animate-zoomInPopup">
                          <p
                            className="min-w-[15rem]   hover:bg-[#f3f4f5] cursor-pointer px-3 py-2 hover:text-[#2B60EC]"
                            onClick={() => {
                              setShowStudentFullInfoBox(true);
                              setShowTableMenu(-1);
                            }}
                          >
                            Student information
                          </p>
                          <p
                            className="min-w-[15rem] hover:bg-[#f3f4f5] cursor-pointer px-3 py-2 hover:text-[#2B60EC]"
                            onClick={() => {
                              setShowStudentFeesInfoBox(true);
                              setShowTableMenu(-1);
                            }}
                          >
                            Fees information
                          </p>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {showStudentFullInfoBox && (
            <StudentFullInfoInTable
              setShowStudentFullInfoBox={setShowStudentFullInfoBox}
              rightPopup={`animate-rightPopup`}
              currentStudentFullInfoObject={currentStudentFullInfoObject}
            />
          )}
          {/* {showStudentFeesInfoBox && (
            <FeeSummayReport
              setShowStudentFeesInfoBox={setShowStudentFeesInfoBox}
              currentStudentFullInfoObject={currentStudentFullInfoObject}
              rightPopup={`animate-rightPopup`}
              getAllStudentsRefetch={getAllStudentsRefetch}
            />
          )} */}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-xl  ">
          <p className=" p-10 rounded-xl border-red-500 text-red-500">
            No Data Found
          </p>
        </div>
      )}
    </div>
  );
}

export default StudentTable;
