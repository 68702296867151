import React, { useContext, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import {
  useFeeReminderMutation,
  useGetStudentByScholarNumberQuery,
} from "../../../Redux/ReduxToolkit";
import { Utils } from "../../../Utils/Utils";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { RiEditFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  BsFileEarmarkArrowUpFill,
  BsFillSendArrowUpFill,
} from "react-icons/bs";
import UploadStudentDocument from "../UploadStudentDocument/UploadStudentDocument";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { API_CONSTANT } from "../../../ApiConstant/ApiConstant";
import FeeSummayReport from "../../FeeSummayReport/FeeSummayReport";
import FeeSummayReportHindi from "../../FeeSummayReport/FeeSummayReportHindi";
import { AuthContext } from "../../../Provider/AuthProvider";

function StudentFullInfoInTable({
  currentStudentFullInfoObject,
  setShowStudentFullInfoBox,
  rightPopup,
}) {
  const { userData } = useContext(AuthContext);

  const date = new Date();
  const navigate = useNavigate();
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [promoteIsLoading, setPromoteIsLoading] = useState(false);
  const [showStudentFeesInfoBox, setShowStudentFeesInfoBox] = useState(false);

  const [feeReminder, { isLoading: feeReminderIsLoading, isSuccess }] =
    useFeeReminderMutation();

  const {
    data: getStudentApiData,
    isLoading: getStudentApiDataLoading,
    refetch: getStudentApiDataRefetch,
  } = useGetStudentByScholarNumberQuery(
    currentStudentFullInfoObject?.scholarNumber
  );

  // console.log(
  //   "asd",
  //   getStudentApiData?.student?.personalDetails?.whatsAppNumber
  // );

  const handleFeeReminder = async () => {
    const body = {
      campaignName: "FEE REMINDER",
      destination: `91${getStudentApiData?.student?.personalDetails?.whatsAppNumber}`,
      userName: `${
        getStudentApiData?.student?.personalDetails?.firstName
          .charAt(0)
          .toUpperCase() +
        getStudentApiData?.student?.personalDetails?.firstName.slice(1)
      } ${
        getStudentApiData?.student?.personalDetails?.lastName
          .charAt(0)
          .toUpperCase() +
        getStudentApiData?.student?.personalDetails?.lastName.slice(1)
      }`,
      templateParams: [
        `${getStudentApiData?.student?.personalDetails?.firstName} ${getStudentApiData?.student?.personalDetails?.lastName}`,
        `${getStudentApiData?.student?.class}, ${getStudentApiData?.student?.section},  with outstanding ₹ ${currentStudentFullInfoObject?.otherDetails?.balanceDue}`,
        `${Utils?.formatDate(date)}`,
      ],
    };

    try {
      const response = await feeReminder({
        body: body,
      });
      if (response) {
        console.log(response?.data?.data);
        if (response?.data?.data?.success === "true") {
          enqueueSnackbar("The message was sent successfully 👍🏻", {
            autoHideDuration: 3000,
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        }
      } else {
        enqueueSnackbar("message not senty 📵", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  const handlePromoteStudent = async () => {
    setPromoteIsLoading(true);
    try {
      const { data } = await axios.put(
        `${API_CONSTANT.BASE_URL_PROD}/student/promoteStudent/${currentStudentFullInfoObject?.scholarNumber}`
      );
      // console.log("data", data);
      getStudentApiDataRefetch();
      setPromoteIsLoading(false);
    } catch (error) {
      // console.log("Error");
      setPromoteIsLoading(false);
    }
  };

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0   ">
      {(getStudentApiDataLoading ||
        feeReminderIsLoading ||
        promoteIsLoading) && <CircleLoader />}
      <div
        className={`bg-white  min-w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${rightPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <div className="flex items-center gap-2">
            <VscAccount className=" text-2xl text-gray-400" />
            <p className="text-xl font-semibold text-[#2B60EC] capitalize">
              {`${getStudentApiData?.student?.personalDetails?.firstName} ${getStudentApiData?.student?.personalDetails?.lastName}`}
            </p>
          </div>

          <IoCloseSharp
            className={` text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300 `}
            onClick={() => setShowStudentFullInfoBox(false)}
          />
        </div>

        <div className="w-full flex justify-end  mt-5">
          {getStudentApiData?.student?.otherDetails?.balanceDue !== 0 && (
            <CustomButton
              hasIcon={true}
              label={`Fee Reminder`}
              className={`bg-red-500 border-none text-white  `}
              onClick={() => handleFeeReminder()}
            >
              <BsFillSendArrowUpFill />
            </CustomButton>
          )}
          <CustomButton
            hasIcon={true}
            label={` Upload Document`}
            className={`border-orange-400  text-orange-400 hover:bg-orange-400 hover:text-white !mx-0`}
            onClick={() => {
              setShowUploadPopup(true);
            }}
          >
            <BsFileEarmarkArrowUpFill />
          </CustomButton>
          <CustomButton
            hasIcon={true}
            label={`Edit`}
            className={`bg-orange-400 border-none text-white `}
            onClick={() =>
              navigate(`/${userData?.medium}/update-studentInfo`, {
                state: {
                  scholarNumber: currentStudentFullInfoObject?.scholarNumber,
                },
              })
            }
          >
            <RiEditFill />
          </CustomButton>
        </div>
        <div className=" mt-5 w-full flex justify-end  ">
          <CustomButton
            hasIcon={true}
            label={`Promote Student`}
            className={`bg-[#2B60EC] hover:bg-[#4867bd] border-none text-white  self-end !me-0 `}
            onClick={() => handlePromoteStudent()}
          >
            <BsFillSendArrowUpFill />
          </CustomButton>
          <CustomButton
            hasIcon={true}
            label={`Submit Fee`}
            className={`bg-green-600 hover:bg-green-700 border-none text-white  self-end  `}
            onClick={() => setShowStudentFeesInfoBox(true)}
          >
            <BsFillSendArrowUpFill />
          </CustomButton>
        </div>

        <div className="p-10">
          {getStudentApiData?.student?.otherDetails?.balanceDue !== 0 && (
            <div className="flex justify-between mb-3 text-red-500 font-semibold border border-red-500 p-2 rounded-md">
              <p className=" ">Previous Due Balance</p>
              <p className="">
                ₹ {getStudentApiData?.student?.otherDetails?.balanceDue}
              </p>
            </div>
          )}
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Scholar Number</p>
            <p className="text-gray-500">
              {getStudentApiData?.student?.scholarNumber}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Admission Date</p>
            <p className="text-gray-500">
              {Utils?.formatDate(getStudentApiData?.student?.dateofAdmission)}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Scholar Full Name</p>
            <p className="text-gray-500 capitalize flex items-center gap-1">
              <span>
                {getStudentApiData?.student?.personalDetails?.firstName}
              </span>
              <span>
                {getStudentApiData?.student?.personalDetails?.lastName}
              </span>
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Father Name</p>
            <p className="text-gray-500 capitalize">
              {getStudentApiData?.student?.personalDetails?.fatherName}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Mother Name</p>
            <p className="text-gray-500 capitalize">
              {getStudentApiData?.student?.personalDetails?.motherName}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Date of birth</p>
            <p className="text-gray-500 capitalize">
              {Utils.formatDate(
                getStudentApiData?.student?.personalDetails?.dob
              )}

              {/* {getStudentApiData?.student?.personalDetails?.dob} */}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Email Id</p>
            <p className="text-gray-500">
              {getStudentApiData?.student?.personalDetails?.email}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Aadhar No</p>
            <p className="text-gray-500">
              {getStudentApiData?.student?.personalDetails?.adharNumber}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Mobile No</p>
            <p className="text-gray-500">
              +91 {getStudentApiData?.student?.personalDetails?.phone}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]"> Class</p>
            <p className="text-gray-500">{getStudentApiData?.student?.class}</p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-[#313639]">Section</p>
            <p className="text-gray-500">
              {getStudentApiData?.student?.section}
            </p>
          </div>
        </div>
      </div>
      {showUploadPopup && (
        <UploadStudentDocument
          setShowUploadPopup={setShowUploadPopup}
          studentData={getStudentApiData?.student}
          rightPopup={`animate-rightPopup`}
        />
      )}
      {showStudentFeesInfoBox && userData?.medium === "ENGLISH" && (
        <FeeSummayReport
          setShowStudentFeesInfoBox={setShowStudentFeesInfoBox}
          currentStudentFullInfoObject={currentStudentFullInfoObject}
          rightPopup={`animate-bottomPopup`}
          getAllStudentsRefetch={getStudentApiDataRefetch}
        />
      )}
      {showStudentFeesInfoBox && userData?.medium === "HINDI" && (
        <FeeSummayReportHindi
          setShowStudentFeesInfoBox={setShowStudentFeesInfoBox}
          currentStudentFullInfoObject={currentStudentFullInfoObject}
          rightPopup={`animate-bottomPopup`}
          getAllStudentsRefetch={getStudentApiDataRefetch}
        />
      )}
    </div>
  );
}

export default StudentFullInfoInTable;
