import React, { useEffect } from "react";
import CircleLoader from "../../AllLoaders/CircleLoader";
import { useGetExpenseRangeQuery } from "../../Redux/ReduxToolkit";
import { Utils } from "../../Utils/Utils";

function ExpenseRangeTable({ expenceTableData }) {
  // const {
  //   data: getExpenceTable,
  //   isLoading: getExpenceTableIsLoading,
  //   refetch: getExpenceTableRefetch,
  // } = useGetExpenseRangeQuery(
  //   {
  //     // startDate: startDate,
  //     // endDate: endDate,
  //     startDate: Utils?.formateDateForApiRequest(startDate),
  //     endDate: Utils?.formateDateForApiRequestNextDayDate(endDate),
  //   },
  //   { skip: !startDate || !endDate }
  // );

  //   console.log(expenceTableData);

  // useEffect(() => {
  //   getExpenceTableRefetch();
  // }, [getExpenceTable]);

  return (
    <div>
      {expenceTableData?.length > 0 ? (
        <table className="w-full text-sm bg-white rounded">
          <thead>
            <tr className="shadow text-gray-700">
              <th className="p-3 text-center">S.No.</th>
              <th className="p-3 text-center">Expense Date</th>
              <th className="p-3 text-center">Expense Name</th>
              <th className="p-3 text-center">Expense Amount</th>
              <th className="p-3 text-center">Expense Category Name</th>
              <th className="p-3 text-center">Expense Category Description</th>
            </tr>
          </thead>
          <tbody>
            {expenceTableData?.map((expensesData, index) => (
              <tr
                className="text-gray-400 shadow cursor-pointer hover:bg-gray-400/10 text-xs"
                key={index}
              >
                <td className="p-3 text-center">{index + 1}</td>
                <td className="p-3 text-center">
                  {Utils?.formatDate(expensesData?.expenseDate)}
                </td>
                <td className="p-3 text-center">{expensesData?.expenseName}</td>
                <td className="p-3 text-center">
                  ₹ {expensesData?.expenseAmount}
                </td>
                <td className="p-3 text-center">
                  {expensesData?.expenseCategory?.categoryName}
                </td>
                <td className="p-3 text-center">
                  {expensesData?.expenseCategory?.categoryDescription}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="w-full text-center text-xl">
          This date is not any expenses
        </div>
      )}
    </div>
  );
}

export default ExpenseRangeTable;
