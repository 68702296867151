import React from "react";
import { IoCloseCircle } from "react-icons/io5";

function ShowFullImagePopup({
  rightPopup,
  showFullPhotoPopup,
  setShowFullPhotoPopup,
  setArrayIndex,
}) {
  return (
    <div className="bg-gray-300/50 w-full h-[100vh] fixed top-0 left-0 flex justify-end z-50">
      <div className={`${rightPopup} w-[80%] bg-white`}>
        <IoCloseCircle
          className={`absolute right-5 top-3 text-4xl cursor-pointer hover:rotate-90 duration-200 `}
          onClick={() => {
            setShowFullPhotoPopup({ index: null, imagePath: "" });
            setArrayIndex(-1);
          }}
        />
        <div className="flex items-center justify-center h-[100vh]">
          <img
            src={showFullPhotoPopup?.imagePath}
            alt=""
            className="h-[90vh] w-[90%] rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
}

export default ShowFullImagePopup;
