import React, { useEffect } from "react";
import ContentAriea from "../ContentAriea/ContentAriea";
import MenuSidebar from "../MenuSidebar/MenuSidebar";
import MobileMenuSidebar from "../MenuSidebar/MobileMenuSidebar";

function ErpApp() {
  useEffect(() => {
    const url = new URL(window.location.href);
    const role = url.searchParams.get("role");
    // console.log(role);
  }, []);

  return (
    <div className=" w-full h-[100vh] flex justify-between ">
      <div className=" hidden lg:block">
        <MenuSidebar />
      </div>{" "}
      <div className="block lg:hidden">
        <MobileMenuSidebar />
      </div>
      <ContentAriea />
    </div>
  );
}

export default ErpApp;
