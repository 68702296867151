import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { FaBus, FaComputer, FaUsers } from "react-icons/fa6";
import { TbCertificate, TbReport } from "react-icons/tb";
import { PiStudent, PiStudentFill } from "react-icons/pi";
import { LuCalendarDays } from "react-icons/lu";

import { MdDashboard, MdOutlineAccountBalance } from "react-icons/md";

import CustomButton from "../Shared/CustomButton/CustomButton";
import { AuthContext } from "../Provider/AuthProvider";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";

function MobileMenuSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, userData } = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const roleSchool = [
    {
      title: "Dashboard",
      link: `/${userData?.medium}`,
      icon: <MdDashboard />,
    },
    {
      title: "Student Management",
      link: `/${userData?.medium}/student`,
      icon: <PiStudentFill className="text-2xl" />,
    },
    {
      title: "Staff Management",
      link: `/${userData?.medium}/teacher`,
      icon: <FaUsers className="text-2xl" />,
    },

    {
      title: "Transfer & Migration ",
      link: `/${userData?.medium}/tc-migration`,
      icon: <TbCertificate className="text-2xl" />,
    },

    {
      title: "Time Table & Holiday ",
      link: `/${userData?.medium}/time-table`,
      icon: <LuCalendarDays className="text-2xl" />,
    },
    {
      title: "Transport Management   ",
      link: `/${userData?.medium}/transport`,
      icon: <FaBus className="text-2xl" />,
    },
    {
      title: "Expense Management",
      link: `/${userData?.medium}/expense`,
      icon: <MdOutlineAccountBalance className="text-2xl" />,
    },
    // {
    //   title: "SkillUp Tech",
    //   link: `/${userData?.medium}/`,
    //   icon: <FaComputer className="text-2xl" />,
    // },
    // {
    //   title: "Academic Management  ",
    //   link: "#",
    //   icon: <BiSolidSchool className="text-2xl" />,
    // },

    // {
    //   title: "Results & Exam Management ",
    //   link: "#",
    //   icon: <PiNotebookDuotone className="text-2xl" />,
    // },
    // {
    //   title: "Fee Management / Fee History ",
    //   link: "#",
    //   icon: <IoReceiptOutline className="text-2xl" />,
    // },
    // {
    //   title: "Payroll ",
    //   link: "#",
    //   icon: <MdOutlinePayment className="text-2xl" />,
    // },
  ];
  const roleTeacher = [
    {
      title: "My Profile",
      link: "/staff-profile",
      link: "/",
      icon: <FaUsers className="text-2xl" />,
    },
    {
      title: "My Students",
      link: "/my-students",
      icon: <PiStudent className="text-2xl" />,
    },
    {
      title: "ClassAttendanceReport",
      link: "/attendance-report",
      icon: <TbReport className="text-2xl" />,
    },
  ];
  return (
    <div className="w-full">
      <div className=" w-full  flex items-center justify-end px-5 p-2 z-50   absolute top-0 left-0">
        {!showMenu ? (
          <RxHamburgerMenu
            onClick={() => setShowMenu(true)}
            className="text-3xl "
          />
        ) : (
          <IoMdClose onClick={() => setShowMenu(false)} className="text-3xl " />
        )}
      </div>
      {showMenu && (
        <div className="h-full  bg-[#2B60EC] w-full md:w-[50%] flex flex-col items-start pt-10 absolute top-0 left-0 z-50">
          <div className="flex   items-center px-6 tablet:px-1  mb-5 gap-3">
            <IoIosArrowBack
              onClick={() => setShowMenu(false)}
              className={`text-white text-2xl absolute top-5 right-5`}
            />
            <img
              src={require("../assets/school_logo.png")}
              alt=""
              className="w-[3rem]"
            />
            <p className="text-white  text-xl ">MLMIC</p>
          </div>
          {userRole === "TEACHER" ? (
            <ul className="flex flex-col text-left text-white lg:mt-10">
              {roleTeacher.map((item, index) => (
                <li
                  className={`flex w-full   mt-1  text-sm hover:border-l-4 hover:border-white ${
                    location.pathname === item?.link
                      ? "border-l-4 border-white bg-gradient-to-r from-[#9ab0eb] to-[#2B60EC]"
                      : " border-l-4 border-transparent"
                  }`}
                  key={index}
                  onClick={() => {
                    navigate(item?.link);
                    setShowMenu(false);
                  }}
                >
                  <p
                    className={`flex items-center  h-full w-full px-6 ps-4 py-3 hover:cursor-pointer hover:bg-gradient-to-r  hover:from-[#9ab0eb] hover:to-[#2B60EC] tablet:px-1 tablet:text-sm `}
                  >
                    <span className="me-3 text-xl">{item?.icon}</span>{" "}
                    {item.title}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="flex flex-col text-left text-white ">
              {/* {roleSchool.map((item, index) => (
                <li
                  className={`flex w-full   mt-1  text-sm hover:border-l-4 hover:border-white ${
                    location.pathname === item?.link
                      ? "border-l-4 border-white "
                      : " border-l-4 border-transparent"
                  }`}
                  key={index}
                >
                  <Link
                    to={item.link}
                    className={`flex items-center  h-full w-full px-6 ps-2 py-2.5 hover:cursor-pointer hover:bg-gradient-to-r  hover:from-[#9ab0eb] hover:to-[#2B60EC] tablet:px-1 tablet:text-sm `}
                  >
                    <span className="me-3 text-xl">{item?.icon}</span>{" "}
                    {item.title}
                  </Link>
                </li>
              ))} */}
              Work is Under Processing
            </ul>
          )}
          <div className="flex justify-center mt-10 absolute bottom-24 right-0 left-0">
            <CustomButton
              label={`Logout`}
              className={`bg-white border-none !rounded-full py-3 !px-10 !font-normal !mx-0`}
              onClick={() => {
                // localStorage.clear();
                // navigate("/");
                logout();
              }}
            />
          </div>

          <Link
            to="https://www.phynlabz.com"
            className="text-white font-normal text-xs bottom-10 w-full text-center absolute"
          >
            Powered by &copy;PhynLabz <br />
            <span className="text-gray-300 mt-2 text-xsm">
              Version 1.0.0 alpha
            </span>
          </Link>
        </div>
      )}
    </div>
  );
}

export default MobileMenuSidebar;
