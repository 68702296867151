import React, { useContext, useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import MLMIC_AllClassFeeStructureJson from "../../../StringConstant/MLMICFeeStructure.json";
import InputFieldWithBorder from "../../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { useNavigate, useSubmit } from "react-router-dom";
import CustomAlertPopup from "../../../Shared/CustomAlertPopup/CustomAlertPopup";
import {
  useApplyRelextionMutation,
  useGetPreviousBalanceByScholarIdQuery,
  useSubmitPaymentMutation,
} from "../../../Redux/ReduxToolkit";
import { Utils } from "../../../Utils/Utils";
import { enqueueSnackbar } from "notistack";
import TextareaWithBorder from "../../../Shared/TextareaWithBorder/TextareaWithBorder";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { AuthContext } from "../../../Provider/AuthProvider";
function FeeSubmissionHindi({
  bottomPopup,
  setshowFeeSubmitPopup,
  currentStudentFullInfoObject,
  getPaymentByScholarIdRefetch,
  previousDueBalance,
  getAllStudentsRefetch,
}) {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [checkCurrentMonth, setCheckCurrentMonth] = useState(false);
  const [admissionCheckbox, setAdmissionCheckbox] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedFees, setSelectedFees] = useState();
  const [studentFullYearFeeStructure, setStudentFullYearFeeStructure] =
    useState([]);
  const [academicYear, setAcademicYear] = useState(
    Utils?.generateAcademicYears(5)?.[0]
  );
  const [currentStudentClass, setCurrentStudentClass] = useState("");
  const [showClearBalanceWarningPopup, setShowClearBalanceWarningPopup] =
    useState(false);
  const [payAmountInputValue, setPayAmountInputValue] = useState(0);
  const [monthNumber, setMonthNumber] = useState(null);
  const [totalFees, setTotalFees] = useState(0);
  const [gTotalFees, setGTotalFees] = useState(0);
  const [showClearBalanceForm, setShowClearBalanceForm] = useState(false);
  const [clearBalanceDescription, setClearBalanceDescription] = useState("");
  const [clearBalanceAmount, setClearBalanceAmount] = useState("");
  const [computerFee, setComputerFee] = useState(50);
  const [showConveyanceDropdown, setShowConveyanceDropdown] = useState("");
  const [showExtraCurricularDropdown, setShowExtraCurricularDropdown] =
    useState("");
  const [lastMonth, setLastMonth] = useState("");

  const [totalPreviousFeeData, setTotalPreviousFeeData] = useState();

  const [submitPayment, { isLoading: submitPaymentIsLoading }] =
    useSubmitPaymentMutation();
  const [applyRelextion, { isLoading: applyRelextionIsLoading }] =
    useApplyRelextionMutation();

  const {
    data: getPreviousBalance,
    isLoading: getPreviousBalanceIsLoading,
    refetch: getPreviousBalanceRefetch,
  } = useGetPreviousBalanceByScholarIdQuery(
    currentStudentFullInfoObject?.scholarNumber
  );

  useEffect(() => {
    if (
      selectedFees ||
      getPreviousBalance ||
      getPreviousBalance !== 0 ||
      academicYear ||
      monthNumber
    ) {
      if (academicYear !== Utils?.generateAcademicYears(1)?.[0]) {
        let total = 0;
        if (showConveyanceDropdown === "van") {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0) +
            parseInt(selectedFees?.conveyance?.van || 0);
        } else if (showConveyanceDropdown === "bus") {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0) +
            parseInt(selectedFees?.conveyance?.bus || 0);
        } else {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0);
        }
        setTotalFees(total);
        const gTotal = total + parseInt(getPreviousBalance?.balanceDue);
        setGTotalFees(gTotal);
      } else {
        let total = 0;
        if (showConveyanceDropdown === "van") {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.registrationFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0) +
            parseInt(selectedFees?.academic.admissionCharges || 0) +
            parseInt(selectedFees?.conveyance?.van || 0);
        } else if (showConveyanceDropdown === "bus") {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.registrationFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0) +
            parseInt(selectedFees?.academic.admissionCharges || 0) +
            parseInt(selectedFees?.conveyance?.bus || 0);
        } else {
          total =
            parseInt(selectedFees?.academic.monthlyFee || 0) +
            parseInt(selectedFees?.academic.examFee || 0) +
            parseInt(selectedFees?.academic.registrationFee || 0) +
            parseInt(selectedFees?.academic.developmentCharges || 0) +
            parseInt(selectedFees?.academic.admissionCharges || 0);
          // admissionCheckbox
          //   ? parseInt(selectedFees?.academic.admissionCharges || 0)
          //   : parseInt(0);
        }
        if (admissionCheckbox === true) {
          setTotalFees(total);
        } else {
          const finalTotal =
            total - parseInt(selectedFees?.academic.admissionCharges || 0);
          setTotalFees(finalTotal);
        }
      }
    }
  }, [
    selectedFees,
    getPreviousBalance,
    showConveyanceDropdown,
    academicYear,
    monthNumber,
    admissionCheckbox,
  ]);

  useEffect(() => {
    const gTotal = totalFees + parseInt(getPreviousBalance?.balanceDue);
    setGTotalFees(gTotal);
  }, [totalFees, getPreviousBalance?.balanceDue]);

  useEffect(() => {
    if (currentStudentFullInfoObject.class) {
      setCurrentStudentClass(currentStudentFullInfoObject.class);
    }
  }, [currentStudentFullInfoObject]);

  useEffect(() => {
    // console.log(
    //   MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson?.PG_TO_UKG
    // );
    if (
      currentStudentClass === "PG" ||
      currentStudentClass === "LKG" ||
      currentStudentClass === "UKG"
    ) {
      setStudentFullYearFeeStructure(
        MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson
          ?.PG_TO_UKG
      );
    }
    if (
      currentStudentClass === "1st" ||
      currentStudentClass === "2nd" ||
      currentStudentClass === "3rd" ||
      currentStudentClass === "4th" ||
      currentStudentClass === "5th"
    ) {
      setStudentFullYearFeeStructure(
        MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson
          ?.ONE_TO_FIVE
      );
    }
    if (
      currentStudentClass === "6th" ||
      currentStudentClass === "7th" ||
      currentStudentClass === "8th"
    ) {
      setStudentFullYearFeeStructure(
        MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson
          ?.SIX_TO_EIGHT
      );
    }
    if (currentStudentClass === "9th" || currentStudentClass === "10th") {
      setStudentFullYearFeeStructure(
        MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson
          ?.NINE_TO_TEN
      );
    }
    if (currentStudentClass === "11th" || currentStudentClass === "12th") {
      setStudentFullYearFeeStructure(
        MLMIC_AllClassFeeStructureJson?.MLMIC_AllClassFeeStructureJson?.[
          "ELEVEN_TO_TWELVE(SCIENCE)"
        ]
      );
    }
  }, [currentStudentClass]);

  const handleMonthChange = (event, index) => {
    // console.log("j jdhbssnxjsf");
    setMonthNumber(index);
    const selectedMonth = event.target.value;
    const feesObject = studentFullYearFeeStructure?.find(
      (item) => item.month === selectedMonth
    )?.fees;
    setSelectedMonth(selectedMonth);
    //  Last Payamnet Data YAha lao

    setSelectedFees(feesObject);
  };

  // console.log("previousDueBalance", previousDueBalance);
  // console.log("selectedMonth", selectedMonth);
  console.log("monthNumber", previousDueBalance);

  useEffect(() => {
    console.log("jxns", previousDueBalance?.createdAt);
    if (previousDueBalance?.createdAt) {
      const date = new Date(previousDueBalance?.createdAt);
      setLastMonth(date.getMonth());
    } else {
      setLastMonth(2);
    }
  }, [monthNumber]);

  useEffect(() => {
    const currentMonth = new Date().getMonth();

    console.log("Mahina Nikla", {
      currentMonth,
      lastMonth: 4,
      // lastMonth: date.getMonth(),
    });
    const totalPreviousFeeData1 = calculateTotalBalance(
      2,
      // date.getMonth(),
      // currentMonth
      // 11
      monthNumber,
      getPreviousBalance?.balanceDue
    );
    setTotalPreviousFeeData(totalPreviousFeeData1);
  }, [lastMonth, selectedMonth]);

  const handlePayFees = async () => {
    if (
      payAmountInputValue === undefined ||
      payAmountInputValue === null ||
      payAmountInputValue === "" ||
      payAmountInputValue === 0
    ) {
      enqueueSnackbar("Pay amount is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (payAmountInputValue > gTotalFees) {
      enqueueSnackbar("You cannot pay more than the grade-total amount", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    const body = {
      scholarNumber: currentStudentFullInfoObject?.scholarNumber,
      month: monthNumber,
      amountPaid: parseInt(payAmountInputValue),
      totalFeeForCurrentMonth: totalFees,
      academicYear: academicYear,
    };
    try {
      const response = await submitPayment({
        body: body,
      });

      if (response.data) {
        navigate(`/${userData?.medium}/student-fee`, {
          state: {
            receiptData: selectedFees,
            gTotalFees: gTotalFees,
            totalFees: totalFees,
            month: selectedMonth,
            paidBalence: parseInt(payAmountInputValue),
            dueBalence: parseInt(gTotalFees) - parseInt(payAmountInputValue),
            currentStudentFullInfoObject: currentStudentFullInfoObject,
            allPaymentDetails: response.data,
            showConveyanceDropdown: showConveyanceDropdown,
            previousDueBalance: previousDueBalance?.currentDue,
            academicYear: academicYear,
            totalPreviousFeeData: totalPreviousFeeData,
          },
        });
        getAllStudentsRefetch();
        getPaymentByScholarIdRefetch();
        getPreviousBalanceRefetch();
      } else {
        console.log("Oops! payment not submited ! ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  const handleClearBalanceFormValidation = () => {
    if (
      clearBalanceDescription === undefined ||
      clearBalanceDescription === null ||
      clearBalanceDescription === ""
    ) {
      enqueueSnackbar("Reason is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      clearBalanceAmount === undefined ||
      clearBalanceAmount === null ||
      clearBalanceAmount === "" ||
      clearBalanceAmount === 0
    ) {
      enqueueSnackbar("Clear amount is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    setShowClearBalanceWarningPopup(true);
  };

  const handleClearBalanceApiCall = async () => {
    const body = {
      scholarNumber: currentStudentFullInfoObject?.scholarNumber,
      month: monthNumber,
      relaxationAmount: clearBalanceAmount,
      note: clearBalanceDescription,
    };
    try {
      const response = await applyRelextion({
        body: body,
      });

      if (response.data) {
        // console.log("response ", response);
        console.log(response?.data?.message);

        enqueueSnackbar(response?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });

        setShowClearBalanceForm(false);
        setShowClearBalanceWarningPopup(false);
        getPaymentByScholarIdRefetch();
        getAllStudentsRefetch();
        getPreviousBalanceRefetch();
      } else {
        console.log("Oops! payment not submited ! ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  const calculateTotalBalance = (lastMonth, currentMonth, dueBalance = 0) => {
    let totalBalace = 0;
    let balanceObj = {};
    let monthlyFee = 0;
    console.log(
      "Student Full Fee Stuructirw",
      studentFullYearFeeStructure?.[0]?.fees?.academic?.examFee || 0
    );
    for (let i = lastMonth + 1; i <= currentMonth; i++) {
      let monthlyFee = parseInt(
        studentFullYearFeeStructure[i]?.fees?.academic?.monthlyFee
      );
      const examFee = parseInt(
        studentFullYearFeeStructure[i]?.fees?.academic?.examFee || 0
      );
      const developmentCharges = parseInt(
        studentFullYearFeeStructure[i]?.fees?.academic?.developmentCharges || 0
      );
      totalBalace = totalBalace + monthlyFee + examFee + developmentCharges;
      setTotalFees(totalBalace);

      balanceObj[i] = {
        monthlyFee: monthlyFee,
        examFee: examFee,
        developmentCharges: developmentCharges,
        computerLab: 50,
      };
    }
    return {
      summary: balanceObj,
      monthlyPendingBalance: totalBalace,
      dueBalance,
      totalBalance: totalBalace + dueBalance,
    };
  };

  const handleCheckBoxCurrentMonth = () => {
    setCheckCurrentMonth(!checkCurrentMonth);

    const currentMonth = new Date().getMonth();

    console.log("currentMonth aa gya hai ", currentMonth);
  };

  // console.log("monthNumber", monthNumber);
  console.log("Total Prweviois Balacne", totalPreviousFeeData);

  // totalPreviousFeeData?.summary?.map((key) => console.log(key));

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0 z-50 ">
      {(submitPaymentIsLoading ||
        applyRelextionIsLoading ||
        getPreviousBalanceIsLoading) && <CircleLoader />}
      <div
        className={`bg-white  w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${bottomPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <p className="text-xl font-semibold text-[#2B60EC]">Fee Submission</p>
          <IoCloseSharp
            className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
            onClick={() => {
              setshowFeeSubmitPopup(false);
            }}
          />
        </div>
        <div className="h-[92vh] overflow-y-scroll no-scrollbar p-5  ">
          <p className="text-xl  text-end">
            Last fee payment month is{" "}
            <b>{Utils?.getMonthNameByMonthNumber(lastMonth)}</b>.
          </p>

          <p className="mb-2">Acadmic Year</p>
          <select
            value={academicYear}
            onChange={(e) => setAcademicYear(e.target.value)}
            className="w-full mb-5 p-2 border border-black rounded-md text-sm  "
          >
            <option value="" disabled>
              Choose class
            </option>
            {Utils?.generateAcademicYears(5)?.map((studentClassData, index) => (
              <option value={studentClassData} key={index}>
                {studentClassData}
              </option>
            ))}
          </select>
          <div className="">
            <select
              className="w-full p-2 mb-5 border border-black rounded-md text-sm"
              value={selectedMonth}
              onChange={(event) =>
                handleMonthChange(
                  event,
                  studentFullYearFeeStructure.findIndex(
                    (monthData) => monthData.month === event.target.value
                  )
                )
              }
            >
              <option value="" disabled>
                SELECT MONTH
              </option>
              {studentFullYearFeeStructure?.map((monthData, index) => (
                <option value={monthData.month} key={index}>
                  {monthData?.month}
                </option>
              ))}
            </select>
            {/* <div className="flex items-center gap-3  ">
              <input
                type="checkbox"
                checked={checkCurrentMonth}
                className=" size-5"
                onChange={() => {
                  handleCheckBoxCurrentMonth();
                }}
              />
              Current Month
            </div>
            <hr className="my-10" /> */}
          </div>
          {selectedFees?.academic && (
            <div className="">
              {/* <select
                className="w-full mb-5 p-2 border border-black rounded-md text-sm  "
                onChange={(e) => setShowConveyanceDropdown(e.target.value)}
                value={showConveyanceDropdown}
              >
                <option value="">Choose Conveyance</option>
                <option value="van">Van</option>
                <option value="bus">Bus</option>
              </select>
              <select
                className="w-full mb-5 p-2 border border-black rounded-md text-sm  "
                onChange={(e) => setShowExtraCurricularDropdown(e.target.value)}
                value={showExtraCurricularDropdown}
              >
                <option value="">Choose extra curricular</option>
                <option value="computer_lab">Computer Lab</option>
                <option value="library">Library</option>
              </select> */}
              <div className="px-5 pt-3 mb-5 text-sm ">
                {academicYear === Utils?.generateAcademicYears(1)?.[0] &&
                  selectedFees?.academic?.admissionCharges && (
                    <div className="flex  mb-3 gap-3">
                      <input
                        checked={admissionCheckbox}
                        type="checkbox"
                        className="size-5"
                        onChange={() =>
                          setAdmissionCheckbox(!admissionCheckbox)
                        }
                      />
                      <div className="flex justify-between w-full">
                        <p className="font-semibold text-[#313639]">
                          Admission Charges
                        </p>
                        <p className="text-gray-500">
                          {selectedFees?.academic?.admissionCharges}
                        </p>
                      </div>
                    </div>
                  )}

                {Object.keys(totalPreviousFeeData?.summary || {}).map((key) => (
                  <div className="flex flex-col  mb-3 gap-3">
                    {/* <input type="checkbox" className="size-5" /> */}
                    <div className="flex justify-between w-full">
                      <p className="font-semibold text-[#313639]">
                        Monthly Fee{" "}
                        {`( ${Utils?.getMonthNameByMonthNumber(key)} )`}
                      </p>
                      <p className="text-gray-500">
                        {totalPreviousFeeData.summary[key].monthlyFee}
                      </p>
                    </div>
                    {/* <div className="flex justify-between w-full">
                      <p className="font-semibold text-[#313639]">
                        Computer Lab{" "}
                        {`( ${Utils?.getMonthNameByMonthNumber(key)} )`}
                      </p>
                      <p className="text-gray-500">
                        {totalPreviousFeeData.summary[key].computerLab}
                      </p>
                    </div> */}
                    {totalPreviousFeeData.summary[key].examFee !== 0 && (
                      <div className="flex justify-between w-full">
                        <p className="font-semibold text-[#313639]">
                          Exam Fee
                          {`( ${Utils?.getMonthNameByMonthNumber(key)} )`}
                        </p>
                        <p className="text-gray-500">
                          {totalPreviousFeeData.summary[key].examFee}
                        </p>
                      </div>
                    )}
                    {totalPreviousFeeData.summary[key].developmentCharges !==
                      0 && (
                      <div className="flex justify-between w-full">
                        <p className="font-semibold text-[#313639]">
                          Development Charges
                          {`( ${Utils?.getMonthNameByMonthNumber(key)} )`}
                        </p>
                        <p className="text-gray-500">
                          {totalPreviousFeeData.summary[key].developmentCharges}
                        </p>
                      </div>
                    )}
                  </div>
                ))}

                {/* <div className="flex  mb-3 gap-3">
                  <div className="flex justify-between w-full">
                    <p className="font-semibold text-[#313639]">Monthly Fee</p>
                    <p className="text-gray-500">
                      {selectedFees?.academic?.monthlyFee}
                    </p>
                  </div>
                </div> */}

                {/* {selectedFees?.academic?.developmentCharges && ( */}
                {/* <div className="flex  mb-3 gap-3">
                    <input type="checkbox" className="size-5" />
                    <div className="flex justify-between w-full">
                      <p className="font-semibold text-[#313639]">
                        Development Charges
                      </p>
                      <p className="text-gray-500">
                        {selectedFees?.academic?.developmentCharges}
                      </p>
                    </div>
                  </div> */}
                {/* )} */}
                {academicYear === Utils?.generateAcademicYears(1)?.[0] &&
                  selectedFees?.academic?.registrationFee && (
                    <div className="flex  mb-3 gap-3">
                      {/* <input type="checkbox" className="size-5" /> */}
                      <div className="flex justify-between w-full">
                        <p className="font-semibold text-[#313639]">
                          Registration Fee
                        </p>
                        <p className="text-gray-500">
                          {selectedFees?.academic?.registrationFee}
                        </p>
                      </div>
                    </div>
                  )}
                {selectedFees?.academic?.examFee && (
                  <div className="flex  mb-3 gap-3">
                    {/* <input type="checkbox" className="size-5" /> */}
                    <div className="flex justify-between w-full">
                      <p className="font-semibold text-[#313639]"> Exam Fee</p>
                      <p className="text-gray-500">
                        {selectedFees?.academic?.examFee}
                      </p>
                    </div>
                  </div>
                )}
                {showConveyanceDropdown === "van" &&
                  selectedFees?.isConveyance &&
                  selectedFees?.conveyance?.van && (
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-[#313639]">
                        Conveyance ( Van )
                      </p>
                      <p className="text-gray-500">
                        {selectedFees?.conveyance?.van}
                      </p>
                    </div>
                  )}
                {showConveyanceDropdown === "bus" &&
                  selectedFees?.isConveyance &&
                  selectedFees?.conveyance?.bus && (
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-[#313639]">
                        Conveyance ( Bus )
                      </p>
                      <p className="text-gray-500">
                        {selectedFees?.conveyance?.bus}
                      </p>
                    </div>
                  )}
                {showExtraCurricularDropdown === "computer_lab" &&
                  selectedFees?.extra_curricular?.computer_lab && (
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-[#313639]">
                        Computer Lab
                      </p>
                      <p className="text-gray-500">
                        {selectedFees?.extra_curricular?.computer_lab}
                      </p>
                    </div>
                  )}
                {showExtraCurricularDropdown === "library" &&
                  selectedFees?.extra_curricular?.computer_lab && (
                    <div className="flex justify-between mb-3">
                      <p className="font-semibold text-[#313639]">Library</p>
                      <p className="text-gray-500">
                        {selectedFees?.extra_curricular?.library}
                      </p>
                    </div>
                  )}
                <div className="flex justify-between mb-3 py-2 font-semibold text-[#313639] text-base border-y border-dashed border-black">
                  <p className=" ">Total</p>
                  <p className="">{totalFees}</p>
                </div>
                {(getPreviousBalance?.balanceDue ||
                  getPreviousBalance?.balanceDue > 0) && (
                  <div className="flex justify-between items-center mb-3">
                    <div className="flex items-center gap-2">
                      <p className="font-semibold text-[#313639]">
                        Previous Amount
                      </p>
                      <p
                        className={`flex items-center uppercase !text-[10px] font-light p-1 px-3 rounded bg-red-500 text-white cursor-pointer ${
                          showClearBalanceForm ? "" : ""
                        }`}
                        onClick={() => {
                          setShowClearBalanceForm(true);
                        }}
                      >
                        Clear balance
                      </p>
                    </div>
                    <p className="text-red-500 font-bold ">
                      {getPreviousBalance?.balanceDue}
                    </p>
                  </div>
                )}
                {showClearBalanceForm && (
                  <div className="p-5 shadow-lg rounded-xl mb-5">
                    <TextareaWithBorder
                      label={`Reason`}
                      value={clearBalanceDescription}
                      onChange={(e) =>
                        setClearBalanceDescription(e.target.value)
                      }
                    />
                    <InputFieldWithBorder
                      type={`number`}
                      label={`Clear amount`}
                      onChange={(e) => setClearBalanceAmount(e.target.value)}
                      value={clearBalanceAmount}
                    />
                    <CustomButton
                      label="Clear"
                      className="!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd] mt-3 "
                      onClick={() => handleClearBalanceFormValidation()}
                    />
                  </div>
                )}
                <div className="flex justify-between mb-3 py-2 font-semibold text-[#313639] text-base border-b border-dashed border-black">
                  <p className=" ">G. Total</p>
                  <p className="">{gTotalFees}</p>
                </div>
              </div>
              <div className="">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    label={`Enter Pay Amount`}
                    onChange={(e) => setPayAmountInputValue(e.target.value)}
                  />
                </div>
              </div>
              <CustomButton
                label="Pay Fees"
                className="!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]"
                onClick={() => handlePayFees()}
              />
            </div>
          )}
        </div>
      </div>

      {showClearBalanceWarningPopup && (
        <CustomAlertPopup
          title={`warning`}
          discreption={`This action cannot be undone. This will clear any past due & past history. make sure that you confirm before you initiate this action`}
          cancelBtnLabel={`Cancel`}
          cancelBtnOnClick={setShowClearBalanceWarningPopup}
          submitBtnLabel={`Ok`}
          submitBtnOnClick={handleClearBalanceApiCall}
        />
      )}
    </div>
  );
}

export default FeeSubmissionHindi;
