import { jwtDecode } from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");
    if (token !== null || token !== undefined || token !== "") {
      setAccessToken(token);
    }
    if (userData !== null || userData !== undefined) {
      setUserData(JSON.parse(userData));
    }
  }, []);

  const login = (token, data) => {
    setAccessToken(token);
    setUserData(data);
    localStorage.setItem("token", token);
    setUserInfo(jwtDecode(token)?.user);

    // console.log("decoding", jwtDecode(token)?.user);

    localStorage.setItem("userData", JSON.stringify(data));

    // window.location.href = "/home";
  };

  const logout = () => {
    setAccessToken(null);
    setUserData(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("medium");

    localStorage.clear();

    window.location.href = "/";
  };

  // console.log("jhbdd", userInfo);

  return (
    <AuthContext.Provider
      value={{ login, logout, accessToken, userInfo, userData }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
