import React from "react";
import HomePage from "./HomePage/HomePage";

function WebsitePages() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default WebsitePages;
