import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import InputFieldWithBorder from "../../../Shared/InputFieldWithBorder/InputFieldWithBorder";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { Calendar } from "primereact/calendar";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../../StringConstant/MotiLalAllConstant";
import { enqueueSnackbar } from "notistack";
import {
  useCreateTeacherMutation,
  useUpdateStaffMutation,
} from "../../../Redux/ReduxToolkit";
import { useNavigate } from "react-router-dom";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { Utils } from "../../../Utils/Utils";

function UpdateTeacher({
  setShowUpdatePopup,
  bottomPopup,
  getTableDataRefetch,
  teacherFullInfoArray,
}) {
  const medium = localStorage.getItem("medium");

  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adharNumber, setAdharNumber] = useState("");
  const [dobDate, setDobDate] = useState(null);
  const [teacherMobile, setTeacherMobile] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [homeState, setHomeState] = useState("Uttar Pradesh");
  const [pincode, setPincode] = useState("");
  const [isClassTeacher, setIsClassTeacher] = useState(false);
  const [changePasswordType, setChangePasswordType] = useState("password");
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");

  const [createTeacher, { isLoading: createTeacherIsLoading }] =
    useCreateTeacherMutation();

  const [updateStaff, { isLoading, updateStaffIsLoading }] =
    useUpdateStaffMutation();

  const handleSave = async () => {
    if (
      firstName === null ||
      firstName === undefined ||
      firstName === "" ||
      firstName.trim().length <= 2
    ) {
      enqueueSnackbar(
        "First name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (lastName === null || lastName === undefined || lastName === "") {
      enqueueSnackbar(
        "Last name is required and must be at least 3 characters long!",
        {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }
      );
      return;
    }
    if (
      adharNumber === null ||
      adharNumber === undefined ||
      adharNumber === ""
    ) {
      enqueueSnackbar("Aadhar Number is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (email === null || email === undefined || email === "") {
      enqueueSnackbar(" Email Id  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      teacherMobile === null ||
      teacherMobile === undefined ||
      teacherMobile === ""
    ) {
      enqueueSnackbar("  Mobile Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (dobDate === null || dobDate === undefined || dobDate === "") {
      enqueueSnackbar("Date of Birth  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      houseNumber === null ||
      houseNumber === undefined ||
      houseNumber === ""
    ) {
      enqueueSnackbar("House Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      houseNumber === null ||
      houseNumber === undefined ||
      houseNumber === ""
    ) {
      enqueueSnackbar("House Number  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      addressLine1 === null ||
      addressLine1 === undefined ||
      addressLine1 === ""
    ) {
      enqueueSnackbar("Address 1  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      addressLine2 === null ||
      addressLine2 === undefined ||
      addressLine2 === ""
    ) {
      enqueueSnackbar("Address 2  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (city === null || city === undefined || city === "") {
      enqueueSnackbar("City  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (homeState === null || homeState === undefined || homeState === "") {
      enqueueSnackbar("State  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (pincode === null || pincode === undefined || pincode === "") {
      enqueueSnackbar("Pin Code / Zip Code  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    const body = {
      data: {
        personalDetails: {
          dob: dobDate,
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          phone: teacherMobile,
          adharNumber: adharNumber,
        },
        addressDetails: {
          houseNumber: houseNumber,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          state: homeState,
          pinCode: pincode,
        },

        other: {
          isClassTeacher: isClassTeacher,
          classTeacherOf: studentClass,
          classTeacherSection: studentSection,
        },
        role: "TEACHER",
        medium: medium,
      },
      staffId: teacherFullInfoArray?.staffId,
    };

    try {
      const response = await updateStaff({
        body: body,
      });
      if (response.data) {
        // console.log(response?.data);
        setShowUpdatePopup(false);
        getTableDataRefetch();
      } else {
        console.log("Oops! teacher is not created ! ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  useEffect(() => {
    // console.log(
    //   Utils?.formateDateForApiRequestNextDayDate(
    //     teacherFullInfoArray?.personalDetails?.dob
    //   )
    // );
    setDobDate(
      Utils?.formatDateForRequesBody(teacherFullInfoArray?.personalDetails?.dob)
    );
    setFirstName(teacherFullInfoArray?.personalDetails?.firstName);
    setLastName(teacherFullInfoArray?.personalDetails?.lastName);
    setEmail(teacherFullInfoArray?.personalDetails?.email);
    setTeacherMobile(teacherFullInfoArray?.personalDetails?.phone);
    setAdharNumber(teacherFullInfoArray?.personalDetails?.adharNumber);
    setPassword(teacherFullInfoArray?.personalDetails?.password);

    setHouseNumber(teacherFullInfoArray?.addressDetails?.houseNumber);
    setAddressLine1(teacherFullInfoArray?.addressDetails?.addressLine1);
    setAddressLine2(teacherFullInfoArray?.addressDetails?.addressLine2);
    setCity(teacherFullInfoArray?.addressDetails?.city);
    setHomeState(teacherFullInfoArray?.addressDetails?.state);
    setPincode(teacherFullInfoArray?.addressDetails?.pinCode);
    setIsClassTeacher(teacherFullInfoArray?.other?.isClassTeacher);
    setStudentClass(teacherFullInfoArray?.other?.classTeacherOf);
    setStudentSection(teacherFullInfoArray?.other?.classTeacherSection);
  }, [teacherFullInfoArray]);

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-center items-center fixed top-0 left-0 z-50 ">
      {updateStaffIsLoading && <CircleLoader />}

      <div
        className={`bg-white  lg:w-[50rem] h-[90vh] tablet:w-[60%] rounded-2xl shadow-lg relative ${bottomPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <p className="text-xl font-semibold text-[#2B60EC]">
            Update Teacher Information Form
          </p>
          <IoCloseSharp
            className="text-3xl text cursor-pointer hover:text-gray-700 text-gray-600"
            onClick={() => setShowUpdatePopup(false)}
          />
        </div>
        <div className="h-[81vh]  overflow-scroll p-5">
          <div className="border-b border-black border-dashed mb-5">
            <p className="text-[#2B60EC] font-semibold">Personal Details</p>
            <div className="h-[2px] bg-[#2B60EC] w-[4rem] "></div>

            <div className="p-5">
              <div className="flex items-end w-full gap-5 ">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`First Name`}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Last Name`}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
              </div>
              {/* <div className="flex items-end w-full gap-5 ">
                  <div className="mb-3 w-full">
                    <InputFieldWithBorder label={`Father Name`} />
                  </div>
                  <div className="mb-3 w-full">
                    <InputFieldWithBorder label={`Mother Name`} />
                  </div>
                </div>
                 */}
              <div className="flex items-end w-full gap-5 ">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Email Id`}
                    type={`email`}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Mobile No`}
                    type={`number`}
                    onChange={(e) => setTeacherMobile(e.target.value)}
                    value={teacherMobile}
                  />
                </div>
              </div>

              <div className="flex items-end w-full gap-5 ">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Aadhar No`}
                    type={`number`}
                    onChange={(e) => setAdharNumber(e.target.value)}
                    value={adharNumber}
                  />
                </div>

                <div className="mb-3 w-full">
                  <p className={`text-[#797b7c] text-sm mb-1`}>Date of Birth</p>
                  <InputFieldWithBorder
                    inputFieldDisable
                    value={Utils?.formatDate(dobDate)}
                  />
                  {/* 
                    <Calendar
                      inputClassName="border  p-1.5 rounded-lg text-sm mb-1"
                      value={dobDate}
                      onChange={(e) => setDobDate(e.value)}
                      dateFormat="dd/mm/yy"
                      className="w-full "
                      placeholder={`DD/MM/YYYY`}
                    /> */}
                </div>
              </div>

              <div className="flex items-end w-full gap-5 ">
                <div className="mb-3 w-[49%] flex items-end gap-2 ">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Password`}
                    type={changePasswordType}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {changePasswordType === "password" && (
                    <div
                      className={`p-2  my-1 rounded-md cursor-pointer bg-[#2B60EC] text-white `}
                      onClick={() => setChangePasswordType("text")}
                    >
                      <FaRegEye />
                    </div>
                  )}

                  {changePasswordType === "text" && (
                    <div
                      className={`p-2  my-1 rounded-md cursor-pointer  bg-gray-200`}
                      onClick={() => setChangePasswordType("password")}
                    >
                      <FaRegEyeSlash />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-black border-dashed mb-5">
            <p className="text-[#2B60EC] font-semibold">Address Details</p>
            <div className="h-[2px] bg-[#2B60EC] w-[4rem] "></div>

            <div className="  p-5  ">
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Building No`}
                    value={houseNumber}
                    onChange={(e) => setHouseNumber(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Address Line 1`}
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={` Address Line 2`}
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full flex  gap-5">
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`City `}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`State`}
                    value={homeState}
                    onChange={(e) => setHomeState(e.target.value)}
                  />
                </div>
                <div className="mb-3 w-full">
                  <InputFieldWithBorder
                    hasIcon={true}
                    label={`Pin code / Zip code`}
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
          <div className=" ">
            <p className="text-[#2B60EC] font-semibold">Other Details</p>
            <div className="h-[2px] bg-[#2B60EC] w-[4rem] "></div>

            <div className="  p-5  ">
              <div className="w-full flex flex-col  gap-5">
                <div className="flex items-start gap-4">
                  <input
                    type="checkbox"
                    className="size-5 cursor-pointer"
                    onChange={() => setIsClassTeacher(!isClassTeacher)}
                    checked={isClassTeacher}
                  />
                  <p className="">Assign class teacher</p>
                </div>
                {isClassTeacher && (
                  <div className="flex items-end gap-5">
                    <div className="mb-3 w-full">
                      <select
                        value={studentClass}
                        onChange={(e) => setStudentClass(e.target.value)}
                        className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
                      >
                        <option value="" disabled>
                          Choose Class
                        </option>
                        {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                          (studentClassData, index) => (
                            <option value={studentClassData} key={index}>
                              {studentClassData}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="mb-3 w-full">
                      <select
                        value={studentSection}
                        onChange={(e) => setStudentSection(e.target.value)}
                        className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
                      >
                        <option value="" disabled>
                          Choose Section
                        </option>
                        {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
                          (studentSectionData, index) => (
                            <option value={studentSectionData} key={index}>
                              {studentSectionData}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <CustomButton
              label={`Cancel`}
              className={`!mx-0 text-white border-none bg-[#f5864a] hover:bg-[#f98f57]`}
              onClick={() => setShowUpdatePopup(false)}
            />
            <CustomButton
              label={`Save`}
              className={` text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateTeacher;
