import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import img1 from "../../assets/School_photos/galery10.jpg";
import img4 from "../../assets/School_photos/galery9.jpg";
import img2 from "../../assets/School_photos/galery1.jpg";
import img3 from "../../assets/School_photos/galery12.jpg";
import img5 from "../../assets/School_photos/galery9.jpg";

function PrincipleBoxCarousel() {
  const imageUrls = [
    {
      url: img1,
    },
    {
      url: img2,
    },
    {
      url: img3,
    },
    {
      url: img4,
    },
    {
      url: img5,
    },
  ];
  return (
    <div className="bg-red-400">
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        dynamicHeight={true}
        autoPlay={true}
      >
        {imageUrls?.map((image, index) => (
          <div className="">
            <img key={index} className="  h-[30rem]   " src={image?.url} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default PrincipleBoxCarousel;
