import React from "react";
import EnglishTcHeader from "./EnglishTcHeader";
import EnglishTcMiddleBox from "./EnglishTcMiddleBox";
import EnglishTcDscriptionTable from "./EnglishTcDscriptionTable";
import EnglishTcDecleartion from "./EnglishTcDecleartion";

function TcEnglishTemplate({
  componentRef,
  currentStudentAllData,
}) {
  return (
    <div className="my-5  p-5 relative z-10 " ref={componentRef}>
      <img
        src={require("../../../../assets/school_logo.png")}
        alt=""
        className="absolute opacity-[0.07] left-0 right-0 top-0 bottom-0 m-auto w-[25rem] -z-10"
      />
      <div className="">
        <EnglishTcHeader currentStudentAllData={currentStudentAllData} />
      </div>{" "}
      <div className="">
        <EnglishTcMiddleBox currentStudentAllData={currentStudentAllData} />
      </div>{" "}
      <div className="">
        <EnglishTcDscriptionTable
          currentStudentAllData={currentStudentAllData}
        />
      </div>
      <div className="">
        <EnglishTcDecleartion
          currentStudentAllData={currentStudentAllData}
        />
      </div>
    </div>
  );
}

export default TcEnglishTemplate;
