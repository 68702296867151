export const API_CONSTANT = {
  IMAGE_CLOUD_FRONT_URL: "https://d21hn80xe717k9.cloudfront.net/",
  BASE_URL_DEV: "https://8b35mkwmz6.execute-api.ap-south-1.amazonaws.com/",
  BASE_URL_PROD: "https://schoolapi.upmyprep.com/",

  // student api start

  GET_ALL_STUDENTS_BY_MEDIUM_TYPE: "student/getAllStudent",

  GET_STUDENT_BY_SCHOLAR_NUMBER: "student/getStudentByScholarNumber",
  CREATE_STUDENT: "student/createNewStudent",
  UPDATE_STUDENT: "student/updateStudent",
  STUDENT_UPLOAD_DOCUMENTS: "student/upload",
  GET_ALL_STUDENT_DOCUMENTS: "student/getAllDocuments",
  DELETE_DOCUMENT: "student/deleteDocument",
  GET_TIME_TABLE: "timetable/getTimeTable",
  CREATE_TIME_TABLE: "timetable/createTimeTable",
  PROMOTE_STUDENT: "student/promoteStudent",

  // student api end

  // staff api start

  STAFF_LOGIN: "staff/login",
  CREATE_TEACHER: "staff/createStaff",
  UPDATE_TEACHER: "staff/updateStaff",
  GET_ALL_TEACHERS: "staff/getAllStaff",
  GET_TEACHER_BY_STAFF_ID: "staff/getStaff",
  GET_STUDENTS_BY_STAFF_ID: "staff/getStudentsByStaffId",
  ASSIGN_CLASS_TEACHER: "staff/assignClassTeacher",
  MARK_ATTENDANCE: "attendance/markAttendance",
  GET_STUDENT_ATTENDANCES: "attendance/getStudentAttendance",
  GET_CLASS_ATTENDANCE_BY_RANGE: "attendance/getClassAttendanceByRangeSorted",

  // staff api end

  // Fee api start

  SUBMIT_FEE: "payment/submitFee",
  APPLY_RELEXATION: "payment/applyRelaxation",
  GET_PAYMENT_BY_SCHOLAR_ID: "payment",
  GET_FEE_DETAILS_BY_CLASS_YEAR: "payment/getFee",
  GET_PREVIOUS_BALANCE_BY_SCHOLAR_ID: "student/getBalanceAmount",

  // fee api end

  // expense api satrt
  GET_EXPENSE_RANGE: "expense/getExpenseRange",
  CREATE_EXPENSE_CATEGORY: "expense/createExpenseCategory",
  GET_EXPENSE_CATEGORY: "expense/getExpenseCategory",
  ADD_NEW_EXPENSE: "expense/addNewExpense",
  // expense api end

  // dashboard api start

  GET_TOTAL_STUDENT_COUNT_BY_MEDIUM_TYPE_ACADMIC_YEAR: "dashboard",

  // dashboard api end

  USER_LOGIN: "auth/loginUser",
  FEE_REMINDER: "utils/alertFeesReminder",
};
