import React, { useContext, useEffect } from "react";
import { SlGraph } from "react-icons/sl";
import { PiStudentFill } from "react-icons/pi";
import { HiUsers } from "react-icons/hi2";
import { CiMoneyBill } from "react-icons/ci";
import { useGetTotalStudentCountByMediumAcadmicYearQuery } from "../../../Redux/ReduxToolkit";
import { Utils } from "../../../Utils/Utils";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { AuthContext } from "../../../Provider/AuthProvider";

function CountSection({ setExpenceDetails }) {
  const mediumValue = localStorage.getItem("medium");
  const { userData } = useContext(AuthContext);

  const { data: getStudentCount, isLoading: getStudentCountIsLoading } =
    useGetTotalStudentCountByMediumAcadmicYearQuery({
      medium: userData?.medium,
      academicYear: Utils?.generateAcademicYears(1)?.[0],
    });

  useEffect(() => {
    if (getStudentCount) {
      setExpenceDetails(getStudentCount);
    }
  }, [getStudentCount]);

  return (
    <div className="">
      {getStudentCountIsLoading && <CircleLoader />}
      <div className="flex  justify-between">
        <div className="min-h-[5rem] bg-white relative p-5 rounded-lg w-[24%] shadow-lg">
          <div className="h-full flex flex-col justify-between gap-5">
            <p className=" text-[#acacac] text-sm">Total Students</p>
            <p className="text-xl font-semibold text-[#616161]">
              {getStudentCount?.totalStudent}
            </p>
          </div>
          <div className="bg-gradient-to-r  from-[#5e72e4] to-[#825ee4] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
            <PiStudentFill className="text-white text-2xl" />
          </div>
        </div>{" "}
        <div className="min-h-[5rem] bg-white relative p-5 rounded-lg w-[24%] shadow-lg">
          <div className="h-full flex flex-col justify-between gap-5">
            <p className="text-[#acacac] text-sm">Total Staff</p>
            <p className="text-xl font-semibold text-[#616161]">
              {getStudentCount?.totalStaff}
            </p>
          </div>
          <div className="bg-gradient-to-r from-[#2dce89] to-[#2dcecc] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
            <HiUsers className="text-white text-2xl" />
          </div>
        </div>
        <div className="min-h-[5rem] bg-white relative p-5 rounded-lg w-[24%] shadow-lg">
          <div className="h-full flex flex-col justify-between gap-5">
            <p className="text-[#acacac] text-sm">This Week Earnings</p>
            <p className="text-xl font-semibold text-[#616161]">
              ₹{" "}
              {getStudentCount?.revenueWeekly
                ? getStudentCount?.revenueWeekly
                : 0}
            </p>
          </div>
          <div className="bg-gradient-to-r from-[#f5365c] to-[#f56036] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
            <SlGraph className="text-white text-2xl" />
          </div>
        </div>
        <div className="min-h-[5rem] bg-white relative p-5 rounded-lg w-[24%] shadow-lg">
          <div className="h-full flex flex-col justify-between gap-5">
            <p className="text-[#acacac] text-sm">This Month Earnings</p>
            <p className="text-xl font-semibold text-[#616161]">
              ₹{" "}
              {getStudentCount?.monthlyRevenue
                ? getStudentCount?.monthlyRevenue
                : 0}
            </p>
          </div>
          <div className="bg-gradient-to-r from-[#fb6340] to-[#fbb140] absolute top-[27%]  my-auto right-5 p-4  rounded-full">
            <CiMoneyBill className="text-white text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountSection;
