import React from "react";
import { FaTrophy } from "react-icons/fa";
import CustomButton from "../../Shared/CustomButton/CustomButton";

function SchoolEvents() {
  return (
    <div className="flex flex-col  items-center mb-10">
      <div className="flex flex-col  w-[95%] lg:w-[70%] tablet:w-[80%] gap-10 my-10">
        <div className="lg:w-[50%] flex flex-col justify-center items-center self-center">
          <img
            src={require("../../assets/school-events.png")}
            alt=""
            className="w-[5rem]"
          />
          <div className=" text-center">
            <p className=" text-2xl font-extrabold mb-4">SCHOOL EVENTS</p>
            <p className="italic ">
              " Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio,
              dicta dolorem. Fugiat alias, reprehenderit incidunt sunt quaerat
              maiores, dignissimos architecto voluptate animi vero nisi fuga
              soluta quod accusantium. Numquam, nam! "
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center ">
        <div className="flex flex-col justify-between lg:flex-row   w-[95%] lg:w-[70%] tablet:w-[80%] ">
          <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
            <div className="overflow-hidden inline-block ">
              <img
                src={require("../../assets/curriculum-kn-2.jpg")}
                alt=""
                className="  hover:scale-105 duration-300 overflow-hidden cursor-pointer "
              />
            </div>
            <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
              <p className="text-[#FADF13]">18 Oct, 2022</p>
              <p className="">Happy Republic Day</p>
            </div>
          </div>
          <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
            <div className="overflow-hidden inline-block ">
              <img
                src={require("../../assets/curriculum-kn-2.jpg")}
                alt=""
                className="  hover:scale-105 duration-300 overflow-hidden cursor-pointer "
              />
            </div>
            <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
              <p className="text-[#FADF13]">18 Oct, 2022</p>
              <p className="">Happy Republic Day</p>
            </div>
          </div>
          <div className="flex flex-col text-white w-full my-5 lg:w-[30%] ">
            <div className="overflow-hidden inline-block ">
              <img
                src={require("../../assets/curriculum-kn-2.jpg")}
                alt=""
                className="  hover:scale-105 duration-300 overflow-hidden cursor-pointer "
              />
            </div>
            <div className="flex flex-col gap-2 bg-[#293B61] p-4 text-sm">
              <p className="text-[#FADF13]">18 Oct, 2022</p>
              <p className="">Happy Republic Day</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center ">
        <CustomButton
          label={`VIEW ALL EVENTS`}
          className={`hover:bg-[#edd156] text-white bg-[#ED0C0A] duration-300 border-none !p-5  font-extrabold hover:text-black`}
        />
      </div>
    </div>
  );
}

export default SchoolEvents;
