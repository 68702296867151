import React, { useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import TimeTableSchedule from "./TimeTableSchedule";
import CreateTimeTableForm from "./CreateTimeTableForm";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../StringConstant/MotiLalAllConstant";
import { useGetTimeTableQuery } from "../../Redux/ReduxToolkit";
import CircleLoader from "../../AllLoaders/CircleLoader";

function TimeTableAndHolyday() {
  const [showCreateTimeTablePopup, setShowCreateTimeTablePopup] =
    useState(false);
  const [timeTableAllData, setTimeTableAllData] = useState();
  const [season, setSeason] = useState("FALL");
  const [studentClass, setStudentClass] = useState("6th");
  const [studentSection, setStudentSection] = useState("Q");

  const {
    data: getTimeTable,
    isLoading: getTimeTableIsloading,
    refetch: getTimeTableRefetch,
  } = useGetTimeTableQuery(
    {
      season: season,
      studentClass: studentClass,
      studentSection: studentSection,
    },
    { skip: !season || !studentClass || !studentSection }
  );

  useEffect(() => {
    if (getTimeTable) {
      setTimeTableAllData(getTimeTable?.data);
    }
  }, [getTimeTable]);

  return (
    <div className="m-5">
      {getTimeTableIsloading && <CircleLoader />}
      <div className="w-full flex justify-between items-center bg-white px-5 py-2 rounded-lg">
        <p className="">Time Table & Holiday Management</p>

        <div className="flex items-center">
          <CustomButton
            label={`Create Time Table`}
            className={`!mx-0 text-white border-none bg-[#2B60EC] hover:bg-[#4867bd]`}
            onClick={() => setShowCreateTimeTablePopup(true)}
          />
        </div>
      </div>{" "}
      <div className="w-full flex  gap-5 items-center bg-white px-5 py-2 rounded-lg mt-5">
        <p className="">Filter By :</p>
        <div className=" w-[10rem]">
          <select
            value={season}
            onChange={(e) => setSeason(e.target.value)}
            className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
          >
            <option value="" disabled>
              Choose Season
            </option>
            <option value="SUMMER">SUMMER</option>
            <option value="WINTER">WINTER</option>
            <option value="FALL">FALL</option>
          </select>
        </div>
        <div className=" w-[10rem]">
          <select
            value={studentClass}
            onChange={(e) => setStudentClass(e.target.value)}
            className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
          >
            <option value="" disabled>
              Choose Class
            </option>
            {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
              (studentClassData, index) => (
                <option value={studentClassData} key={index}>
                  {studentClassData}
                </option>
              )
            )}
          </select>
        </div>{" "}
        <div className=" w-[10rem]">
          <select
            value={studentSection}
            onChange={(e) => setStudentSection(e.target.value)}
            className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm text-[#787B7C]"
          >
            <option value="" disabled>
              Choose Section
            </option>
            {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
              (studentSectionData, index) => (
                <option value={studentSectionData} key={index}>
                  {studentSectionData}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <div className=" ">
        <TimeTableSchedule
          timeTableAllData={timeTableAllData}
          season={season}
          studentClass={studentClass}
          studentSection={studentSection}
        />
      </div>
      {showCreateTimeTablePopup && (
        <CreateTimeTableForm
          rightPopup={`animate-rightPopup`}
          setShowCreateTimeTablePopup={setShowCreateTimeTablePopup}
          getTimeTableRefetch={getTimeTableRefetch}
        />
      )}
    </div>
  );
}

export default TimeTableAndHolyday;
