import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_CONSTANT } from "../ApiConstant/ApiConstant";

export const SchoolApi = createApi({
  reducerPath: "admin",
  baseQuery: fetchBaseQuery({
    baseUrl: API_CONSTANT?.BASE_URL_PROD,
  }),
  endpoints: (builder) => ({
    getAllStudentByMediumType: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_ALL_STUDENTS_BY_MEDIUM_TYPE}/${params.academicYear}?medium=${params.medium}`,
    }),
    getAllStudentsByClassSectionAcademicYear: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_ALL_STUDENTS_BY_MEDIUM_TYPE}/${params.studentClass}/${params.studentSection}/${params.academicYear}?medium=${params.medium}`,
    }),
    getStudentByScholarNumber: builder.query({
      query: (scholarNumber) =>
        `${API_CONSTANT.GET_STUDENT_BY_SCHOLAR_NUMBER}/${scholarNumber}`,
    }),
    getPaymentByScholarNumber: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_PAYMENT_BY_SCHOLAR_ID}/${params.scholarNumber}/${params?.academicYear}`,
    }),
    getAllDocumentByScholarNumber: builder.query({
      query: (scholarNumber) =>
        `${API_CONSTANT.GET_ALL_STUDENT_DOCUMENTS}/${scholarNumber}`,
    }),
    getTimeTable: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_TIME_TABLE}/${params.studentClass}/${params.studentSection}/${params.season}`,
    }),
    getAllTeachers: builder.query({
      query: (medium) => `${API_CONSTANT.GET_ALL_TEACHERS}/${medium}`,
    }),
    getTeacherByStaffId: builder.query({
      query: (staffId) => `${API_CONSTANT.GET_TEACHER_BY_STAFF_ID}/${staffId}`,
    }),
    getStudentsByStaffId: builder.query({
      query: (staffId) => `${API_CONSTANT.GET_STUDENTS_BY_STAFF_ID}/${staffId}`,
    }),
    getFeeDetailsByClassYearRange: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_FEE_DETAILS_BY_CLASS_YEAR}/${params?.class}/${params?.section}/${params?.academicYear}/${params.startDate}/${params.endDate}`,
    }),
    getExpenseRange: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_EXPENSE_RANGE}/${params?.startDate}/${params?.endDate}`,
    }),
    getExpenseCategory: builder.query({
      query: () => `${API_CONSTANT.GET_EXPENSE_CATEGORY}`,
    }),
    getTotalStudentCountByMediumAcadmicYear: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_TOTAL_STUDENT_COUNT_BY_MEDIUM_TYPE_ACADMIC_YEAR}/${params?.medium}/${params.academicYear}`,
    }),
    getClassAttendanceByRange: builder.query({
      query: (params) =>
        `${API_CONSTANT.GET_CLASS_ATTENDANCE_BY_RANGE}/${params?.studentClass}/${params?.studentSection}/${params?.fromDate}/${params?.toDate}`,
    }),
    getPreviousBalanceByScholarId: builder.query({
      query: (scholarNumber) =>
        `${API_CONSTANT.GET_PREVIOUS_BALANCE_BY_SCHOLAR_ID}/${scholarNumber}`,
    }),

    createStudent: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.CREATE_STUDENT}`,
        method: "POST",
        body: body,
      }),
    }),
    updateStudent: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.UPDATE_STUDENT}`,
        method: "PUT",
        body: body,
      }),
    }),
    updateStaff: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.UPDATE_TEACHER}`,
        method: "PUT",
        body: body,
      }),
    }),
    submitPayment: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.SUBMIT_FEE}`,
        method: "POST",
        body: body,
      }),
    }),
    studentUploadDocument: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.STUDENT_UPLOAD_DOCUMENTS}`,
        method: "POST",
        body: body,
      }),
    }),
    createTimeTable: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.CREATE_TIME_TABLE}`,
        method: "POST",
        body: body,
      }),
    }),
    createTeacher: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.CREATE_TEACHER}`,
        method: "POST",
        body: body,
      }),
    }),
    assignClassTeacher: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.ASSIGN_CLASS_TEACHER}`,
        method: "POST",
        body: body,
      }),
    }),
    staffLogin: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.STAFF_LOGIN}`,
        method: "POST",
        body: body,
      }),
    }),
    markAttendance: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.MARK_ATTENDANCE}`,
        method: "POST",
        body: body,
      }),
    }),
    createExpenseCategory: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.CREATE_EXPENSE_CATEGORY}`,
        method: "POST",
        body: body,
      }),
    }),
    addNewExpense: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.ADD_NEW_EXPENSE}`,
        method: "POST",
        body: body,
      }),
    }),
    applyRelextion: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.APPLY_RELEXATION}`,
        method: "POST",
        body: body,
      }),
    }),
    userLogin: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.USER_LOGIN}`,
        method: "POST",
        body: body,
      }),
    }),
    feeReminder: builder.mutation({
      query: ({ body }) => ({
        url: `${API_CONSTANT.FEE_REMINDER}`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetAllStudentsByClassSectionAcademicYearQuery,
  useGetStudentByScholarNumberQuery,
  useGetAllStudentByMediumTypeQuery,
  useCreateStudentMutation,
  useUpdateStudentMutation,
  useGetPaymentByScholarNumberQuery,
  useSubmitPaymentMutation,
  useStudentUploadDocumentMutation,
  useGetAllDocumentByScholarNumberQuery,
  useGetTimeTableQuery,
  useCreateTimeTableMutation,
  useCreateTeacherMutation,
  useGetAllTeachersQuery,
  useGetTeacherByStaffIdQuery,
  useAssignClassTeacherMutation,
  useStaffLoginMutation,
  useGetStudentsByStaffIdQuery,
  useMarkAttendanceMutation,
  useCreateExpenseCategoryMutation,
  useGetExpenseCategoryQuery,
  useGetExpenseRangeQuery,
  useAddNewExpenseMutation,
  useGetTotalStudentCountByMediumAcadmicYearQuery,
  useGetFeeDetailsByClassYearRangeQuery,
  useApplyRelextionMutation,
  useGetPreviousBalanceByScholarIdQuery,
  useUserLoginMutation,
  useFeeReminderMutation,
  useUpdateStaffMutation,
  useGetClassAttendanceByRangeQuery,
} = SchoolApi;
