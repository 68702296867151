import React from "react";

function EnglishTcDscriptionTable() {
  return (
    <div className="">
      <table className="w-full text-xsm  rounded">
        <thead className="border border-black bg-gray-700/20">
          <tr className=" text-gray-700 border-b border-black font-bold ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">Class</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <p className="!font-extrabold">
                Date of
                <br /> Admission
              </p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="!font-extrabold">
                Date of
                <br /> Promotion
              </p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="!font-extrabold">
                Date of
                <br /> Removal
              </p>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <p className="!font-extrabold">
                Cause of Removal e.g. non-payment
                <br /> of dues, removal of family expulsion etc.
              </p>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <p className="!font-extrabold">Year</p>
            </td>
            <td className=" p-1 text-center">
              <p className="!font-extrabold">Conduct & Wark</p>
            </td>
          </tr>
        </thead>
        <tbody className="border border-black">
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">VI</p>
              <p className="!font-extrabold">6</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none "
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">VII</p>
              <p className="!font-extrabold">7</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">VIII</p>
              <p className="!font-extrabold">8</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">IX</p>
              <p className="!font-extrabold">9</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">X</p>
              <p className="!font-extrabold">10</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>{" "}
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">XI</p>
              <p className="!font-extrabold">11</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
          <tr className=" text-gray-700 border-b border-black ">
            <td className=" p-1 text-center">
              <p className="!font-extrabold">XII</p>
              <p className="!font-extrabold">12</p>
            </td>{" "}
            <td className=" p-1 text-center border-l border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>{" "}
            <td className=" p-1 text-center border-x border-black">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
            <td className=" p-1 text-center">
              <textarea
                className="w-full  h-full bg-transparent p-1.5 focus:outline-none  focus:border border-[#2B60EC] rounded resize-none"
                rows={2}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EnglishTcDscriptionTable;
