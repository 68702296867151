function TextareaWithBorder({
  label,
  onChange,
  inputClassName,
  labelClassName,
  value,
  placeholder,
  rows,
}) {
  return (
    <div className="my-1 w-full">
      <p className={`text-[#797b7c] text-sm mb-1 ${labelClassName}`}>{label}</p>
      <textarea
        className={`border hover:border-[#2B60EC] focus:outline-none focus:border-[#2B60EC] w-full text-sm rounded-md p-1.5 ${inputClassName}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
      ></textarea>
    </div>
  );
}

export default TextareaWithBorder;
