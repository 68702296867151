import React, { useEffect, useState } from "react";
import { Utils } from "../../../../Utils/Utils";

function HindiTcDecleartion() {
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="">
      <div className="flex items-start text-xsm gap-3 my-5">
        <div className="">Note</div>
        <div className="flex flex-col gap-2">
          <div className="flex items-start  gap-3">
            <p className="">1</p>
            <div className="">
              <p className="">
                ( प्रमाणित किया जाता कि उपर्युक्त छात्र पत्रावली विभाग के
                नियामनुसार छात्र द्वारा संस्था त्याग के दिन तक के लिए अंकित है )
              </p>
            </div>
          </div>
          <div className="flex items-start  gap-3">
            <p className="">2</p>
            <div className="">
              <p className="">
                ( यदि छात्र अपनी कक्षा में श्रेष्ठ रहा है तो वह बात "आचरण तथा
                कार्य" शीर्षक में लिखी जानी चाहिए )
              </p>
            </div>
          </div>
          <div className="flex items-start  gap-3">
            <p className="">3</p>
            <div className="">
              <p className="flex gap-5">
                ( कक्षा 9 से 12 तक के जो छात्र संस्था त्याग कर रहे है उनकी
                उपस्थिति अर्थात कालेज अथवा स्कूल में जितनी )
                <input
                  type="text"
                  className=" bg-transparent w-[20rem]  focus:outline-none "
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between text-xs mt-10  items-center">
        <div className="flex items-end gap-4  py-2 ">
          <p className=" capitalize  flex gap-1">
            <span>दिनांक</span>
          </p>
          <input
            disabled
            value={Utils?.formatDate(currentDate)}
            type="text"
            className=" bg-transparent w-full   focus:outline-none "
          />
        </div>
        <p className="">लिपिक</p>
        <div className="flex items-end gap-4  py-2 mx-5">
          <span className="flex">हo प्रधानाचार्य</span>
        </div>
      </div>
    </div>
  );
}

export default HindiTcDecleartion;
