import React from "react";
import { useNavigate } from "react-router-dom";

function GalleryContainer() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center w-full ">
      <div className="flex flex-col lg:flex-row  justify-between   w-[95%] lg:w-[70%] tablet:w-[80%] gap-10 ">
        <div
          className="bg-red-100 p-32  rounded-lg w-1/2  text-center text-4xl font-semibold cursor-pointer hover:scale-105 duration-200"
          // onClick={() => navigate("/")}
        >
          Sparkle & Shine
        </div>
        <div className="bg-red-100 p-32  rounded-lg w-1/2 text-center text-4xl font-semibold cursor-pointer hover:scale-105 duration-200">
          Other Photos
        </div>
      </div>
    </div>
  );
}

export default GalleryContainer;
