import { useContext, useEffect, useState } from "react";

import "./SchoolLogin.css";
import { MOTILAL_STRINGS } from "../../../StringConstant/MotiLalAllConstant";
import SchoolLogin from "./SchoolLogin";
import TeacherLogin from "./TeacherLogin";
import StudentLogin from "./StudentLogin";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { IoIosUnlock } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { enqueueSnackbar } from "notistack";
import { useUserLoginMutation } from "../../../Redux/ReduxToolkit";
import CircleLoader from "../../../AllLoaders/CircleLoader";
import { AuthContext } from "../../../Provider/AuthProvider";

const MainLogin = () => {
  const [loginSwitchBtn, setLoginSwitchBtn] = useState("school");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login: setAccessToken } = useContext(AuthContext);
  const [userLogin, { isLoading: userLoginIsLoading }] = useUserLoginMutation();

  // useEffect(() => {
  //   const role = localStorage.getItem("role");
  //   if (role === "school") {
  //     navigate("/home");
  //   }
  //   if (role === "teacher") {
  //     navigate("/staff-profile");
  //   }
  // }, []);

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleLogin = async () => {
    if (email === null || email === undefined || email.length === 0) {
      enqueueSnackbar("Email is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered Email is incorrect", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      return;
    }

    if (password === null || password === undefined || password.length === 0) {
      enqueueSnackbar("Password is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    try {
      const response = await userLogin({
        body: {
          email: email,
          password: password,
        },
      });

      // console.log(response);

      if (response?.data) {
        // console.log("user login ", response?.data?.userInfo?.medium);
        if (response?.data?.accessToken) {
          // console.log("user login ", response?.data?.userInfo);
          // localStorage.setItem("role", response?.data?.userInfo?.role);

          setAccessToken(response?.data?.accessToken, response?.data?.userInfo);
          navigate(`/${response?.data?.userInfo?.medium}`);
        }

        if (response?.data?.msg) {
          enqueueSnackbar(response?.data?.msg, {
            autoHideDuration: 3000,
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
          return;
        }
      } else {
        console.log("Oops! user is not logid ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };
  return (
    <div
      className=" w-full h-[100vh] flex justify-center
     items-center login_bg_image relative"
    >
      {userLoginIsLoading && <CircleLoader />}
      <div className="bg-white w-[90%] p-5 lg:p-0 lg:w-[30rem] min-h-[28rem] flex flex-col items-center  rounded-3xl ">
        <div className="flex flex-col items-center pt-5 ">
          <img
            src={require("../../../assets/school_logo.png")}
            alt=""
            className="w-[5rem]"
          />
          <div className=" text-center">
            <p className=" font-extrabold  text-xl my-2  text-gray-700 ">
              {MOTILAL_STRINGS?.MLMIC_NAME_ENGLISH}
            </p>
            {/* <p className="text-gray-600 text-sm">Log in to Get Started</p> */}
          </div>
        </div>
        <div className=" rounded-md text-sm w-full lg:px-10  mt-5">
          <div className=" w-full">
            <select
              value={loginSwitchBtn}
              onChange={(e) => setLoginSwitchBtn(e.target.value)}
              className="w-full p-3 border border-[#E4E7EB]  text-sm text-[#787B7C] rounded-full"
            >
              <option value={`school`}>School</option>
              <option value={`teacher`}>Teacher</option>
              {/* <option value={`student`}>Student</option> */}
            </select>
          </div>
        </div>

        {/* <div className="w-full lg:px-10 my-5">
          {isError && (
            <div className="border border-red-400 p-4 rounded-lg text-red-600 text-xs">
              {error?.data?.message}
            </div>
          )}

          <div className="">
            <div className="">
              <div className=" relative  border rounded-full px-5 py-3 ">
                <span className="absolute top-3.5  bottom-0 my-auto text-xl">
                  <CiMail />
                </span>
                <input
                  autoComplete="off"
                  type="email"
                  className=" w-full rounded-full border-0 text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 ms-5 px-3 focus:outline-none"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="my-3 ">
              <div className=" relative border rounded-full px-5 py-3">
                <span className="absolute mt-0 mb-0 my-auto text-xl top-3.5">
                  <IoIosUnlock className="text-gray-500" />
                </span>
                <input
                  autoComplete="off"
                  type="password"
                  className=" w-full rounded-full border-0  text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 mx-5 px-3 focus:outline-none "
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // isError(false);
                  }}
                />
              </div>
            </div>
            <CustomButton
              label={`LOG IN`}
              className={`!mx-0 !py-3 w-full !rounded-full text-white bg-blue-600 border-none  mt-3 hover:bg-blue-600`}
              onClick={() => handleLogin()}
            />
          </div>
          <div className="flex justify-center w-full text-sm mt-5">
            <p className=" mt-2 text-blue-500 cursor-pointer">
              Forgot password ?
            </p>
          </div>
        </div> */}

        {loginSwitchBtn === "school" && <SchoolLogin />}
        {loginSwitchBtn === "teacher" && <TeacherLogin />}
        {/* {loginSwitchBtn === "student" && <StudentLogin />} */}
      </div>
    </div>
  );
};

export default MainLogin;
